import React from 'react'
import { groupBy } from 'lodash'
import { push } from 'connected-react-router'
import { MapAction } from 'map/reducers/MapReducer'
import { IconButton } from '@mui/material'
import { useDispatch } from 'react-redux'
import MapThunk from 'map/actions/MapThunk'
import RefreshIcon from '@mui/icons-material/Refresh'
import PropTypes from 'prop-types'
import DtoSelection from 'selection/dto/DtoSelection'

const PlaySelection = ({ selection }) => {
    const dispatch = useDispatch()

    const onClick = () => {
        const criterias = selection.filters.map(f => ({ ...f, group: f.type }))
        const groupedCriterias = groupBy(criterias, c => c.type)
        dispatch(MapAction.setSearchCriteria({
            cities: groupedCriterias.city || [],
            departments: groupedCriterias.department || [],
            watersheds: groupedCriterias.watershed || [],
            stations: groupedCriterias.station || [],
            epci: groupedCriterias.epci || [],
        }))
        dispatch(MapAction.resetDataToDisplay())
        dispatch(MapAction.setLocated(false))

        if (groupedCriterias.city) {
            groupedCriterias.city.forEach(c => dispatch(MapThunk.fetchCityResult(c.code)))
        }
        if (groupedCriterias.department) {
            groupedCriterias.department.forEach(d => dispatch(MapThunk.fetchDepartmentResult(d.code)))
        }
        if (groupedCriterias.watershed) {
            groupedCriterias.watershed.forEach(d => dispatch(MapThunk.fetchWatershedResult(d.code)))
        }
        if (groupedCriterias.station) {
            groupedCriterias.station.forEach(d => dispatch(MapThunk.fetchStationResult(d.code)))
        }
        dispatch(push(`/map?selection=${selection.code}`))
    }

    return (
        <IconButton onClick={onClick}>
            <RefreshIcon htmlColor='blue'/>
        </IconButton>
    )
}

PlaySelection.propTypes = {
    selection: PropTypes.instanceOf(DtoSelection),
}

export default PlaySelection