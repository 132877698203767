import { createSlice } from '@reduxjs/toolkit'
import { TOASTR_ERROR, TOASTR_INFO, TOASTR_SUCCESS, TOASTR_WARNING } from '../constants/ToastrConstants'

export const initialState = {
    type: TOASTR_SUCCESS,
    message: '',
    duration: 6000,
}

const store = createSlice({
    name: 'toastr',
    initialState,
    reducers: {
        success(state, action) {
            state.type = TOASTR_SUCCESS
            state.message = action.payload.message ?? action.payload
            state.duration = action.payload.duration ?? initialState.duration
        },
        warning(state, action) {
            state.type = TOASTR_WARNING
            state.message = action.payload.message ?? action.payload
            state.duration = action.payload.duration ?? initialState.duration
        },
        error(state, action) {
            state.type = TOASTR_ERROR
            state.message = action.payload.message ?? action.payload
            state.duration = action.payload.duration ?? initialState.duration
        },
        info(state, action) {
            state.type = TOASTR_INFO
            state.message = action.payload.message ?? action.payload
            state.duration = action.payload.duration ?? initialState.duration
        },
        reset(state) {
            state.type = TOASTR_SUCCESS
            state.message = ''
            state.duration = 5000
        },
    },
    extraReducers: {
    },
})

export const ToastrAction = store.actions
export default store.reducer