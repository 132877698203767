import React from 'react'
import { Grid, Modal } from '@mui/material'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import DtoSelection from '../dto/DtoSelection'
import i18n from 'simple-react-i18n'

const useStyles = makeStyles(() => ({
    grid: {
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '7px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'left',
        '&:focus': {
            outline: 'none',
        },
    },
    title: {
        padding: '15px',
        fontSize: '1.7em',
    },
    hr: {
        backgroundColor: '#353333',
        height: '3px',
        border: 'none',
        borderRadius: '3px',
    },
    data: {
        padding: '15px',
    },
    button: {
        border: '1px solid #353333',
        padding: '10px',
        cursor: 'pointer',
        borderRadius: '4px',
        color: 'white',
        fontWeight: 'bold',
    },
    cancel: {
        backgroundColor: 'white',
        color: '#232052',
        '&:hover': {
            color: 'white',
            transitionDuration: '0.25s',
            backgroundColor: '#232052',
        },
    },
    valid: {
        backgroundColor: '#232052',
        '&:hover': {
            color: '#B8B1B1',
            transitionDuration: '0.25s',
            backgroundColor: '#353333',
        },
    },
}))

const DeleteSelection = ({ selection, setSelection, deleteSelection }) => {
    const classes = useStyles()

    const onKeyDown = (e) => {
        if (e.key === 'Escape') {
            e.defaultMuiPrevented = true
            setSelection(null)
        }
    }

    return (
        <Modal
            open={selection !== null}
        >
            <Grid container item className={classes.grid} md={5} onKeyDown={onKeyDown}>
                <Grid item className={classes.title}>
                    {i18n.deleteSelection} {selection ? selection.name : ''} {' ?'}
                </Grid>

                <Grid item md={12} className={classes.hr} />

                <Grid container item>
                    <div className={classes.data}>
                        {i18n.selectInfosToDelete} {selection ? selection.name : ''} {' ?'}
                    </div>
                </Grid>

                <Grid container item justifyContent={'flex-end'} alignItems={'center'} spacing={1}>
                    <Grid item>
                        <button className={`${classes.button} ${classes.cancel}`} onClick={() => setSelection(null)}>{i18n.cancel}</button>
                    </Grid>
                    <Grid item>
                        <button className={`${classes.button} ${classes.valid}`} onClick={() => {
                            deleteSelection(selection)
                            setSelection(null)
                        }}
                        >{i18n.delete}</button>
                    </Grid>
                </Grid>
            </Grid>
        </Modal>
    )
}

DeleteSelection.propTypes = {
    selection: PropTypes.instanceOf(DtoSelection),
    setSelection: PropTypes.func,
    deleteSelection: PropTypes.func,
}

export default DeleteSelection