import { createAsyncThunk } from '@reduxjs/toolkit'
import ApplicationConf from 'conf/ApplicationConf'
import { genericPrivatePromise, genericPublicPromise } from 'utils/ActionUtils'
import i18n from 'simple-react-i18n'
import LogAction from 'log/actions/LogAction'
import { MapAction } from 'map/reducers/MapReducer'
import { ToastrAction } from '../../toastr/reducers/ToastrReducer'
import { SIEAU_LOGIN } from '../../pages/offline/constants/HomeConstants'

const MapThunk = {
    fetchStations: async(dispatch, p, code) => {
        let progress = 5

        dispatch(MapAction.setLoadingMessage(i18n.loadingQualityStation))
        dispatch(MapAction.setLoadingProgress(progress))

        const qualito = await genericPublicPromise(p('QUALITY', code)).finally(() => {
            progress = progress + 20
            dispatch(MapAction.setLoadingProgress(progress))
            dispatch(MapAction.setLoadingMessage(i18n.loadingPiezometricStation))
        })

        const piezo = await genericPublicPromise(p('PIEZOMETRY', code)).finally(() => {
            progress = progress + 20
            dispatch(MapAction.setLoadingProgress(progress))
            dispatch(MapAction.setLoadingMessage(i18n.loadingPluviometricStation))
        })

        const pluvio = await genericPublicPromise(p('PLUVIOMETRY', code)).finally(() => {
            progress = progress + 20
            dispatch(MapAction.setLoadingProgress(progress))
            dispatch(MapAction.setLoadingMessage(i18n.loadingHydrometricStation))
        })

        const hydro = await genericPublicPromise(p('HYDROMETRY', code)).finally(() => {
            progress = progress + 20
            dispatch(MapAction.setLoadingProgress(progress))
        })

        dispatch(MapAction.setLoadingMessage(i18n.loadingLastMeasures))

        return {
            qualito,
            piezo,
            pluvio,
            hydro,
        }
    },
    fetchLastMeasureStations: async(dispatch, watershedStations) => {
        const login = localStorage.getItem(SIEAU_LOGIN)

        if (login) {
            const stations = watershedStations.flatMap(s => s.stations)
            const pluvioStationsCode = stations.filter(s => s.type === 'pluvio').map(s => s.code)
            const hydroStationsCode = stations.filter(s => s.type === 'hydro').map(s => s.code)
            const piezoStationsCode = stations.filter(s => s.type === 'piezo').map(s => s.code)
            //const qualitoStationsCode = resultStations.filter(s => s.type === 'qualito').map(s => s.code)

            const [pluvio, hydro, piezo] = await Promise.all([
                genericPrivatePromise(ApplicationConf.pluviometer.lastMeasures(), 'POST', JSON.stringify(pluvioStationsCode)),
                genericPrivatePromise(ApplicationConf.hydrometricStation.lastMeasures(), 'POST', JSON.stringify({ ids: hydroStationsCode })),
                genericPrivatePromise(ApplicationConf.piezometer.situationLastMeasures(), 'POST', JSON.stringify({ ids: piezoStationsCode })),
            ])

            dispatch(MapAction.setPluvioLastMeasures(pluvio))
            dispatch(MapAction.setHydroLastMeasures(hydro))
            dispatch(MapAction.setPiezoLastMeasures(piezo))
        }


        dispatch(MapAction.setLoadingProgress(100))
        dispatch(MapAction.setLoadingMessage(i18n.displayEntitiesOnMap))
    },
    fetchCityResult: createAsyncThunk(
        'fetchCityResult',
        async(code, { rejectWithValue, dispatch }) => {
            try {
                const response = await MapThunk.fetchStations(dispatch, ApplicationConf.referencial.cityLayer, code)
                const stations = [...response.qualito.watershedStation, ...response.piezo.watershedStation, ...response.pluvio.watershedStation, ...response.hydro.watershedStation]
                MapThunk.fetchLastMeasureStations(dispatch, stations)
                return {
                    cityKml: response.qualito.cityKml,
                    watershedStation: stations,
                }
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.cities} to display on map: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        }
    ),
    fetchDepartmentResult: createAsyncThunk(
        'fetchDepartmentResult',
        async(code, { rejectWithValue, dispatch }) => {
            try {
                const response = await MapThunk.fetchStations(dispatch, ApplicationConf.referencial.departmentLayer, code)
                const stations = [...response.qualito, ...response.piezo, ...response.pluvio, ...response.hydro]
                MapThunk.fetchLastMeasureStations(dispatch, stations)
                return stations
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.departments} to display on map: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        }
    ),
    fetchWatershedResult: createAsyncThunk(
        'fetchWatershedResult',
        async(code, { rejectWithValue, dispatch }) => {
            try {
                const response = await MapThunk.fetchStations(dispatch, ApplicationConf.referencial.watershedLayer, code)
                const stations = [...response.qualito.stations, ...response.piezo.stations, ...response.pluvio.stations, ...response.hydro.stations]
                MapThunk.fetchLastMeasureStations(dispatch, stations)
                return {
                    stations,
                    watershedKml: response.qualito.watershedKml,
                }
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.watershed} to display on map: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        }
    ),
    fetchStationResult: createAsyncThunk(
        'fetchStationResult',
        async(code, { rejectWithValue, dispatch }) => {
            try {
                const response = await MapThunk.fetchStations(dispatch, ApplicationConf.station.stationLayer, code)
                const type = Object.keys(response.qualito).length && response.qualito
                    || Object.keys(response.piezo).length && response.piezo
                    || Object.keys(response.pluvio).length && response.pluvio
                    || Object.keys(response.hydro).length && response.hydro
                    || {}

                MapThunk.fetchLastMeasureStations(dispatch, type.stations)

                return {
                    stations: type.stations,
                    watershedKml: type.watershedKml,
                }
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.station} to display on map: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        }
    ),
    fetchCityByCoordinate: createAsyncThunk(
        'fetchCityByCoordinate',
        async(latlon, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPublicPromise(ApplicationConf.referencial.cityByCoordinate(latlon.lat, latlon.lon))
                dispatch(MapAction.resetDataToDisplay())
                dispatch(MapThunk.fetchCityResult(response[0].code))
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.city} geolocation: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        }
    ),
}

export default MapThunk
