import React from 'react'
import { Box, Grid, LinearProgress, Modal, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import loadingGif from 'assets/pictures/loading.gif'
import PropTypes from 'prop-types'
import { isUndefined } from 'lodash'

const useStyles = makeStyles(() => ({
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
        '&:focus': {
            outline: 'none',
        },
    },
    info: {
        marginTop: '10px',
        padding: '10px',
        borderRadius: '5px',
        color: 'white',
        fontSize: '0.9em',
        fontWeight: 'bold',
    },
}))

const LinearProgressWithLabel = ({ progress }) => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }} style={{ marginTop: 5 }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant='determinate' value={progress} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant='body2' color='white'>{`${Math.round(
                    progress,
                )}%`}</Typography>
            </Box>
        </Box>
    )
}

LinearProgressWithLabel.propTypes = {
    progress: PropTypes.number,
}

const LoadingScreen = ({ isOpen, message, progress }) => {
    const classes = useStyles()

    return (
        <Modal
            open={isOpen}
        >
            <Box className={classes.modal}>
                <img src={loadingGif}/>
                <Grid container className={classes.info}>
                    <Grid item style={{ minwidth: 250 }}>
                        {
                            message ?? ''
                        }
                        {
                            !isUndefined(progress) && (<LinearProgressWithLabel progress={progress} />)
                        }
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
}

LoadingScreen.propTypes = {
    isOpen: PropTypes.bool,
    message: PropTypes.string,
    progress: PropTypes.number,
}

export default LoadingScreen