/* eslint-disable no-process-env */
import thunk from 'redux-thunk'
import logger from 'redux-logger'
import { createStore, combineReducers, applyMiddleware } from 'redux'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createHashHistory } from 'history'
import { ContactReducer, store as ContactReducerStore } from '../pages/online/contact/reducers/ContactReducer'
import { CmsReducer, store as CmsReducerStore } from '../pages/online/cms/reducers/CmsReducer'
import ReferentialReducer, { initialState as ReferentialReducerStore } from '../referential/reducers/ReferentialReducer'
import MapReducer, { initialState as MapReducerStore } from '../map/reducers/MapReducer'
import HomeReducer, { initialState as HomeReducerStore } from '../home/reducers/HomeReducer'
import AuthenticationReducer, { initialState as AuthenticationReducerStore } from '../authentication/reducers/AuthenticationReducer'
import SearchBarReducer, { initialState as SearchBarReducerStore } from '../searchBar/reducers/SearchBarReducer'
import SelectionReducer, { initialState as SelectionReducerStore } from '../selection/reducers/SelectionReducer'
import ToastrReducer, { initialState as ToastrReducerStore } from '../toastr/reducers/ToastrReducer'
import AccountReducer, { initialState as AccounReducerStore } from '../account/reducers/AccountReducer'
import WaitReducer, { initialState as WaitReducerStore } from '../wait/WaitReducer'

export const history = createHashHistory()

const middlewares = [thunk, routerMiddleware(history)]

if (process.env.NODE_ENV !== 'production') {
    middlewares.push(logger)
}

const enhancer = applyMiddleware(...middlewares)

const initialStore = {
    SelectionReducer: SelectionReducerStore,
    SearchBarReducer: SearchBarReducerStore,
    AuthenticationReducer: AuthenticationReducerStore,
    MapReducer: MapReducerStore,
    ReferentialReducer: ReferentialReducerStore,
    HomeReducer: HomeReducerStore,
    AccountReducer: AccounReducerStore,
    ContactReducer: ContactReducerStore,
    ToastrReducer: ToastrReducerStore,
    CmsReducer: CmsReducerStore,
    WaitReducer: WaitReducerStore,
}

const appReducers = combineReducers({
    SelectionReducer,
    SearchBarReducer,
    AuthenticationReducer,
    MapReducer,
    ReferentialReducer,
    HomeReducer,
    AccountReducer,
    ContactReducer,
    CmsReducer,
    ToastrReducer,
    WaitReducer,
    router: connectRouter(history),
})

const rootReducer = (state, action) => {
    if (action.type === 'RESET_ALL_STORE') {
        const { applicationSettings } = state.HomeReducer
        const homeReducer = {
            ...initialStore.HomeReducer,
            applicationSettings,
        }
        return {
            ...state,
            ...initialStore,
            HomeReducer: homeReducer,
        }
    }
    return appReducers(state, action)
}

const AppStore = createStore(rootReducer, initialStore, enhancer)

export default AppStore
