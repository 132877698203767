import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import AutocompleteAquadb from 'searchBar/AutocompleteAquadb'
import { Box, Grid, useMediaQuery } from '@mui/material'
import SideBar from 'sideBar/SideBar'
import PhotoIcon from '@mui/icons-material/Photo'
import BackgroundTab from 'sideBar/components/BackgroundTab'
import SettingsTab from 'sideBar/components/SettingsTab'
import LayersTab from 'sideBar/components/LayersTab'
import BurgerMenu from 'burgerMenu/BurgerMenu'
import { useTheme } from '@emotion/react'
import DtoSelection from 'selection/dto/DtoSelection'
import { HYDRO, PIEZO, PLUVIO, QUALITO } from 'map/constants/ThemesConstants'
import { Map } from 'ol'
import VectorLayer from 'ol/layer/Vector'
import AQUASYS_LOGO_NOIR from '../../assets/pictures/logo_aquasys_noir.png'
import { makeStyles } from '@mui/styles'
import SettingsIcon from '@mui/icons-material/Settings'
import RoomIcon from '@mui/icons-material/Room'
import AnimatedCluster from 'ol-ext/layer/AnimatedCluster'
import { useLocation } from 'react-router'
import queryString from 'query-string'
import { useSelector } from 'react-redux'

const LAYERS = 'LAYERS'
const BACKGROUNDS = 'BACKGROUNDS'
const SETTINGS = 'SETTINGS'

const useStyles = makeStyles(() => ({
    logo: {
        width: '100%',
        height: 'auto',
        cursor: 'pointer',
    },
}))

const MapOverlay = ({
    layers,
    watershedPolygonLayer,
    backgrounds,
    background,
    setBackground,
    disableCluster = false,
    setDisableCluster,
    clusterDistance = 25,
    setClusterDistance,
}) => {
    const theme = useTheme()
    const classes = useStyles()
    const smMatches = useMediaQuery((th) => th.breakpoints.up('sm'))

    const selections = useSelector(store => store.SelectionReducer.selections)

    const location = useLocation()
    const selectionCode = parseInt(queryString.parse(location.search).selection)
    const selection = selections.find(s => s.code === selectionCode)

    const [selectedLayers, setSelectedLayers] = useState(selection ? selection.themes : [QUALITO, PIEZO, HYDRO, PLUVIO])

    const getTabs = [{
        name: LAYERS,
        icon: <RoomIcon/>,
    },
    {
        name: BACKGROUNDS,
        icon: <PhotoIcon/>,
    },
    {
        name: SETTINGS,
        icon: <SettingsIcon/>,
    }]

    useEffect(() => {
        setSelectedLayers(selection ? selection.themes : [QUALITO, PIEZO, HYDRO, PLUVIO])
    }, [disableCluster, selection])

    return (
        <>
            <Grid container direction='row' justifyContent='flex-end' alignItems='center' spacing={2} sx={{ position: 'absolute' }}>
                { smMatches && (
                    <Grid item container xs={0} sm={1} style={{ zIndex: 8, paddingRight: 4, paddingTop: 25 }}>
                        <BurgerMenu fromMap={true}/>
                    </Grid>
                )}
                <Grid item xs={12} sm={11} style={{ paddingLeft: 20, paddingRight: 4, paddingTop: 25 }}>
                    <Grid container sm={9} xl={7}>
                        {
                            <AutocompleteAquadb saveData={{
                                layers: layers.filter(l => selectedLayers.includes(l.code)),
                                watersheds: watershedPolygonLayer ? watershedPolygonLayer.getSource().getFeatures().map(f => f.getId()) : [],
                                selection,
                            }}
                            />
                        }
                    </Grid>
                </Grid>
                <SideBar tabs={getTabs}>
                    {
                        tab => (
                            <>
                                {tab === LAYERS && (<LayersTab layers={layers} selectedLayers={selectedLayers}
                                    onClick={
                                        (_, newValue, l) => {
                                            if (newValue) {
                                                setSelectedLayers(sl => [
                                                    ...sl,
                                                    l.code,
                                                ])
                                            } else {
                                                setSelectedLayers(sl => sl.filter(e => e !== l.code))
                                            }
                                        }
                                    } disableCluster={disableCluster}
                                />)}
                                {tab === BACKGROUNDS && (<BackgroundTab backgrounds={backgrounds}
                                    selectedBackground={background}
                                    onClick= {
                                        (_, bgcode) => {
                                            setBackground(bgcode)
                                        }
                                    }
                                />)}
                                {tab === SETTINGS && (<SettingsTab
                                    disableCluster={!disableCluster}
                                    setDisableCluster={(_, newValue) => setDisableCluster(!newValue)}
                                    clusterDistance={clusterDistance}
                                    setClusterDistance={(_, newValue) => setClusterDistance(newValue)}
                                />)}
                            </>
                        )
                    }
                </SideBar>
            </Grid>
            { !smMatches ? (
                <Box sx={theme.menu.burger.mobile}>
                    <BurgerMenu/>
                    <Grid container xs={3} style={{ zIndex: 8 }}>
                        <Grid container item>
                            <img className={classes.logo} src={AQUASYS_LOGO_NOIR} alt='Aquasys logo' onClick={() => window.open('https://www.aquasys.fr/', '_blank')}/>
                        </Grid>
                    </Grid>
                </Box>
            ) : (
                <Grid container sm={4} md={3} lg={2} position={'absolute'} bottom={10} left={10} style={{ zIndex: 8 }}>
                    <Grid container item>
                        <img className={classes.logo} src={AQUASYS_LOGO_NOIR} alt='Aquasys logo' onClick={() => window.open('https://www.aquasys.fr/', '_blank')}/>
                    </Grid>
                </Grid>
            )}
        </>
    )
}

MapOverlay.propTypes = {
    map: PropTypes.shape({
        current: PropTypes.instanceOf(Map),
    }),
    layers: PropTypes.arrayOf(PropTypes.shape({
        layer: PropTypes.oneOfType([
            PropTypes.instanceOf(AnimatedCluster),
            PropTypes.instanceOf(VectorLayer),
        ]),
        code: PropTypes.string,
        label: PropTypes.string,
        isSelected: PropTypes.bool,
    })),
    watershedPolygonLayer: PropTypes.instanceOf(VectorLayer),
    selection: PropTypes.instanceOf(DtoSelection),
    backgrounds: PropTypes.arrayOf(PropTypes.shape({
        code: PropTypes.string,
        label: PropTypes.string,
        layer: PropTypes.shape({
            url: PropTypes.string,
        }),
        preview: PropTypes.string,
    })),
    background: PropTypes.string,
    setBackground: PropTypes.func,
    disableCluster: PropTypes.bool,
    setDisableCluster: PropTypes.func,
    clusterDistance: PropTypes.number,
    setClusterDistance: PropTypes.func,
}

export default MapOverlay