import React, { useEffect, useMemo, useState } from 'react'
import i18n from 'simple-react-i18n'
import { push } from 'connected-react-router'
import { Grid } from '@mui/material'
import { useTheme } from '@mui/styles'
import { InputRow } from 'components/styled/Inputs'
import { PrimaryButton } from 'components/styled/Buttons'
import { useDispatch, useSelector } from 'react-redux'
import { AQUADB_LOGO, AQUASYS_LOGO } from 'home/constants/HomeConstants'
import AuthenticationThunk from './actions/AuthenticationThunk'

const Reset = () => {
    const theme = useTheme()
    const dispatch = useDispatch()

    const { applicationSettings } = useSelector(store => ({
        applicationSettings: store.AuthenticationReducer.applicationSettings,
    }),)

    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirmation, setPasswordConfirmation] = useState('')
    const [tel, setTel] = useState('')
    const [isEmailError, setEmailError] = useState(false)
    const [isNameError, setNameError] = useState(false)
    const [isPasswordError, setPasswordError] = useState(false)

    useEffect(() => {
        dispatch(AuthenticationThunk.fetchApplicationSettings())
    }, [dispatch])

    const passwordHelperText = useMemo(() => {
        return applicationSettings.find((s) => s.parameter === 'securityPasswordDescription')?.value ?? ''
    }, [applicationSettings])

    const onSubmit = () => {
        const isValid = email && name && password && password === passwordConfirmation
        setEmailError(!email)
        setNameError(!name)
        setPasswordError(!password || password !== passwordConfirmation)

        const isValidEmail = (!!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email))
        setEmailError(!isValidEmail)

        const isValidName = name.length > 4 && name.length < 50
        setNameError(!isValidName)

        const passwordPolicy = applicationSettings.find((s) => s.parameter === 'passwordPolicy')
        const passwordMatch = !passwordPolicy || RegExp(passwordPolicy.value).test(password)
        setPasswordError(!passwordMatch || password !== passwordConfirmation)

        if (isValid && isValidEmail && isValidName && passwordMatch) {
            const user = {
                login: email,
                name,
                passwd: password,
                tel: `${tel || ''}`,
            }
            dispatch(AuthenticationThunk.createUser(user))
        }
    }

    return (
        <Grid
            container
            direction='row'
            justifyContent='space-evenly'
            alignItems='flex-start'
            sx={theme.menu.backgroundAquadb}
        >
            <Grid container sx={theme.menu.center} justifyContent={'center'}>
                <Grid item xs={12} sx={theme.menu.logoAquadb}>
                    <img src={AQUADB_LOGO} alt='' onClick={() => dispatch(push('/home'))}/>
                </Grid>
                <Grid container item xs={10} md={5} justifyContent={'center'} style={{ backgroundColor: 'white', margin: '50px 0', padding: '50px 0', borderRadius: 3 }}>
                    <Grid container justifyContent={'center'} style={{ fontSize: '1.5em', marginBottom: 15 }}>
                        {i18n.inscription}
                    </Grid>
                    <Grid item xs={11} md={7}>
                        <InputRow
                            id='email'
                            required
                            label={i18n.email}
                            type='email'
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value)
                                setEmailError(false)
                            }}
                            helperText={isEmailError ? i18n.invalidEmail : i18n.warningEmail}
                            variant='standard'
                            error={isEmailError}
                            style={{ marginBottom: 10 }}
                        />
                    </Grid>
                    <Grid item xs={11} md={7}>
                        <InputRow
                            id='name'
                            required
                            label={i18n.name}
                            value={name}
                            onChange={(e) => {
                                setName(e.target.value)
                                setNameError(false)
                            }}
                            helperText={isNameError ? i18n.invalidName : ''}
                            variant='standard'
                            error={isNameError}
                            style={{ marginBottom: 10 }}
                        />
                    </Grid>
                    <Grid item xs={11} md={7}>
                        <InputRow
                            id='password'
                            required
                            label={i18n.password}
                            type='password'
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value)
                                setPasswordError(false)
                            }}
                            variant='standard'
                            error={isPasswordError}
                            style={{ marginBottom: 10 }}
                        />
                    </Grid>
                    <Grid item xs={11} md={7}>
                        <InputRow
                            required
                            label={i18n.newPasswordAgain}
                            type='password'
                            value={passwordConfirmation}
                            onChange={(e) => {
                                setPasswordConfirmation(e.target.value)
                                setPasswordError(false)
                            }}
                            helperText={passwordHelperText}
                            variant='standard'
                            error={isPasswordError}
                            style={{ marginBottom: 10 }}
                        />
                    </Grid>
                    <Grid item xs={11} md={7}>
                        <InputRow
                            id='tel'
                            label={i18n.tel}
                            type='tel'
                            value={tel}
                            onChange={(e) => {
                                setTel(e.target.value)
                            }}
                            variant='standard'
                            style={{ marginBottom: 10 }}
                        />
                    </Grid>
                    <Grid item xs={11} md={7}>
                        <PrimaryButton onClick={onSubmit} style={{ marginTop: 15 }}>
                            {i18n.signup}
                        </PrimaryButton>
                    </Grid>
                    <Grid item xs={11} md={7}>
                        <PrimaryButton
                            variant='contained'
                            reverse='true'
                            style={{ marginTop: 15 }}
                            onClick={() => dispatch(push('/login'))}
                        >
                            {i18n.cancel}
                        </PrimaryButton>
                    </Grid>
                </Grid>
                <Grid item xs={12} container justifyContent='center' alignItems='center' sx={theme.menu.logoAquasys} >
                    <img src={AQUASYS_LOGO} alt='' onClick={() => window.open('https://www.aquasys.fr/', '_blank')} />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Reset
