import {
    RECEIVE_CMS_CATEGORIES,
    RECEIVE_CMS_EVENT,
    RECEIVE_CMS_EVENTS,
    RECEIVE_CMS_DOCUMENT,
    RECEIVE_CMS_CATEGORY,
    RECEIVE_CMS_ACTUALITIES,
} from '../constants/CmsConstants'
import DtoCMSEvent from '../dto/DtoCMSEvent'
import DtoCMSCategory from '../dto/DtoCMSCategory'

export function CmsReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_CMS_CATEGORY:
            return { ...state, cms: action.cms.map((e) => new DtoCMSEvent(e)) }
        case RECEIVE_CMS_EVENTS:
            return { ...state, cmsEvents: action.cmsEvents.map((n) => new DtoCMSEvent(n)) }
        case RECEIVE_CMS_EVENT:
            return { ...state, cmsEvent: new DtoCMSEvent(action.cmsEvent) }
        case RECEIVE_CMS_CATEGORIES:
            return { ...state, cmsCategories: action.categories.map((n) => new DtoCMSCategory(n)) }
        case RECEIVE_CMS_DOCUMENT:
            return { ...state, document: action.document }
        case RECEIVE_CMS_ACTUALITIES:
            return { ...state, actualities: action.actualities.map((n) => new DtoCMSEvent(n)) }
        default:
            return state
    }
}

export const store = {
    cms: [],
    cmsEvents: [],
    cmsEvent: {},
    cmsCategories: [],
    document: {},
    actualities: [],
}
