import { createAsyncThunk } from '@reduxjs/toolkit'
import ApplicationConf from 'conf/ApplicationConf'
import {
    getAuthorization,
    getAuthorizationLogin,
    getJson,
} from 'utils/ActionUtils'
import { HomeAction } from '../../home/reducers/HomeReducer'

const CGUThunk = {
    fetchCGU: createAsyncThunk(
        'fetchCGU',
        async(_, { rejectWithValue, dispatch }) => {
            try {
                const response = await fetch(ApplicationConf.cms.getByCategory(1), {
                    method: 'GET',
                    headers: getAuthorizationLogin(),
                }).then(getJson).then((json) => {
                    dispatch(HomeAction.setCGU(json[0]))
                })
                return response
            } catch (err) {
                return rejectWithValue(err)
            }
        }
    ),
    fetchCGUValidDate: createAsyncThunk(
        'fetchCGUValidDate',
        async(login, { rejectWithValue, dispatch }) => {
            try {
                const response = await fetch(ApplicationConf.cgu.getDateValidCGU(login), {
                    method: 'GET',
                    headers: getAuthorizationLogin(),
                }).then(getJson).then((json) => dispatch(HomeAction.setCGUValidDate(json.date)))
                return response
            } catch (err) {
                return rejectWithValue(err)
            }
        }
    ),
    sendDateCgu: createAsyncThunk(
        'sendDateCgu',
        async(args, { rejectWithValue, dispatch }) => {
            try {
                const response = await fetch(ApplicationConf.cgu.sendDateCgu(), {
                    method: 'POST',
                    headers: getAuthorization(),
                    body: JSON.stringify(args),
                }).finally(() => dispatch(HomeAction.setShowCGU(false)))
                return response
            } catch (err) {
                return rejectWithValue(err)
            }
        }
    ),
}

export default CGUThunk
