/* eslint-disable max-nested-callbacks */
import { createAsyncThunk } from '@reduxjs/toolkit'
import ApplicationConf from 'conf/ApplicationConf'
import { genericPublicPromise } from 'utils/ActionUtils'
import i18n from 'simple-react-i18n'
import LogAction from 'log/actions/LogAction'
import { ToastrAction } from '../../toastr/reducers/ToastrReducer'

const SearchBarThunk = {
    search: createAsyncThunk(
        'search',
        async(body, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPublicPromise(ApplicationConf.referencial.search(), 'POST', JSON.stringify(body))
                    .then(json => {
                        const options = json.flatMap(group => group[1].map(option => ({
                            group: group[0],
                            code: option[0],
                            name: option[1],
                            extra: `${option.length > 2 ? option[2] : ''}`,
                        })))
                        return options
                    })
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.research}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        }
    ),
}

export default SearchBarThunk
