import React from 'react'
import { Button, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { push } from 'connected-react-router'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import AutocompleteAquadb from 'searchBar/AutocompleteAquadb'
import useMediaQuery from '@mui/material/useMediaQuery'
import { HOME_BACKGROUND_IMAGE, AQUADB_LOGO, AQUASYS_LOGO } from './constants/HomeConstants'
import BurgerMenu from 'burgerMenu/BurgerMenu'

const useStyles = makeStyles(() => ({
    title: {
        color: 'white',
        fontWeight: 'bold',
        textShadow: 'black 0px 2px 5px',
    },
    aquadbLogo: {
        maxWidth: '95%',
        height: 'auto',
    },
    aquasysLogo: {
        width: '60%',
        height: 'auto',
        maxWidth: 220,
        minWidth: 80,
        cursor: 'pointer',
    },
    subtitle: {
        color: 'white',
        fontSize: '0.9em',
    },
    background: {
        backgroundImage: HOME_BACKGROUND_IMAGE,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundAttachment: 'fixed',
        backgroundSize: 'cover',
        overflowX: 'hidden',
        height: '100%',
        width: '100%',
    },
    hide: {
        display: 'none !important',
    },
    white: {
        color: 'white',
    },
    button: {
        border: 'none',
        padding: '10px',
        cursor: 'pointer',
        borderRadius: '3px',
        backgroundColor: 'white',
        fontSize: '1em',
        fontWeight: 'bold',
        width: '100%',
        boxShadow: '1px 1px 12px rgba(0,0,0,0.5)',
        '&:hover': {
            transitionDuration: '0.2s',
            backgroundColor: '#A1A1A1',
        },
    },
}))

const Home = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const mdMatches = useMediaQuery((th) => th.breakpoints.up('md'))
    const smMatches = useMediaQuery((th) => th.breakpoints.up('sm'))

    const { isAutocompleteSelected, login } = useSelector(store => ({
        isAutocompleteSelected: store.HomeReducer.isAutocompleteSelected,
        login: store.AuthenticationReducer.login,
    }),)

    const [isDisplayLogin, setDisplayLogin] = useState(!login)

    const navigate = (path) => {
        dispatch(push(path))
    }

    useEffect(() => {
        setDisplayLogin(!login)
    }, [login])

    return (
        <div className={classes.background}>
            <Grid container style={{ padding: 8 }}>
                <Grid item container xs={1} sm={4} justifyContent='flex-start' alignItems='flex-end' className={`${isAutocompleteSelected && !smMatches ? classes.hide : ''}`} style={{ paddingRight: 8 }}>
                    <BurgerMenu color='light'/>
                </Grid>
                <Grid item container xs={11} sm={8} justifyContent='flex-end' alignItems='flex-end' className={`${isAutocompleteSelected && !smMatches ? classes.hide : ''}`} style={{ paddingRight: 8 }}>
                    {
                        isDisplayLogin &&
                        <Button
                            variant='contained'
                            onClick={() => navigate('/login')}
                            style={{ backgroundColor: 'white', color: 'black' }}
                        >
                            {i18n.log_in}
                        </Button>
                    }
                </Grid>
                <Grid container justifyContent={'center'} paddingTop={8}>
                    <Grid container item xs={12} sm={10} rowSpacing={2}>
                        <Grid container item xs={8} className={`${!mdMatches && isAutocompleteSelected && !smMatches ? classes.hide : ''}`} style={{ marginBottom: 20 }}>
                            <img src={AQUADB_LOGO} alt='Logo aQuaDB' className={classes.aquadbLogo} style={ smMatches ? { marginTop: 210 } : { marginTop: 170 }} />
                        </Grid>
                        <Grid item sm={8} className={`${isAutocompleteSelected && !smMatches ? classes.hide : ''}`}>
                            <div className={classes.title} style={ smMatches ? { fontSize: '4.5vmin' } : { fontSize: '1.5em' } }>{ i18n.aquadbTitle }</div>
                        </Grid>
                        <Grid item xs={12}>
                            <AutocompleteAquadb/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container item justifyContent='flex-end' alignItems='center' xs={12} style={{ position: 'absolute', bottom: 8, textAlign: 'right', paddingRight: 8 }}>
                <Grid item>
                    <div className={classes.subtitle}>{ i18n.aquadbDevelopedBy }</div>
                </Grid>
                <Grid item>
                    <img style={{ width: '100%' }} src={AQUASYS_LOGO} alt='Aquasys logo' className={classes.aquasysLogo} onClick={() => window.open('https://www.aquasys.fr/', '_blank')}/>
                </Grid>
            </Grid>
        </div>
    )
}

export default Home