import { createSlice } from '@reduxjs/toolkit'
import AuthenticationThunk from 'authentication/actions/AuthenticationThunk'
import { SIEAU_LOGIN } from 'pages/offline/constants/HomeConstants'

export const initialState = {
    cgu: {},
    dateValidCgu: '',
    login: localStorage.getItem(SIEAU_LOGIN),
    applicationSettings: [],
}

const store = createSlice({
    name: 'login',
    initialState,
    reducers: {
        setLogin(state, action) {
            state.login = action.payload
        },
    },
    extraReducers: {
        [AuthenticationThunk.logout.fulfilled]: (state) => {
            state.login = ''
        },
        [AuthenticationThunk.fetchApplicationSettings.fulfilled]: (state, action) => {
            state.applicationSettings = action.payload
        },
    },
})

export const AuthenticationAction = store.actions
export default store.reducer