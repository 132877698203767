import { path } from './basepath'

// const localApi = 'http://localhost:8000/'

export default {
    login: () => `${path}login`,
    logout: () => `${path}logout`,
    resetPassword: () => `${path}reset`,
    verifyResetCode: () => `${path}verifyResetCode`,
    ping: pathToPing => `https://${pathToPing}/api/mobile/ping`,
    log: {
        error: () => `${path}log/error`,
        info: () => `${path}log/info`,
        debug: () => `${path}log/debug`,
    },
    station: {
        links: type => `${path}${type}/links`,
        stationLayer: (type, code) => `${path}/public/layer/station/${type}/${code}`,
    },
    contact: {
        getChat: (login, dest) => `${path}user/messagesDest/conv/${login}/${dest}`,
        getMessage: (id) => `${path}user/messages/${id}`,
        sendMessage: () => `${path}user/messages`,
        defConv: () => `${path}user/messagesDest`,
        getMessagesOf: (login) => `${path}user/messages/login/${login}`,
    },
    qualitometer: {
        getAll: params => `${path}qualitometer${params}`,
        getAllByIds: () => `${path}qualitometer/byIds`,
        getAllGeoFormat: () => `${path}qualitometer/geoOutput`,
        get: id => `${path}qualitometer/${id}`,
        events: id => `${path}qualitometer/${id}/event`,
        operations: id => `${path}qualitometer/${id}/operation`,
        contact: id => `${path}qualitometer/${id}/contact`,
        contributor: id => `${path}station/qualitometer/${id}/contributor`,
        localisation: id => `${path}qualitometer/${id}/localisation`,
    },
    piezometer: {
        getAll: params => `${path}piezometer${params}`,
        getAllByIds: () => `${path}piezometer/byIds`,
        getAllGeoFormat: () => `${path}piezometer/geoOutput`,
        get: id => `${path}piezometer/${id}`,
        events: id => `${path}piezometer/${id}/event`,
        eventUpdate: (stationId, eventId) => `${path}piezometer/${stationId}/event/${eventId}`,
        event: (id, eventId) => `${path}piezometer/${id}/event/${eventId}`,
        allEvents: () => `${path}piezometer/allEvents`,
        eventWithDiagnostics: () => `${path}piezometer/eventDiagnostic`,
        updateEventWithDiagnostics: id => `${path}piezometer/eventDiagnostic/${id}`,
        measures: id => `${path}piezometer/measure/${id}/brute`,
        additionalMeasures: (stationId, type) => `${path}piezometer/measure/${stationId}/type/${type}`,
        measuresMax: () => `${path}piezometer/situation/stations/raw`,
        threshold: id => `${path}piezometer/piezometerThreshold/${id}`,
        situation: () => `${path}piezometer/situation/stations/type`,
        dataTypes: () => `${path}piezometer/dataTypes`,
        chartOptions: id => `${path}piezometer/chartOptions/${id}`,
        contact: id => `${path}piezometer/${id}/contact`,
        contributor: id => `${path}piezometer/${id}/contributor`,
        accessibility: id => `${path}piezometer/${id}/accessibilities/`,
        links: () => `${path}piezometer/links`,
        localisation: id => `${path}piezometer/${id}/localisation`,
        situationLastMeasures: () => `${path}piezometer/situation/lastMeasures`,

    },
    hydrometricStation: {
        getAll: params => `${path}hydrologicalStation${params}`,
        getAllByIds: () => `${path}hydrologicalStation/byIds`,
        getAllGeoFormat: () => `${path}hydrologicalStation/geoOutput`,
        get: code => `${path}hydrologicalStation/${code}`,
        events: id => `${path}hydrologicalStation/${id}/event`,
        measures: code => `${path}hydrologicalStation/${code}/measures`,
        measuresMax: () => `${path}hydrologicalStation/situation`,
        contact: id => `${path}hydrologicalStation/${id}/contact`,
        localisation: id => `${path}hydrologicalStation/${id}/localisation`,
        lastMeasures: () => `${path}hydrologicalStation/chronic/lastMeasures`,
    },

    installation: {
        getAll: params => `${path}installation${params}`,
        getAllByIds: () => `${path}installation/byIds`,
        getAllGeoFormat: () => `${path}installation/geoOutput`,
        get: id => `${path}installation/${id}`,
        getBorehole: id => `${path}installation/borehole/${id}`,
        getAllBoreholes: () => `${path}installation/borehole/`,
        insert: () => `${path}installation`,
        contact: id => `${path}installation/${id}/contact`,
        contributor: id => `${path}installation/${id}/contributors`,
        localisation: id => `${path}installation/${id}/localisation`,
        events: id => `${path}installation/${id}/event`,
    },
    pluviometer: {
        getAll: params => `${path}pluviometer${params}`,
        getAllByIds: () => `${path}pluviometer/byIds`,
        getAllGeoFormat: () => `${path}pluviometer/geoOutput`,
        get: code => `${path}pluviometer/${code}`,
        events: id => `${path}pluviometer/${id}/event`,
        contact: id => `${path}pluviometer/${id}/contact`,
        lastMeasures: () => `${path}pluviometer/lastmeasures`,
    },
    cms: {
        getAll: () => `${path}cms`,
        insert: () => `${path}cms`,
        get: id => `${path}cms/${id}`,
        delete: id => `${path}cms/${id}`,
        update: id => `${path}cms/${id}`,
        categories: () => `${path}cms/category`,
        getByCategory: (id) => `${path}cms/${id}/category`,
    },
    referencial: {
        search: () => `${path}public/referential/searchaquadb/`,
        cityByCoordinate: (lat, lon) => `https://geo.api.gouv.fr/communes?lat=${lat}&lon=${lon}&fields=nom,code,codesPostaux,codeDepartement,codeRegion,population&format=json&geometry=centre`,
        cityLayer: (type, code) => `${path}public/layer/city/${type}/${code}`,
        departmentLayer: (type, code) => `${path}public/layer/dept/${type}/${code}`,
        watershedLayer: (type, code) => `${path}public/layer/watershed/${type}/${code}`,
        contact: id => `${path}referencial/contact/${id}`,
        contacts: () => `${path}referencial/contact`,
        city: code => `${path}city/${code}`,
        cities: () => `${path}city/`,
        contributor: id => `${path}referencial/contributor/${id}`,
        contributors: () => `${path}referencial/contributor/`,
        sandreCodes: () => `${path}sandre/`,
        // watersheds: () => `${path}watershed/`,
        aquifers: () => `${path}referencial/aquifer/`,
    },
    materiel: {
        materielChannel: () => `${path}materiel/`,
        centralChannel: id => `${path}materiel/central/${id}/channel`,
        centralRange: id => `${path}materiel/central/${id}/range`,
        centrals: () => `${path}materiel/central`,
        centralsLastSituations: () => `${path}materiel/central/lastSituation/`,
        centralsSituationByEvent: id => `${path}materiel/central/situation/event/${id}`,
        central: id => `${path}materiel/central/${id}`,
        centralTypes: () => `${path}materiel/central/type`,
        powerSupplies: () => `${path}materiel/powerSupply`,
        powerSuppliesLastSituations: () => `${path}materiel/powerSupply/lastSituation/`,
        powerSuppliesSituationByEvent: id => `${path}materiel/powerSupply/situation/event/${id}`,
        powerSupply: id => `${path}materiel/powerSupply/${id}`,
        powerSupplyTypes: () => `${path}materiel/powerSupply/type`,
        sensors: () => `${path}materiel/sensor`,
        sensorsLastSituations: () => `${path}materiel/sensor/lastSituation/`,
        sensorsSituationByEvent: id => `${path}materiel/sensor/situation/event/${id}`,
        sensor: id => `${path}materiel/sensor/${id}`,
        sensorTypes: () => `${path}materiel/sensor/type`,
        simsLastSituations: () => `${path}materiel/sim/lastSituation/`,
        simsSituationByEvent: id => `${path}materiel/sim/situation/event/${id}`,
        sims: () => `${path}materiel/sim`,
        sim: id => `${path}materiel/sim/${id}`,
        simTypes: () => `${path}materiel/sim/type`,
        telecoms: () => `${path}materiel/telecom`,
        telecomsLastSituations: () => `${path}materiel/telecom/lastSituation/`,
        telecomsSituationByEvent: id => `${path}materiel/telecom/situation/event/${id}`,
        telecom: id => `${path}materiel/telecom/${id}`,
        telecomTypes: () => `${path}materiel/telecom/type`,
        variousMateriels: () => `${path}materiel/variousMateriel`,
        variousMaterielsLastSituations: () => `${path}materiel/variousMateriel/lastSituation/`,
        variousMaterielsSituationByEvent: id => `${path}materiel/variousMateriel/situation/event/${id}`,
        variousMateriel: id => `${path}materiel/variousMateriel/${id}`,
        variousMaterielTypes: () => `${path}materiel/variousMateriel/type`,
        equipments: () => `${path}materiel/equipment`,
        equipmentsLastSituations: () => `${path}materiel/equipment/lastSituation/`,
        equipmentsSituationByEvent: id => `${path}materiel/equipment/situation/event/${id}`,
        equipment: equipmentId => `${path}materiel/equipment/${equipmentId}`,
        equipmentTypes: () => `${path}materiel/equipment/type`,

        getPiezometerCentralAssignment: id => `${path}materiel/central/piezometer/station/${id}`,
        getPiezometerPowerSupplyAssignment: id => `${path}materiel/powerSupply/piezometer/station/${id}`,
        getPiezometerSensorAssignment: id => `${path}materiel/sensor/piezometer/station/${id}`,
        getPiezometerSimAssignment: id => `${path}materiel/sim/piezometer/station/${id}`,
        getPiezometerTelecomAssignment: id => `${path}materiel/telecom/piezometer/station/${id}`,
        getPiezometerVariousMaterielAssignment: id => `${path}materiel/variousMateriel/piezometer/station/${id}`,
        getPiezometerEquipmentAssignment: id => `${path}materiel/equipment/piezometer/station/${id}`,

        getQualitometerCentralAssignment: id => `${path}materiel/central/qualitometer/station/${id}`,
        getQualitometerPowerSupplyAssignment: id => `${path}materiel/powerSupply/qualitometer/station/${id}`,
        getQualitometerSensorAssignment: id => `${path}materiel/sensor/qualitometer/station/${id}`,
        getQualitometerSimAssignment: id => `${path}materiel/sim/qualitometer/station/${id}`,
        getQualitometerTelecomAssignment: id => `${path}materiel/telecom/qualitometer/station/${id}`,
        getQualitometerVariousMaterielAssignment: id => `${path}materiel/variousMateriel/qualitometer/station/${id}`,
        getQualitometerEquipmentAssignment: id => `${path}materiel/equipment/qualitometer/station/${id}`,

        getHydrometricStationCentralAssignment: id => `${path}materiel/central/hydrometry/${id}/lastSituation`,
        getHydrometricStationPowerSupplyAssignment: id => `${path}materiel/powerSupply/hydrometry/${id}/lastSituation`,
        getHydrometricStationSensorAssignment: id => `${path}materiel/sensor/hydrometry/${id}/lastSituation`,
        getHydrometricStationSimAssignment: id => `${path}materiel/sim/hydrometry/${id}/lastSituation`,
        getHydrometricStationTelecomAssignment: id => `${path}materiel/telecom/hydrometry/${id}/lastSituation`,
        getHydrometricStationVariousMaterielAssignment: id => `${path}materiel/variousMateriel/hydrometry/${id}/lastSituation`,

        getPluviometerCentralAssignment: id => `${path}materiel/central/pluviometer/station/${id}`,
        getPluviometerPowerSupplyAssignment: id => `${path}materiel/powerSupply/pluviometer/station/${id}`,
        getPluviometerSensorAssignment: id => `${path}materiel/sensor/pluviometer/station/${id}`,
        getPluviometerSimAssignment: id => `${path}materiel/sim/pluviometer/station/${id}`,
        getPluviometerTelecomAssignment: id => `${path}materiel/telecom/pluviometer/station/${id}`,
        getPluviometerVariousMaterielAssignment: id => `${path}materiel/variousMateriel/pluviometer/station/${id}`,
        getPluviometerEquipmentAssignment: id => `${path}materiel/equipment/pluviometer/station/${id}`,

        materielByEvent: (id, date) => `${path}materiel/piezometer/${id}/event/${date}`,
        deletePowerSupplySituation: id => `${path}materiel/powerSupply/situation/'${id}`,
        situationGeneric: matCategory => `${path}materiel/${matCategory}/situation/`,
        states: () => `${path}materiel/state`,

    },
    files: {
        stationPicture: code => `${path}files/picture/${code}`,
        getPicturesFtp: () => `${path}files/pictureftp`,
        addPicture: () => `${path}files/uploadPictureFtp`,
        stationDocument: code => `${path}files/document/${code}`,
        addDocument: () => `${path}files/uploadDocumentFtp`,
        delete: () => `${path}files/delete`,
    },
    campaign: {
        campaigns: stationType => `${path}campaign/${stationType}`,
        campaign: (stationType, id) => `${path}campaign/${stationType}/${id}`,
        campaignStations: stationType => `${path}campaign/${stationType}/station`,
        campaignStation: (stationType, id) => `${path}campaign/${stationType}/${id}/station`,
        campaignsProgress: stationType => `${path}campaign/${stationType}/progress`,
        campaignProgress: (stationType, id) => `${path}campaign/${stationType}/${id}/progress`,
    },
    events: {
        diagnostics: () => `${path}piezometer/diagnostic/`,
        solutions: () => `${path}piezometer/solution/`,
        linkDiagnosticsSolutions: () => `${path}piezometer/solution/linkProblem`,
        linkDiagnosticsEventTypes: () => `${path}piezometer/diagnostic/linkEventType`,
        linkDiagnosticsMateriel: () => `${path}piezometer/diagnostic/linkMaterial`,
        getActions: (piezoId, eventId) => `${path}piezometer/${piezoId}/event/${eventId}/actions`,
        getReplacementActions: (piezoId, date) => `${path}piezometer/${piezoId}/event/lastReplacementActions/${date}`,
        eventsToClose: () => `${path}piezometer/eventsToClose`,
    },
    cgu: {
        getAll: () => 'https://www.aquasys.fr/wp-json/wp/v2/pages/3995',
        getDateValidCGU: (login) => `${path}user/statistic/cgu/${login}`,
        sendDateCgu: () => `${path}statisticApplication/user/cgu`,
    },
    user: {
        createUser: () => `${path}public/user/`,
        get: (login) => `${path}user/${login}`,
        getAll: () => `${path}user/`,
        delete: (login) => `${path}user/${login}`,
        getKeyFigures: (login) => `${path}user/keyfigures/${login}`,
        getStatistics: (login) => `${path}user/statistic/${login}`,
        getStationStatistics: (login) => `${path}user/statistic/station/${login}`,
        getCmsStatistics: (login) => `${path}user/statistic/cms/${login}`,
        getUsageStatistics: (login) => `${path}user/statistic/usage/${login}`,
        getUserCGUDate: (login) => `${path}user/statistic/cgu/${login}`,
        postUserCGUDate: () => `${path}statisticApplication/user/cgu`,
        userStations: (login) => `${path}user/station/${login}`,
        put: () => `${path}user`,
        filters: () => `${path}user/filters/station`,
        filterResults: (module, filterCode) =>
            `${path}user/filters/station/${module}/${filterCode}/execute`,
        getBookmarks: () => `${path}user/bookmarks`,
        setBookmark: () => `${path}user/bookmark`,
        settings: () => `${path}user/parameters`,
        applicationSettings: () => `${path}user/parameter/sieau`,
        saveSettings: (login) => `${path}user/parameters/${login}`,
        setting: () => `${path}user/parameter`,
        habilitations: (login = '') => {
            if (login) {
                return `${path}user/habilitations/${login}`
            }
            return `${path}user/habilitations`
        },
        themeAssign: () => `${path}user/theme`,
        assignedThemesLayers: () => `${path}user/theme/all`,
        applicationHabilitations: () => `${path}user/applicationHabilitations`,
        sieauParameters: (parameter) => `${path}user/parameter/sieau/${parameter}`,
        userView: () => `${path}user/view/`,
        genericUserView: () => `${path}user/view/generic/`,
        changePassword: () => `${path}user/password`,
        updatePassword: (login) => `${path}user/password/${login}`,
        profilePicture: (login) => `${path}files/profilePicture/${login}`,
        updateProfilePicture: () => `${path}files/uploadProfilePictureFtp`,
    },
    selection: {
        create: () => `${path}aquadb/selection`,
        get: () => `${path}aquadb/selection`,
        delete: (code) => `${path}aquadb/selection/${code}`,
        export: (code) => `${path}aquadb/selection/${code}/export`,
        favorite: (code) => `${path}aquadb/selection/${code}/favorite`,
        update: (code) => `${path}aquadb/selection/${code}/update`,
    },
}
