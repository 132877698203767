import React, { useState } from 'react'
import i18n from 'simple-react-i18n'
import { Grid, IconButton, Modal } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useDispatch, useSelector } from 'react-redux'
import SaveIcon from '@mui/icons-material/Save'
import { InputRow } from 'components/styled/Inputs'
import { PrimaryButton } from 'components/styled/Buttons'
import SelectionThunk from 'selection/actions/SelectionThunk'
import PropTypes from 'prop-types'
import DtoSelection from 'selection/dto/DtoSelection'
import VectorLayer from 'ol/layer/Vector'

const useStyles = makeStyles(() => ({
    modal: {
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '7px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'left',
        '&:focus': {
            outline: 'none',
        },
    },
    title: {
        padding: '12px',
        fontSize: '1.7em',
    },
}))

const SaveSelection = ({ layers, watersheds, selection }) => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const {
        searchCriteria,
    } = useSelector(store => ({
        searchCriteria: store.MapReducer.searchCriteria,
    }),)

    const [isOpen, setOpen] = useState(false)
    const [newName, setNewName] = useState('')
    const [isNameError, setNameError] = useState(false)

    const onSubmit = (isCreate) => {
        const stations = layers.flatMap(l =>
            l.layer.current.getSource().getSource().getFeatures().map(f => ({ typ: f.get('type'), code: f.getId() }))
        )

        const filters = [
            ...searchCriteria.cities.map(c => ({ typ: 'city', code: c.code })),
            ...searchCriteria.departments.map(c => ({ typ: 'department', code: c.code })),
            ...searchCriteria.watersheds.map(c => ({ typ: 'watershed', code: c.code })),
            ...searchCriteria.stations.map(c => ({ typ: 'station', code: c.code })),
        ]

        if (isCreate && newName) {
            const body = {
                name: newName,
                filter: filters,
                station: stations,
                watershed: watersheds,
                theme: layers.map(l => l.code),
            }
            dispatch(SelectionThunk.create(body))
                .then(action => {
                    if (action.type === 'create/fulfilled') {
                        setOpen(false)
                    }
                })
        } else if (!isCreate && selection) {
            const body = {
                code: selection.code,
                name: selection.name,
                filter: filters,
                station: stations,
                watershed: watersheds,
                theme: layers.map(l => l.code),
            }
            dispatch(SelectionThunk.update({ code: selection.code, body }))
                .then(action => {
                    if (action.type === 'update/fulfilled') {
                        setOpen(false)
                    }
                })
        } else {
            setNameError(true)
        }
    }

    return (
        <>
            {!isOpen && (
                <IconButton color={'inherit'} onClick={() => setOpen(!isOpen)}>
                    <SaveIcon/>
                </IconButton>
            )
            }
            {isOpen && (
                <Modal
                    open={isOpen}
                    onClose={() => setOpen(false)}
                >
                    <Grid
                        container
                        item
                        direction='row'
                        justifyContent='center'
                        alignItems='center'
                        spacing={2}
                        className={classes.modal}
                        md={5}
                    >
                        { selection && (
                            <>
                                <Grid item xs={12} className={classes.title}>
                                    {i18n.updateSearch}{selection.name}
                                </Grid>
                                <Grid item xs={12}>
                                    <PrimaryButton onClick={() => onSubmit(false)}>{i18n.update}</PrimaryButton>
                                </Grid>
                            </>
                        )}
                        <Grid container textAlign={'center'}>
                            <Grid item xs={12} className={classes.title}>
                                {i18n.saveSearch}
                            </Grid>
                        </Grid>
                        <Grid item xs={8}>
                            <InputRow
                                required
                                label={i18n.name}
                                value={newName}
                                onChange={(e) => {
                                    setNewName(e.target.value)
                                    setNameError(false)
                                }}
                                variant='standard'
                                error={isNameError}
                            />
                        </Grid>
                        <Grid item xs={8} margin={'5px'}>
                            <PrimaryButton onClick={() => onSubmit(true)}>{i18n.save}</PrimaryButton>
                        </Grid>
                    </Grid>
                </Modal>
            )
            }
        </>
    )
}

SaveSelection.propTypes = {
    layers: PropTypes.arrayOf(PropTypes.shape({
        layer: PropTypes.shape({
            current: PropTypes.instanceOf(VectorLayer),
        }),
        code: PropTypes.string,
        label: PropTypes.string,
        isSelected: PropTypes.bool,
    })),
    watersheds: PropTypes.arrayOf(PropTypes.string),
    selection: PropTypes.instanceOf(DtoSelection),
}

export default SaveSelection
