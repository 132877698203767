import React, { useState } from 'react'
import i18n from 'simple-react-i18n'
import { Accordion, AccordionDetails, AccordionSummary, Box, Chip, Grid, IconButton, Typography, useMediaQuery } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useDispatch, useSelector } from 'react-redux'
import { HOME_BACKGROUND_IMAGE } from 'home/constants/HomeConstants'
import BurgerMenu from 'burgerMenu/BurgerMenu'
import StarOutlineIcon from '@mui/icons-material/StarOutline'
import GradeIcon from '@mui/icons-material/Grade'
import DownloadIcon from '@mui/icons-material/Download'
import DeleteIcon from '@mui/icons-material/Delete'
import { push } from 'connected-react-router'
import { useTheme } from '@emotion/react'
import SelectionThunk from 'selection/actions/SelectionThunk'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { groupBy } from 'lodash'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import PlaySelection from './PlaySelection'
import { useLocation } from 'react-router'
import queryString from 'query-string'
import { HYDRO, PIEZO, PLUVIO } from 'map/constants/ThemesConstants'
import ExportSelection from './ExportSelection'
import { formatDate } from '../../utils/DateUtil'

const useStyles = makeStyles(() => ({
    background: {
        backgroundImage: HOME_BACKGROUND_IMAGE,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundAttachment: 'fixed',
        backgroundSize: 'cover',
        overflowX: 'hidden',
        height: '100%',
        width: '100%',
    },
    title: {
        color: 'white',
        fontWeight: 'bold',
        textShadow: 'black 0px 2px 5px',
        textAlign: 'center',
        fontSize: '5vmin',
        paddingTop: '20px !important',
        paddingBottom: 30,
    },
    item: {
        backgroundColor: 'white',
        marginLeft: '12px !important',
        marginRight: '4px!important',
        paddingBottom: 8,
    },
    arrowMobile: {
        position: 'absolute !important',
        right: 15,
        bottom: 15,
        zIndex: 8,
    },
}))

const SelectionDetails = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const theme = useTheme()
    const smMatches = useMediaQuery((th) => th.breakpoints.up('sm'))
    const [selectExport, setSelectExport] = useState(null)

    const {
        selections,
    } = useSelector(store => ({
        selections: store.SelectionReducer.selections,
    }),)

    const location = useLocation()
    const selectionCode = parseInt(queryString.parse(location.search).selection)
    const selection = selections.find(s => s.code === selectionCode)

    const stationsByType = groupBy(selection.stations, s => s.type)
    const filtersByType = groupBy(selection.filters, f => f.type)

    const getTypeLabel = type => {
        switch (type) {
            case PLUVIO:
                return i18n.pluviometer
            case PIEZO:
                return i18n.piezometer
            case HYDRO:
                return i18n.hydrometricStation
            default:
                return i18n.qualitometer
        }
    }

    return (
        <div className={classes.background}>
            <ExportSelection selection={selectExport} setSelection={setSelectExport}/>
            <Grid
                container
                direction='row'
                justifyContent='center'
                alignItems='flex-start'
                spacing={1}
                style={!smMatches ? { marginTop: '20%', maxHeight: '55%' } : {}}
            >
                {
                    smMatches && (
                        <>
                            <Grid item xs={6}>
                                <BurgerMenu color='light'/>
                            </Grid>
                            <Grid item xs={6} container justifyContent='flex-end'>
                                <IconButton size='large' onClick={() => dispatch(push('/selection'))}>
                                    <ArrowForwardIcon color='light' fontSize='large' />
                                </IconButton>
                            </Grid>
                        </>
                    )
                }
                <Grid item xs={12} md={10} lg={8}>
                    <div className={classes.title}>{selection.name}</div>
                </Grid>
                <Grid item container
                    alignItems='center'
                    justifyContent='center'
                    xs={10} sm={10} lg={7}
                    className={classes.item}
                >
                    <Grid item xs={2} sm={1}>
                        <IconButton
                            onClick={() => dispatch(SelectionThunk.favorite(selection.code))}
                        >
                            { selection.isFavorite ? <GradeIcon htmlColor='#ffcc00'/> : <StarOutlineIcon/>}
                        </IconButton>
                    </Grid>
                    <Grid item xs={2} sm={1}>
                        <PlaySelection selection={selection}/>
                    </Grid>
                    <Grid item xs={2} sm={1}>
                        <IconButton>
                            <DownloadIcon onClick={() => setSelectExport(selection)} color='success'/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={2} sm={1}>
                        <IconButton
                            onClick={() => {
                                dispatch(SelectionThunk.delete(selection.code))
                                dispatch(push('/selection'))
                            }}
                        >
                            <DeleteIcon color='error'/>
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid item xs={10} sm={10} lg={7}
                    className={classes.item}
                    style={{ borderTop: '1px solid lightgray' }}
                >
                    {i18n.creation} : {formatDate(selection.creationTimestamp)}
                </Grid>
                {
                    selection.updateTimestamp && (
                        <Grid item xs={10} sm={10} lg={7} className={classes.item}>
                            {i18n.lastUpdate} : {formatDate(selection.updateTimestamp)}
                        </Grid>
                    )
                }
                {
                    selection.exportTimestamp && (
                        <Grid item xs={10} sm={10} lg={7} className={classes.item}>
                            {i18n.lastExport} : {formatDate(selection.exportTimestamp)}
                        </Grid>
                    )
                }
                {
                    selection.themes.map(t => (
                        <Grid item xs={10} sm={10} lg={7} key={t}>
                            <Typography variant='h6' align='center' style={{ color: 'white' }}>
                                {getTypeLabel(t)} ({stationsByType[t] ? stationsByType[t].length : 0})
                            </Typography>
                        </Grid>
                    ))
                }
                <Grid item xs={12} sm={10} lg={7}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            style={{ textAlign: 'center' }}
                        >
                            <Typography align='center'>{i18n.filter} ({selection.filters.length})</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid
                                container
                                direction='row'
                                justifyContent='center'
                                alignItems='flex-start'
                                spacing={1}
                                style={{ overflowY: 'auto' }}
                            >
                                {
                                    Object.keys(filtersByType).map((type, n) => (
                                        <Grid item xs={12} key={n} style={{ backgroundColor: 'white' }}>
                                            {i18n[type]}: {filtersByType[type].map(f => <Chip key={f.code} label={`${f.code} - ${f.name}`} />)}
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
            { !smMatches && (
                <>
                    <Box sx={theme.menu.burger.mobile}>
                        <BurgerMenu color='light'/>
                    </Box>
                    <IconButton size='large' className={classes.arrowMobile} onClick={() => dispatch(push('/selection'))}>
                        <ArrowForwardIcon color='light' fontSize='large' />
                    </IconButton>
                </>
            )}
        </div>
    )
}

export default SelectionDetails
