module.exports = {
    LOGIN_FAIL: 'LOGIN_FAIL',
    ADD_TOKEN: 'ADD_TOKEN',
    REMOVE_TOKEN: 'REMOVE_TOKEN',
    SIEAU_TOKEN: 'sieau-token',
    SIEAU_LOGIN: 'SIEAU_LOGIN',
    MAP_STATIONS_VISIBLE: 'MAP_STATIONS_VISIBLE',
    DISCONNECTED: 'DISCONNECTED',
    TITLE: 'TITLE',
    BACK_PATH: 'BACK_PATH',
    NAVBAR_LINKS: 'NAVBAR_LINKS',
    CREDENTIALS: 'CREDENTIALS',
    ACTIONS: 'ACTIONS',
    RECEIVE_NOTIFICATIONS: 'RECEIVE_NOTIFICATIONS',
    READ_NOTIFICATION: 'READ_NOTIFICATION',
    ADD_NOTIFICATION: 'ADD_NOTIFICATION',
    RECEIVE_THEME_LAYERS: 'RECEIVE_THEME_LAYERS',
    RECEIVE_LAST_DOCUMENTS: 'RECEIVE_LAST_DOCUMENTS',
    SHOW_WELCOME_POPUP: 'SHOW_WELCOME_POPUP',
    UPDATE_GLOBAL_RESEARCH: 'UPDATE_GLOBAL_RESEARCH',
    SET_HELP_LINK: 'SET_HELP_LINK',
    RECEIVE_DATE_VALID_CGU: 'RECEIVE_DATE_VALID_CGU',
    RECEIVE_ALL_CGU: 'RECEIVE_ALL_CGU',
    RECEIVE_CONTRIBUTORS: 'RECEIVE_CONTRIBUTORS',
    RECEIVE_PARTENAIRE_CMS: 'RECEIVE_PARTENAIRE_CMS',
    RECEIVE_CGU_CMS: 'RECEIVE_CGU_CMS',
    RECEIVE_CMS_LOGO: 'RECEIVE_CMS_LOGO',
    RECEIVE_CURRENT_LOCATION: 'RECEIVE_CURRENT_LOCATION',
    ACTION: 'ACTION',
    MENU: 'MENU',
    RECEIVE_WATCHID: 'RECEIVE_WATCHID',
    POPUP: 'POPUP',
    MENU_RESET: 'MENU_RESET',
    FULL_RESET: 'FULL_RESET',
    RECEIVE_APPLICATION_SETTINGS: 'RECEIVE_APPLICATION_SETTINGS',
    RECEIVE_CMS_HOME: 'RECEIVE_CMS_HOME',
    SET_RELOAD: 'SET_RELOAD',
    TOKEN_AQUADB_LOGIN:
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2NTg5NTk5NTYyNjYsImxvZ2luIjoiQXF1YWRiIiwiY29ubmVjdGlvbiI6IjIwMjItMDctMjcgMTI6MTI6MzYiLCJsaWNlbnNlIjpbIlNJRyIsIlNJUCIsIlNJSCIsIlNJRVMiLCJTSVEiLCJTSUFETSIsIlNJRyIsIlNJUCIsIlNJSCIsIlNJRVMiLCJTSVEiLCJTSUFETSJdfQ.nbi_wAr4lmKETlYhmdTbUdS1PNviCCfT9KbpkHsHv4M',
}