import React from 'react'
import { Route } from 'react-router-dom'
import { getPayload } from '../../utils/ActionUtils'
import moment from 'moment'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import AuthenticationThunk from 'authentication/actions/AuthenticationThunk'
import { push } from 'connected-react-router'

const AuthenticatedRoute = (props) => {
    const dispatch = useDispatch()
    const payload = getPayload()
    if (!payload || props.location.pathname === '/') {
        return dispatch(push('/home'))
    }

    const exp = moment(JSON.parse(payload).exp)
    if (moment().isAfter(exp)) {
        dispatch(AuthenticationThunk.logout())
        return dispatch(push('/login'))
    }

    return <Route {...props} />
}

AuthenticatedRoute.propTypes = {
    exact: PropTypes.bool,
    path: PropTypes.string,
    component: PropTypes.func,
    location: PropTypes.func,
}

export default AuthenticatedRoute