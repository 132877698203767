import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, Tab, Tabs, Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { BLUE_AQUADB } from '../utils/GlobalColorsUtils'

const useStyles = makeStyles(() => ({
    hidden: {
        display: 'none',
    },
    onTop: {
        zIndex: '8',
        alignSelf: 'start',
    },
    tab: {
        margin: '5px 0 !important',
        borderRadius: '8px 0 0 8px !important',
        backgroundColor: 'white !important',
        color: `${BLUE_AQUADB} !important`,
        transitionDuration: '0.2s',
        border: `2px ${BLUE_AQUADB} solid !important`,
        borderRight: 'none !important',
        '&:hover': {
            backgroundColor: `${BLUE_AQUADB} !important`,
            color: 'white !important',
        },
    },
    selected: {
        backgroundColor: `${BLUE_AQUADB} !important`,
        color: 'white !important',
    },
}))

const HIDDEN = 'HIDDEN'

const SideBar = ({ tabs, openByDefault, defaultTab, children }) => {
    const classes = useStyles()
    const [tab, setTab] = useState(defaultTab)
    const [isHidden, setHidden] = useState(!openByDefault)

    const handleClick = (clickedTab) => {
        if (clickedTab === tab) {
            setTab(HIDDEN)
            setHidden(true)
        } else {
            setTab(clickedTab)
            setHidden(false)
        }
    }

    const tabsIcon = tabs.map((t) => (
        <Tab key={t.name} value={t.name} style={{ minHeight: '60px' }} label={t.icon} onClick={() => handleClick(t.name)} className={t.name === tab ? `${classes.tab} ${classes.selected}` : classes.tab}/>
    ))

    return (
        <>
            <Grid item xs={3} sm={'auto'} className={classes.onTop}>
                <Tabs
                    orientation='vertical'
                    value={tab}
                    aria-label='vertical tabs'
                >
                    {tabsIcon}
                    <Tab key={HIDDEN} value={HIDDEN} sx={{ minHeight: '0px', width: '0px', padding: '0' }} />
                </Tabs>
            </Grid>
            <Grid item xs={9} sm={6} md={4} lg={3} className={`${classes.onTop} ${isHidden ? classes.hidden : ''}`} sx={{ paddingLeft: '0 !important', backgroundColor: 'white', borderTopLeftRadius: 5, borderBottomLeftRadius: 5, border: `2px solid ${BLUE_AQUADB}`, borderRight: 'none' }}>
                <Box style={{ minHeight: '25vh', maxHeight: '75vh', overflow: 'scroll' }} sx={{ paddingLeft: 2, paddingBottom: 2 }}>
                    {children(tab)}
                </Box>
            </Grid>
        </>
    )
}

SideBar.propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        icon: PropTypes.element,
        content: PropTypes.element,
    })),
    openByDefault: PropTypes.bool,
    defaultTab: PropTypes.string,
    children: PropTypes.func,
}

SideBar.defaultProps = {
    tabs: [],
    openByDefault: false,
    defaultTab: HIDDEN,
}

export default SideBar
