import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import i18n from 'simple-react-i18n'
import AppStore, { history } from './store/AppStore'
import { Route, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import Login from './authentication/Login'
import AuthenticatedRoute from './pages/online/AuthenticatedRoute'
import Home from './home/Home'
import { mainBlue, secondaryBlue } from 'components/styled/Theme'
import Reset from 'authentication/Reset'
import Signup from 'authentication/Signup'
import background from 'assets/pictures/background.jpg'
import SelectionDashboard from 'selection/SelectionDashboard'
import SelectionDetails from 'selection/components/SelectionDetails'
import Account from './account/Account'
import { HOME_BACKGROUND_IMAGE } from './home/constants/HomeConstants'
import Cookies from './cookies/Cookies'
import Toastr from './toastr/components/Toastr'
import CGU from './cgu/CGU'
import Wait from './wait/Wait'
import MapDashboard from './map/MapDashboard'
import fr from 'assets/i18n/properties_fr.json'
import en from 'assets/i18n/properties_en.json'


i18n.addLang(fr, ['fr', 'FR'])
i18n.addLang(en, ['en', 'EN'])
i18n.addLang(fr, 'default')
i18n.init()

const properties = [
    {
        code: 'fr',
        value: fr,
    },
    {
        code: 'en',
        value: en,
    },
]

const theme = createTheme({
    typography: {
        fontFamily: 'IBM Plex Sans',
        button: {
            fontSize: '1rem',
            textTransform: 'none',
        },
    },
    palette: {
        primary: {
            main: secondaryBlue,
        },
        secondary: {
            main: mainBlue,
        },
        action: {
            disabledBackground: 'lightgrey',
            disabled: 'white',
        },
        light: {
            main: '#fff',
            contrastText: '#fff',
            light: '#fff',
            darker: '#fff',
        },
    },
    menu: {
        backgroundTop: {
            backgroundImage: `url(${background})`,
            backgroundSize: 'cover',
            height: '15%',
        },
        container: {
            height: '100%',
        },
        logoAquadb: {
            textAlign: 'center',
            '& img': {
                height: 'auto',
                maxWidth: 380,
                width: '95%',
                cursor: 'pointer',
            },
        },
        logoAquasys: {
            height: '20%',
            '& img': {
                height: 'auto',
                maxWidth: 250,
                width: '80%',
                cursor: 'pointer',
            },
        },
        burger: {
            mobile: {
                position: 'absolute',
                left: 15,
                bottom: 15,
                zIndex: 8,
            },
        },
        backgroundAquadb: {
            backgroundImage: HOME_BACKGROUND_IMAGE,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundAttachment: 'fixed',
            backgroundSize: 'cover',
            overflowX: 'hidden',
            height: '100%',
            width: '100%',
        },
        center: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        },
    },
})

export const handleUpdateLanguage = (code) => {
    const propertie = (properties.find(p => p.code === code) || {})
    i18n.clear()
    i18n.addLang(propertie.value || fr, 'default')
    i18n.init()
}

function onDeviceReady() {
    window.open = window.cordova.InAppBrowser.open
}
document.addEventListener('deviceready', onDeviceReady, false)
const app = createRoot(document.getElementById('app'))

app.render(
    <ThemeProvider theme={theme}>
        <Provider store={AppStore}>
            <Wait/>
            <Cookies/>
            <CGU/>
            <Toastr/>
            <ConnectedRouter history={history}>
                <Switch>
                    <Route exact path='/login' component={(props) => <Login {...props} />} />
                    <Route exact path='/reset' component={ (props) => <Reset { ...props } /> } />
                    <Route exact path='/signup' component={ (props) => <Signup { ...props } /> } />
                    {/* <Route exact path='/map' component={ (props) => <MapDashboardOld { ...props } /> } />*/}
                    <Route exact path='/map' component={ (props) => <MapDashboard { ...props } /> } />
                    <Route exact path='/account' component={(props) => <Account {...props} />} />
                    <Route exact path='/home' component={(props) => <Home {...props} />} />
                    <Route path='/' component={(props) => <AuthenticatedRoute {...props} />} />
                </Switch>
                <Route exact path='/selection/detail' component={ (props) => <SelectionDetails { ...props } /> } />
                <Route exact path='/selection' component={ (props) => <SelectionDashboard { ...props } /> } />
            </ConnectedRouter>
        </Provider>
    </ThemeProvider>
)