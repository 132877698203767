/* eslint-disable quotes */
import { push } from 'connected-react-router'
import i18n from 'simple-react-i18n'
import {
    SIEAU_TOKEN,
    CREDENTIALS,
    TOKEN_AQUADB_LOGIN,
} from '../pages/offline/constants/HomeConstants'
import AppStore from '../store/AppStore'
import LogAction from 'log/actions/LogAction'
import { ToastrAction } from '../toastr/reducers/ToastrReducer'
import { WaitAction } from '../wait/WaitReducer'

const codes = {
    200: (res) => res,
    201: (res) => res,
    403: () => {
        const wantedUrl = window.location.href || ''
        const pathToPush = wantedUrl.substring(wantedUrl.indexOf('/#/') + 3)
        if (pathToPush && pathToPush !== 'login' && pathToPush !== 'home') {
            localStorage.setItem("URL_WANTED", pathToPush)
        }
        AppStore.dispatch({ type: 'RESET_ALL_STORE' })
        AppStore.dispatch(push('/login'))
        localStorage.removeItem(SIEAU_TOKEN)
        throw new Error('Not Authorized')
    },
    404: () => {
        throw new Error('404 Not Found')
    },
    409: () => {
        throw new Error('409 Conflict')
    },
    429: () => {
        AppStore.dispatch(ToastrAction.error(i18n.tooManyLoginRequests))
        throw new Error('429 Too Many Requests')
    },
    500: () => {
        throw new Error('500 error')
    },
}

const checkError = (json, errorCodeManagement = {}) => {
    if (json.error && errorCodeManagement[json.error]) {
        errorCodeManagement[json.error]()
    } else if (json.error) {
        AppStore.dispatch(WaitAction.waitStop())
        throw new Error(json.error)
    }
    return json
}

const resetCredentials = () => {
    localStorage.removeItem(SIEAU_TOKEN)
    localStorage.removeItem(CREDENTIALS)
    AppStore.dispatch(push('/login'))
}

const getLoginPassword = () => {
    const credentials = localStorage.getItem(CREDENTIALS)
    return credentials ? atob(credentials).split(':') : null
}

const getJson = response => response.json()

const checkStatus = (obj, response) => {
    const code = Object.keys(obj).find((element) => `${response.status}` === `${element}`)
    if (!code) {
        throw new Error(`Unhandled Error during fetch${response.status}`)
    }
    return obj[code](response)
}

const checkAuth = (response, overrideStatus = {}) => {
    return checkStatus({
        ...codes,
        ...overrideStatus,
    }, response)
}

const catchError = (err, message = '') => {
    AppStore.dispatch(WaitAction.waitStop())
    AppStore.dispatch(LogAction.logError(`${i18n.fetchError + message} : ${err}`))
    AppStore.dispatch(ToastrAction.error(i18n.fetchError + message))
}

const checkAuthV2 = (response, overrideStatus = {}) => {
    return checkStatus({
        ...codes,
        ...overrideStatus,
    }, response)
}

const getAuthorization = () => ({
    Authorization: `Bearer ${localStorage.getItem(SIEAU_TOKEN)}`,
    Module: 'AQUADB',
})

const getAuthorizationLogin = () => ({
    Authorization: TOKEN_AQUADB_LOGIN,
    Module: 'AQUADB',
})

const removeToken = () => {
    localStorage.removeItem(SIEAU_TOKEN)
}

const getPayload = () => {
    const token = localStorage.getItem(SIEAU_TOKEN)
    if (token) {
        return atob(token.split('.')[1])
    }
    return ''
}

const genericPublicPromise = (url, method='GET', body) => {
    return fetch(url, {
        method,
        body,
    })
        .then(getJson)
        .then(checkError)
}

const genericPrivatePromise = (url, method='GET', body) => {
    return fetch(url, {
        method,
        body,
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError)
}

export {
    checkAuth,
    checkStatus,
    checkError,
    getAuthorization,
    getPayload,
    catchError,
    getLoginPassword,
    resetCredentials,
    getJson,
    getAuthorizationLogin,
    removeToken,
    checkAuthV2,
    genericPublicPromise,
    genericPrivatePromise,
}
