import React, { forwardRef, useEffect, useState } from 'react'
import MuiAlert from '@mui/material/Alert'
import { Snackbar } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { ToastrAction } from '../reducers/ToastrReducer'
import { TOASTR_ERROR, TOASTR_SUCCESS, TOASTR_WARNING } from '../constants/ToastrConstants'

const Toastr = () => {
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)

    const {
        type,
        message,
        duration,
    } = useSelector(store => ({
        type: store.ToastrReducer.type,
        message: store.ToastrReducer.message,
        duration: store.ToastrReducer.duration,
    }))

    useEffect(() => {
        if (message.length) {
            setOpen(true)
        }
    }, [dispatch, type, message])

    const Alert = forwardRef((props, ref) => {
        return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
    })

    const close = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        setOpen(false)
        dispatch(ToastrAction.reset())
    }

    const getSeverity = (status) => {
        switch (status) {
            case TOASTR_SUCCESS: return 'success'
            case TOASTR_WARNING: return 'warning'
            case TOASTR_ERROR: return 'error'
            default: return 'info'
        }
    }

    return (
        <>
            <Snackbar open={open} autoHideDuration={duration} onClose={close}>
                <Alert onClose={close} severity={getSeverity(type)} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </>
    )
}

export default Toastr