import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import { Box, Checkbox, Slider } from '@mui/material'

const SettingsTab = ({ disableCluster, setDisableCluster, clusterDistance, setClusterDistance }) => {
    return (
        <FormControl fullWidth>
            <FormLabel style={{ marginBottom: 10, fontSize: 23, color: 'black' }}>{i18n.settings}</FormLabel>
            <FormControlLabel
                control={
                    <Checkbox style={{ padding: '1px 0', marginLeft: 15 }} sx={{ '& .MuiSvgIcon-root': { fontSize: 23 } }}
                        checked={disableCluster}
                        onChange={setDisableCluster}
                    />
                }
                label={<Box paddingLeft={1}>
                    {i18n.clusterSettings}
                </Box>}
            />
            <FormControlLabel
                disabled={!disableCluster}
                style={{ paddingTop: '10px' }}
                control={
                    <Slider value={clusterDistance} max={100} valueLabelDisplay='auto' valueLabelFormat={s => `${s}%`} onChange={setClusterDistance}/>
                }
                label={<Box paddingLeft={1}>
                    {i18n.clusterDistanceSettings}
                </Box>}
                labelPlacement='top'
            />
        </FormControl>
    )
}

SettingsTab.propTypes = {
    disableCluster: PropTypes.bool,
    setDisableCluster: PropTypes.func,
    clusterDistance: PropTypes.number,
    setClusterDistance: PropTypes.func,
}

export default SettingsTab