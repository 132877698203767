import {
    Box, Grid,
    Modal, useMediaQuery,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { useDispatch, useSelector } from 'react-redux'
import { HomeAction } from '../home/reducers/HomeReducer'
import HomeThunk from './actions/CGUThunk'
import i18n from 'simple-react-i18n'
import { push } from 'connected-react-router'

const useStyles = makeStyles(() => ({
    modal: {
        width: '100%',
        maxHeight: '75%',
        position: 'absolute',
        overflow: 'hidden',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        '&:focus': {
            outline: 'none',
        },
    },
    content: {
        backgroundColor: 'white',
        padding: '15px 10px',
        alignItems: 'center',
        fontSize: '1.1em',
        height: '100%',
    },
    cgu: {
        height: '500px',
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            width: '7px',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: 10,
            backgroundColor: 'black',
        },
    },
    button: {
        border: '#353333 solid 1px',
        padding: 10,
        cursor: 'pointer',
        borderRadius: 4,
        color: 'white',
        fontWeight: 'bold',
        backgroundColor: '#232052',
        '&:hover': {
            transitionDuration: '0.25s',
            backgroundColor: '#353333',
        },
    },
}))

const CGU = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const smMatches = useMediaQuery((th) => th.breakpoints.up('sm'))

    const [isNew, setIsNew] = useState(false)

    const { needShowCGU, cgu, cguValidDate, login } = useSelector(store => ({
        needShowCGU: store.HomeReducer.showCGU,
        cgu: store.HomeReducer.CGU,
        cguValidDate: store.HomeReducer.CGUValidDate,
        login: store.AuthenticationReducer.login,
    }))

    useEffect(() => {
        if (!cgu) {
            dispatch(HomeThunk.fetchCGU())
        }
    }, [dispatch, cgu])

    useEffect(() => {
        if (cgu && login) {
            dispatch(HomeThunk.fetchCGUValidDate(login))
        }
    }, [cgu, dispatch, login])

    useEffect(() => {
        if (cgu && login && cguValidDate !== false) {
            if (cguValidDate === null) {
                setIsNew(true)
            } else {
                const dateCGU = new Date(cgu.updateDate)

                if (cguValidDate < dateCGU.getTime()) {
                    setIsNew(true)
                }
            }

            if (isNew) {
                dispatch(HomeAction.setShowCGU(true))
            }
        }
    }, [dispatch, cgu, login, cguValidDate, isNew])

    const hideDisplay = () => {
        dispatch(HomeAction.setShowCGU(false))
        setIsNew(false)
    }

    return (
        <Modal
            open={needShowCGU}
        >
            <Box className={classes.modal} style={{ maxHeight: '100%' }}>
                <Grid container justifyContent={'center'}>
                    <Grid container item sm={6} spacing={2} className={classes.content}>
                        <Grid item style={{ fontSize: '1.2em', fontWeight: 'bold' }}>
                            {i18n.termsConditionsUse}
                        </Grid>
                        <Grid container item className={classes.cgu} marginTop={2}>
                            {
                                cgu ? (
                                    <div dangerouslySetInnerHTML={{ __html: cgu.comment }}/>
                                ) : (
                                    i18n.loadingInProgress
                                )
                            }
                        </Grid>
                        <Grid container item style={{ paddingTop: 10 }} justifyContent={smMatches ? 'flex-end': 'center'}>
                            <Grid item>
                                <button className={classes.button} onClick={() => {
                                    if (isNew) {
                                        dispatch(HomeThunk.sendDateCgu({ login, eventType: 'CGU', module: 'CGU', version: new Date().getTime().toString() }))
                                        const wantedUrl = localStorage.getItem('URL_WANTED')
                                        if (wantedUrl && wantedUrl !== '') {
                                            localStorage.removeItem('URL_WANTED')
                                            dispatch(push(wantedUrl))
                                        } else {
                                            dispatch(push('/home'))
                                        }
                                        setIsNew(false)
                                    } else {
                                        hideDisplay()
                                    }
                                }}
                                >{isNew ? i18n.agreeTerms : i18n.close}</button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
}

export default CGU