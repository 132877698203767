import { createAsyncThunk } from '@reduxjs/toolkit'
import ApplicationConf from 'conf/ApplicationConf'
import { genericPrivatePromise } from 'utils/ActionUtils'
import i18n from 'simple-react-i18n'
import LogAction from 'log/actions/LogAction'
import { ToastrAction } from '../../toastr/reducers/ToastrReducer'

const SelectionThunk = {
    create: createAsyncThunk(
        'create',
        async(body, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPrivatePromise(ApplicationConf.selection.create(), 'POST', JSON.stringify(body))
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} selection create: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        }
    ),
    update: createAsyncThunk(
        'update',
        async(params, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPrivatePromise(ApplicationConf.selection.update(params.code), 'POST', JSON.stringify(params.body))
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} selection update: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        }
    ),
    get: createAsyncThunk(
        'get',
        async(_, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPrivatePromise(ApplicationConf.selection.get())
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} selection get: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        }
    ),
    export: createAsyncThunk(
        'export',
        async(params, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPrivatePromise(ApplicationConf.selection.export(params.code), 'POST', JSON.stringify(params.body))
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} selection export: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        }
    ),
    delete: createAsyncThunk(
        'delete',
        async(code, { rejectWithValue, dispatch, fulfillWithValue }) => {
            try {
                const response = await genericPrivatePromise(ApplicationConf.selection.delete(code), 'DELETE')
                if (response === 0) {
                    throw new Error(i18n.deleteError)
                }
                return fulfillWithValue(code)
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.deleteError} selection delete: ${err}`))
                dispatch(ToastrAction.error(i18n.deleteError))
                return rejectWithValue(err)
            }
        }
    ),
    favorite: createAsyncThunk(
        'favorite',
        async(code, { rejectWithValue, dispatch, fulfillWithValue }) => {
            try {
                const response = await genericPrivatePromise(ApplicationConf.selection.favorite(code), 'POST')
                if (response === 0) {
                    throw new Error(i18n.updateError)
                }
                return fulfillWithValue(code)
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.updateError} selection favorite: ${err}`))
                dispatch(ToastrAction.error(i18n.updateError))
                return rejectWithValue(err)
            }
        }
    ),
}

export default SelectionThunk
