module.exports = {
    RECEIVE_STATION_EVENTS: 'RECEIVE_STATION_EVENTS',
    RECEIVE_EVENT: 'RECEIVE_EVENT',
    RESET_EVENT: 'RESET_EVENT',
    RESET_STATION_EVENTS: 'RESET_STATION_EVENTS',
    RECEIVE_CMS_CATEGORIES: 'RECEIVE_CMS_CATEGORIES',
    RECEIVE_CMS_ACTUALITIES: 'RECEIVE_CMS_ACTUALITIES',
    RECEIVE_CMS_DOCUMENT: 'RECEIVE_CMS_DOCUMENT',
    RECEIVE_MONITORED_STATIONS_EVENTS: 'RECEIVE_MONITORED_STATIONS_EVENTS',
    UPDATE_CMS_CATEGORIES: 'UPDATE_CMS_CATEGORIES',
    RECEIVE_CMS_EVENT: 'RECEIVE_CMS_EVENT',
    RECEIVE_CMS_EVENTS: 'RECEIVE_CMS_EVENTS',
    RESET_CMS_EVENT: 'RESET_CMS_EVENT',
    RECEIVE_PIEZOMETRY_ALL_EVENTS: 'RECEIVE_PIEZOMETRY_ALL_EVENTS',
    RECEIVE_QUALITOMETRY_ALL_EVENTS: 'RECEIVE_QUALITOMETRY_ALL_EVENTS',
    RECEIVE_HYDROMETRY_STATION_ALL_EVENTS: 'RECEIVE_HYDROMETRY_STATION_ALL_EVENTS',
    RECEIVE_INSTALLATION_ALL_EVENTS: 'RECEIVE_INSTALLATION_ALL_EVENTS',
    RECEIVE_PIEZOMETER_DIAGNOSTICS: 'RECEIVE_PIEZOMETER_DIAGNOSTICS',
    RECEIVE_PIEZOMETER_DIAGNOSTICS_LINK_MATERIALS: 'RECEIVE_PIEZOMETER_DIAGNOSTICS_LINK_MATERIALS',
    RECEIVE_PIEZOMETER_DIAGNOSTICS_LINK_EVENT_TYPES:
        'RECEIVE_PIEZOMETER_DIAGNOSTICS_LINK_EVENT_TYPES',
    RECEIVE_PIEZOMETER_SOLUTIONS: 'RECEIVE_PIEZOMETER_SOLUTIONS',
    RECEIVE_PIEZOMETER_SOLUTION_LINK_PROBLEMS: 'RECEIVE_PIEZOMETER_SOLUTION_LINK_PROBLEMS',
    RECEIVE_PIEZOMETER_ACTIONS: 'RECEIVE_PIEZOMETER_ACTIONS',
    RECEIVE_CMS_CATEGORY: 'RECEIVE_CMS_CATEGORY',
    EVENT_TYPES: [
        { code: 'G', id: 1, label: 'preventive', color: 'BLUE' },
        { code: 'M', id: 2, label: 'curative', color: 'RED' },
        { code: 'P', id: 3, label: 'toPlan', color: 'YELLOW' },
        { code: 'S', id: 4, label: 'toMonitor', color: 'ORANGE' },
        { code: 'C', id: 5, label: 'comment', color: 'DARKGREEN' },
    ],
}
