module.exports = {
    SEARCH_PARAMETERS: [
        {
            name: 'city',
        },
        {
            name: 'department',
        },
        {
            name: 'watershed',
        },
        {
            name: 'station',
        },
        {
            name: 'epci',
        },
    ],
}