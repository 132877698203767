import { FormControl, TextField } from '@mui/material'
import { styled } from '@mui/styles'

export const InputRow = styled(TextField)({
    margin: '5px 0',
    width: '100% !important',
    zIndex: '0',
})

export const FormControlRow = styled(FormControl)({
    margin: '5px 0',
    width: '100%',
})
