import React from 'react'
import { makeStyles } from '@mui/styles'
import { Grid, IconButton } from '@mui/material'
import PropTypes from 'prop-types'
import DtoSelection from '../dto/DtoSelection'
import PlaySelection from './PlaySelection'
import InfoIcon from '@mui/icons-material/Info'
import DownloadIcon from '@mui/icons-material/Download'
import GradeIcon from '@mui/icons-material/Grade'
import StarOutlineIcon from '@mui/icons-material/StarOutline'
import DeleteIcon from '@mui/icons-material/Delete'
import i18n from 'simple-react-i18n'
import { formatDate } from '../../utils/DateUtil'

const useStyles = makeStyles(() => ({
    container: {
        borderRadius: '7px',
        '&:hover': {
            transitionDuration: '0.2s',
            transform: 'scale(1.035)',
        },
    },
    background: {
        height: '100%',
        width: '100%',
        borderTopLeftRadius: '7px',
        borderTopRightRadius: '7px',
    },
    line: {
        backgroundColor: 'white',
        boxShadow: '1px 1px 12px rgba(0,0,0,0.5)',
        borderBottomLeftRadius: '7px',
        borderBottomRightRadius: '7px',
        padding: '10px',
    },
    title: {
        position: 'absolute',
        padding: '15px',
        color: 'white',
        fontSize: '1.2em',
        fontWeight: 'bold',
    },
    favoriteContainer: {
        justifyContent: 'center',
        position: 'absolute',
        backgroundColor: '#F9F9F9',
        right: 0,
        borderBottomLeftRadius: '7px',
    },
    iconContainer: {
        position: 'absolute',
        justifyContent: 'center',
        bottom: 0,
        padding: 10,
    },
    icons: {
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#E0E7E2',
        borderRadius: '10px',
        boxShadow: '1px 1px 12px rgba(0,0,0,0.5)',
    },
}))

const InfoCard = ({ title, value }) => {
    return (
        <Grid container item xs={10} justifyContent={'flex-start'} alignItems={'center'} spacing={2}>
            <Grid item xs={5}>
                {title}
            </Grid>
            <Grid item xs={5} style={{ textAlign: 'right' }}>
                {value}
            </Grid>
        </Grid>
    )
}

const SelectionCard = ({ selection, onClick, setSelectExport, favorite, setSelectDelete }) => {
    const classes = useStyles()

    return (
        <Grid container item direction={'column'} xs={11} sm={6} md={4} lg={3} className={classes.container}>
            <Grid container style={{ position: 'relative', height: '150px' }}>
                <img src={'https://www.aquasys.fr/wp-content/uploads/2018/01/ressource-en-eau.jpg'} className={classes.background}/>
                <Grid container item position={'absolute'}>
                    <Grid item xs={10} className={classes.title}>
                        {selection.name}
                    </Grid>
                    <Grid item xs={2} className={classes.favoriteContainer}>
                        <IconButton onClick={() => favorite(selection)}>
                            { selection.isFavorite ? <GradeIcon htmlColor='#ffcc00'/> : <StarOutlineIcon/>}
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid container item direction={'row'} className={classes.iconContainer}>
                    <Grid container item xs={9} md={11} className={classes.icons}>
                        <Grid item>
                            <IconButton onClick={onClick}>
                                <InfoIcon/>
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <PlaySelection selection={selection}/>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={() => {
                                setSelectExport(selection)
                            }}
                            ><DownloadIcon color='success'/>
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={() => setSelectDelete(selection)}>
                                <DeleteIcon color='error'/>
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container item direction={'row'} justifyContent={'center'} className={classes.line}>
                {
                    <InfoCard title={i18n.creation} value={formatDate(selection.creationTimestamp)}/>
                }
                {
                    <InfoCard title={i18n.update} value={formatDate(selection.updateTimestamp)}/>
                }
                {
                    <InfoCard title={i18n.execution} value={formatDate(selection.exportTimestamp)}/>
                }
                {
                    <InfoCard title={i18n.themes} value={selection.themes.length}/>
                }
                {
                    <InfoCard title={i18n.zones} value={selection.watersheds.length}/>
                }
                {
                    <InfoCard title={i18n.stations} value={selection.stations.length}/>
                }
            </Grid>
        </Grid>
    )
}

InfoCard.propTypes = {
    title: PropTypes.string,
    value: PropTypes.any,
}

SelectionCard.propTypes = {
    selection: PropTypes.instanceOf(DtoSelection),
    onClick: PropTypes.func,
    setSelectExport: PropTypes.func,
    favorite: PropTypes.func,
    setSelectDelete: PropTypes.func,
}

export default SelectionCard