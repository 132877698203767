import React, { useEffect, useState } from 'react'
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, Modal } from '@mui/material'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import DtoSelection from '../dto/DtoSelection'
import i18n from 'simple-react-i18n'
import { useDispatch, useSelector } from 'react-redux'
import { ToastrAction } from '../../toastr/reducers/ToastrReducer'
import SelectionThunk from '../actions/SelectionThunk'
import { URL } from '../../home/constants/HomeConstants'

const useStyles = makeStyles(() => ({
    grid: {
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '7px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'left',
        '&:focus': {
            outline: 'none',
        },
    },
    title: {
        padding: '15px',
        fontSize: '1.7em',
    },
    hr: {
        backgroundColor: '#353333',
        height: '3px',
        border: 'none',
        borderRadius: '3px',
    },
    data: {
        padding: '15px',
    },
    buttons: {
        padding: '15px',
        marginBottom: '10px',
    },
    button: {
        border: '1px solid #353333',
        padding: '10px',
        cursor: 'pointer',
        borderRadius: '4px',
        color: 'white',
        fontWeight: 'bold',
    },
    cancel: {
        backgroundColor: 'white',
        color: '#232052',
        '&:hover': {
            color: 'white',
            transitionDuration: '0.25s',
            backgroundColor: '#232052',
        },
    },
    valid: {
        backgroundColor: '#232052',
        '&:hover': {
            color: '#B8B1B1',
            transitionDuration: '0.25s',
            backgroundColor: '#353333',
        },
    },
    disabled: {
        color: '#B8B1B1',
        backgroundColor: '#353333',
    },
}))

const ExportSelection = ({ selection, setSelection }) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [descriptions, setDescriptions] = useState(true)
    const [datas, setDatas] = useState(true)
    const [download, setDownload] = useState()
    const isDisabled = !descriptions && !datas

    const { login } = useSelector(store => ({
        login: store.AuthenticationReducer.login,
    }))

    useEffect(() => {
        const downloadPath = selection?.download

        if (downloadPath) {
            const downloadPathSplited = downloadPath.split('/')
            const directory = downloadPathSplited[downloadPathSplited.length - 2]
            const file = downloadPathSplited[downloadPathSplited.length - 1]

            if (directory && file) {
                setDownload(`${directory}/${file}`)
            }
        } else {
            setDownload(null)
        }
    }, [selection])

    const onKeyDown = (e) => {
        if (e.key === 'Escape') {
            e.defaultMuiPrevented = true
            setSelection(null)
        }
    }

    const exportSelection = () => {
        const body = {
            id: selection.code,
            user: login,
            descriptions,
            datas,
            url: (window.location.href || document.URL).split('#')[0],
        }

        dispatch(SelectionThunk.export({ code: selection.code, body }))
        dispatch(ToastrAction.success({ message: i18n.exportInProgress, duration: 10000 }))

        setSelection(null)
    }

    return (
        <Modal
            open={selection !== null}
        >
            <Grid container item className={classes.grid} md={5} onKeyDown={onKeyDown}>
                <Grid container item className={classes.title}>
                    <Grid item>
                        {i18n.exportSelection} {selection ? selection.name : ''}
                    </Grid>
                </Grid>

                <Grid item md={12} className={classes.hr} />

                <Grid container item>
                    <Grid container item className={classes.data}>
                        {i18n.selectInfosToExport}
                    </Grid>
                    {
                        download && (
                            <Grid container item className={classes.data}>
                                <Grid item>
                                    {i18n.downloadExport}
                                </Grid>
                                <Grid item paddingTop={'5px'}>
                                    <button disabled={isDisabled} className={`${classes.button} ${isDisabled ? classes.disabled : classes.valid}`}
                                        onClick={() => window.open(`${URL}contents/EXPORT/tmp/${download}`, '_blank')}
                                    >{i18n.download}
                                    </button>
                                </Grid>
                            </Grid>
                        )
                    }
                </Grid>

                <Grid container item>
                    <div className={classes.buttons}>
                        <FormControl required error={isDisabled}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox onChange={() => setDescriptions(!descriptions)} checked={descriptions} />} label={i18n.siteDescription} />
                                <FormControlLabel control={<Checkbox onChange={() => setDatas(!datas)} checked={datas} />} label={i18n.siteData} />
                            </FormGroup>
                            <FormHelperText>{i18n.pleaseCheckOneOption}</FormHelperText>
                        </FormControl>
                    </div>
                </Grid>

                <Grid container item justifyContent={'flex-end'} alignItems={'center'} spacing={1}>
                    <Grid item>
                        <button className={`${classes.button} ${classes.cancel}`} onClick={() => setSelection(null)}>{i18n.cancel}</button>
                    </Grid>
                    <Grid item>
                        <button disabled={isDisabled} className={`${classes.button} ${isDisabled ? classes.disabled : classes.valid}`} onClick={exportSelection}>{i18n.export}</button>
                    </Grid>
                </Grid>
            </Grid>
        </Modal>
    )
}

ExportSelection.propTypes = {
    selection: PropTypes.instanceOf(DtoSelection),
    setSelection: PropTypes.func,
}

export default ExportSelection