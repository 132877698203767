import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    Pagination,
    TextField,
} from '@mui/material'
import { useSelector } from 'react-redux'
import { countBy } from 'lodash'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import { makeStyles } from '@mui/styles'
import { BLUE_SELECTION, GRAY } from '../../utils/GlobalColorsUtils'
import Style from 'ol/style/Style'
import Icon from 'ol/style/Icon'
import AnimatedCluster from 'ol-ext/layer/AnimatedCluster'
import VectorLayer from 'ol/layer/Vector'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'

const useStyles = makeStyles(() => ({
    stationCategory: {
        backgroundColor: GRAY,
        borderRadius: 2,
        cursor: 'pointer',
    },
    stationCategoryDetailed: {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
    },
    station: {
        transitionDuration: '0.2s',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: BLUE_SELECTION,
        },
    },
    stationDetails: {
        transitionDuration: '0.3s',
        border: `3px solid ${GRAY}`,
    },
    entitiesSearch: {
        transitionDuration: '0.3s',
    },
    entitiesSearchHide: {
        opacity: '0',
        maxWidth: '0',
        cursor: 'none',
    },
    entitiesSearchShow: {
        opacity: '1',
        maxWidth: '100%',
        cursor: 'initial',
    },
}))

const LayersTab = ({ layers, onClick, selectedLayers, disableCluster }) => {
    const classes = useStyles()

    const {
        citiesResult,
        departmentsResult,
        watershedsResult,
        stationsResult,
    } = useSelector(store => ({
        citiesResult: store.MapReducer.citiesResult,
        departmentsResult: store.MapReducer.departmentsResult,
        watershedsResult: store.MapReducer.watershedsResult,
        stationsResult: store.MapReducer.stationsResult,
    }))

    const [layersParams, setLayersParams] = useState({})

    useEffect(() => {
        layers.map(l => {
            layersParams[l.code] = {
                counter: 0,
                isDetailed: false,
                page: 1,
                searchMode: false,
                searchInput: '',
            }
        })
    }, [layers])

    const changeLayersParams = (type, param, value) => {
        setLayersParams(prev => ({
            ...prev,
            [type]: {
                ...prev[type],
                [param]: value,
            },
        }))
    }

    const [hiddenLayers, setHiddenLayers] = useState([])

    const handleSearchInputChange = (e, type) => {
        changeLayersParams(type, 'page', 1)
        changeLayersParams(type, 'searchInput', e?.target?.value || '')
    }

    const layersIsDetailed = (type) => {
        if (layersParams[type]) {
            return layersParams[type].isDetailed
        }

        return false
    }

    const detailLayers = (type) => {
        changeLayersParams(type, 'isDetailed', !layersParams[type].isDetailed)
    }

    const layerIsShowed = (layer) => {
        return !hiddenLayers.find(h => h.type === layer.get('type') && h.code === layer.get('code'))
    }

    const getStationIcon = (type) => {
        return layers.find(l => l.code === type).icon
    }

    const overLayer = (feature, reset, group) => {
        if (disableCluster) {
            if (!reset) {
                feature.setStyle(new Style({
                    image: new Icon({
                        scale: 1.0,
                        opacity: layerIsShowed(feature) ? 1 : 0,
                        src: getStationIcon(feature.get('type')),
                    }),
                }))
            } else if (layerIsShowed(feature)) {
                feature.setStyle(null)
            }
        } else if (!reset) {
            if (!group) {
                feature.setStyle(new Style({
                    image: new Icon({
                        scale: 1.0,
                        opacity: layerIsShowed(feature) ? 1 : 0,
                        src: getStationIcon(feature.get('features')[0].get('type')),
                    }),
                }))
            } else {
                const foundFeature = group.getSource().getFeatures().find(f => {
                    return f.get('features').some(subFeature => subFeature.get('id') === feature.get('id'))
                })

                foundFeature.setStyle(new Style({
                    image: new Icon({
                        scale: 1.0,
                        opacity: layerIsShowed(feature) ? 1 : 0,
                        src: getStationIcon(feature.get('type')),
                    }),
                }))
            }
        } else if (!group) {
            if (layerIsShowed(feature)) {
                feature.setStyle(null)
            }
        } else {
            const foundFeature = group.getSource().getFeatures().find(f => {
                return f.get('features').some(subFeature => subFeature.get('id') === feature.get('id'))
            })

            if (layerIsShowed(foundFeature)) {
                foundFeature.setStyle(null)
            }
        }
    }

    const overLayers = (type, reset) => {
        if (disableCluster) {
            layers.find(l => l.code === type).layer.getSource().getFeatures().map(f => {
                overLayer(f, reset)
            })
        } else {
            layers.find(l => l.code === type).layer.getSource().getFeatures().flat(1).map(f => {
                overLayer(f, reset)
            })
        }
    }

    const hideLayer = (feature) => {
        setHiddenLayers([...hiddenLayers, { type: feature.get('type'), code: feature.get('code') }])

        if (disableCluster) {
            feature.setStyle(new Style({
                image: new Icon({
                    scale: 1.0,
                    opacity: 0,
                    src: getStationIcon(feature.get('type')),
                }),
            }))
        }
    }

    const showLayer = (feature) => {
        setHiddenLayers(hiddenLayers.filter(l => l.code !== feature.get('code')))

        if (disableCluster) {
            feature.setStyle(null)
        }
    }

    const clickFeature = (feature) => {
        if (disableCluster) {
            if (layerIsShowed(feature)) {
                hideLayer(feature)
            } else {
                showLayer(feature)
            }
        }
    }

    useEffect(() => {
        setHiddenLayers([])

        layers.map(l => {
            changeLayersParams(l.code, 'counter', 0)
        })

        const citiesResultStations = citiesResult.flatMap(c => c.watershedStation.flatMap(ws => ws.stations))
        const departmentsResultStations = departmentsResult.flatMap(d => d.watershedStation.flatMap(ws => ws.stations))
        const watershedsResultStations = watershedsResult.flatMap(w => w.stations)
        const stationsResultStations = stationsResult.flatMap(w => w.stations)

        const layersCounter = [
            countBy(
                citiesResultStations, s => s.type),
            countBy(
                departmentsResultStations, s => s.type),
            countBy(
                watershedsResultStations, s => s.type),
            countBy(
                stationsResultStations, s => s.type),
        ]

        layers.map(l => {
            changeLayersParams(l.code, 'counter', layersCounter.reduce((previous, current) => current[l.code] ? previous + current[l.code] : previous, 0))
        })
    }, [citiesResult, departmentsResult, watershedsResult, stationsResult])

    useEffect(() => {
        setHiddenLayers([])
    }, [disableCluster])

    const getFeatures = (layer) => {
        if (disableCluster) {
            const features = layer.getSource().getFeatures()

            if (!layersParams[layer.get('theme')]?.searchMode) {
                return features
            }

            return features.filter(l2 => l2.get('name').toLowerCase().includes(layersParams[layer.get('theme')].searchInput.toLowerCase()))
        }

        const features = layer.getSource().getFeatures().flat(1).map(f => f.get('features')).flat(1)

        if (!layersParams[layer.get('theme')]?.searchMode) {
            return features
        }

        return features.filter(l2 => l2.get('name').toLowerCase().includes(layersParams[layer.get('theme')].searchInput.toLowerCase()))
    }

    return (
        <FormControl>
            <FormGroup>
                <Grid container item rowSpacing={1}>
                    {
                        layers.map((l, i) => (
                            <Grid key={i} container item>
                                <Grid container item padding={1} alignItems={'center'} justifyContent={'space-between'} className={layersIsDetailed(l.code) ? `${classes.stationCategory} ${classes.stationCategoryDetailed}` : `${classes.stationCategory}`} key={i} onClick={() => detailLayers(l.code)}>
                                    <Grid container item xs={'auto'} alignItems={'center'}>
                                        <img width={28} height={'auto'} style={{ marginRight: 7 }} src={getStationIcon(l.code)} alt={`${i18n.station} ${l.label}`}/>
                                        <Grid style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                            {`${l.label} (${selectedLayers.includes(l.code) && l.layer ? getFeatures(l.layer).filter(s => layerIsShowed(s)).length : 0}/${layersParams[l.code] ? layersParams[l.code].counter : 0})`}
                                        </Grid>
                                    </Grid>
                                    <IconButton size='small'>
                                        {
                                            layersIsDetailed(l.code) ? (
                                                <ArrowDropUpIcon style={{
                                                    color: 'black',
                                                }}
                                                />
                                            ) : (
                                                <ArrowDropDownIcon style={{
                                                    color: 'black',
                                                }}
                                                />
                                            )
                                        }
                                    </IconButton>
                                </Grid>
                                <Grid container item padding={1} display={layersIsDetailed(l.code) ? 'flex' : 'none'} className={classes.stationDetails}>
                                    {
                                        layersParams[l.code]?.counter && l.layer ? (
                                            <>
                                                <Grid container item>
                                                    <Grid container item alignItems={'center'} paddingBottom={'5px'}>
                                                        <Grid item>
                                                            <FormControlLabel key={i}
                                                                onMouseOver={() => overLayers(l.code)}
                                                                onMouseLeave={() => overLayers(l.code, true)}
                                                                control={
                                                                    <Checkbox checked={selectedLayers.includes(l.code)} onChange={(e, newValue) => {
                                                                        if (disableCluster) {
                                                                            if (newValue) {
                                                                                const features = getFeatures(l.layer)
                                                                                setHiddenLayers(hiddenLayers.filter(h => h.type !== l.code))

                                                                                features.map(feature => {
                                                                                    feature.setStyle(null)
                                                                                })
                                                                            } else {
                                                                                const features = getFeatures(l.layer)
                                                                                const stationLayers = features.map(l2 => ({ type: l2.get('type'), code: l2.get('code') }))
                                                                                setHiddenLayers([...hiddenLayers, ...stationLayers])

                                                                                features.map(feature => {
                                                                                    feature.setStyle(new Style({
                                                                                        image: new Icon({
                                                                                            scale: 1.0,
                                                                                            opacity: 0,
                                                                                            src: getStationIcon(feature.get('type')),
                                                                                        }),
                                                                                    }))
                                                                                })
                                                                            }

                                                                            onClick(e, newValue, l)
                                                                        }
                                                                    }}
                                                                    />}
                                                                label={<Box fontWeight={'bold'}>
                                                                    {i18n.allEntities}
                                                                </Box>}
                                                            />
                                                        </Grid>
                                                        <Grid container item xs alignItems={'center'}>
                                                            <Grid container item xs>
                                                                <Grid item width={'100%'} className={`${classes.entitiesSearch} ${!layersParams[l.code].searchMode ? classes.entitiesSearchHide : classes.entitiesSearchShow}`} >
                                                                    <TextField
                                                                        label={i18n.findEntity}
                                                                        variant='outlined'
                                                                        disabled={!layersParams[l.code].searchMode}
                                                                        size={'small'}
                                                                        value={layersParams[l.code].searchInput}
                                                                        onChange={(e) => handleSearchInputChange(e, l.code)}
                                                                        fullWidth
                                                                        InputProps={{
                                                                            endAdornment: (
                                                                                <>
                                                                                    {
                                                                                        layersParams[l.code]?.searchInput && (
                                                                                            <IconButton size='small' onClick={() => handleSearchInputChange(false, l.code)}>
                                                                                                <ClearIcon fontSize='small' style={{
                                                                                                    color: 'black',
                                                                                                }} onClick={() => handleSearchInputChange(false, l.code)}
                                                                                                />
                                                                                            </IconButton>
                                                                                        )
                                                                                    }
                                                                                </>
                                                                            ),
                                                                        }}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container item xs={'auto'}>
                                                                <IconButton size='small' onClick={() => changeLayersParams(l.code, 'searchMode', !layersParams[l.code].searchMode)}>
                                                                    <SearchIcon
                                                                        style={{
                                                                            color: 'black',
                                                                        }}
                                                                    />
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container item width={'100%'} height={'1px'} backgroundColor={'black'}/>
                                                </Grid>
                                                <Grid container item paddingTop={1} style={{ maxHeight: '300px', overflowY: 'scroll' }}>
                                                    {
                                                        getFeatures(l.layer)
                                                            .filter((l2, i2) => i2 >= (layersParams[l.code].page - 1) * 15 && i2 <= layersParams[l.code].page * 15 - 1)
                                                            .map((l2, i2) => (
                                                                <Grid key={i2} container className={classes.station} onClick={() => clickFeature(l2)}
                                                                    onMouseOver={() => overLayer(l2, false, l.layer)}
                                                                    onMouseLeave={() => overLayer(l2, true, l.layer)}
                                                                >
                                                                    <FormControlLabel key={i2}
                                                                        control={
                                                                            <Checkbox style={{ padding: '1px 0', marginLeft: 15 }} sx={{ '& .MuiSvgIcon-root': { fontSize: 23 } }}
                                                                                checked={layerIsShowed(l2)}
                                                                            />
                                                                        }
                                                                        label={<Box fontSize={13} paddingLeft={1}>
                                                                            {l2.get('name')}
                                                                        </Box>}
                                                                    />
                                                                </Grid>
                                                            ))
                                                    }
                                                </Grid>
                                                {
                                                    getFeatures(l.layer).length > 15 && (
                                                        <Grid container item justifyContent={'center'} paddingTop={'10px'}>
                                                            <Pagination count={Math.trunc(getFeatures(l.layer).length % 15 === 0 ? getFeatures(l.layer).length / 15 : getFeatures(l.layer).length / 15 + 1)}
                                                                onChange={(_, page) => changeLayersParams(l.code, 'page', page)} variant='outlined' shape='rounded' size={'small'} showFirstButton showLastButton siblingCount={0}
                                                            />
                                                        </Grid>
                                                    )
                                                }
                                            </>
                                        ) : i18n.noEntity
                                    }
                                </Grid>
                            </Grid>
                        ))
                    }
                </Grid>
            </FormGroup>
        </FormControl>
    )
}

LayersTab.propTypes = {
    onClick: PropTypes.func,
    layers: PropTypes.arrayOf(PropTypes.shape({
        layer: PropTypes.oneOfType([
            PropTypes.instanceOf(AnimatedCluster),
            PropTypes.instanceOf(VectorLayer),
        ]),
        code: PropTypes.string,
        label: PropTypes.string,
        isSelected: PropTypes.bool,
    })),
    selectedLayers: PropTypes.arrayOf(PropTypes.string),
    disableCluster: PropTypes.bool,
}

export default LayersTab