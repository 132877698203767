import { createAsyncThunk } from '@reduxjs/toolkit'
import ApplicationConf from 'conf/ApplicationConf'
import { genericPrivatePromise, genericPublicPromise } from 'utils/ActionUtils'
import i18n from 'simple-react-i18n'
import LogAction from 'log/actions/LogAction'
import { ToastrAction } from '../../toastr/reducers/ToastrReducer'

const ReferentialThunk = {
    fetchCities: createAsyncThunk(
        'fetchCities',
        async(_, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPrivatePromise(ApplicationConf.referencial.cities())
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.cities}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        }
    ),
    fetchDepartments: createAsyncThunk(
        'fetchDepartments',
        async(_, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPublicPromise(ApplicationConf.referencial.departments())
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.departments}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        }
    ),
    fetchWatersheds: createAsyncThunk(
        'fetchWatersheds',
        async(_, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPublicPromise(ApplicationConf.referencial.watersheds())
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.watersheds}: ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
                return rejectWithValue(err)
            }
        }
    ),
}

export default ReferentialThunk
