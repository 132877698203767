import React, { useState } from 'react'
import i18n from 'simple-react-i18n'
import { push } from 'connected-react-router'
import { Grid, IconButton, Modal, useMediaQuery } from '@mui/material'
import { makeStyles, useTheme } from '@mui/styles'
import { useDispatch, useSelector } from 'react-redux'
import logoAquaDB from '../assets/pictures/logo_aquadb.png'
import MenuIcon from '@mui/icons-material/Menu'
import HomeIcon from '@mui/icons-material/Home'
import LogoutIcon from '@mui/icons-material/Logout'
import MapIcon from '@mui/icons-material/Map'
import ListIcon from '@mui/icons-material/List'
import PersonIcon from '@mui/icons-material/Person'
import LoginIcon from '@mui/icons-material/Login'
import AuthenticationThunk from 'authentication/actions/AuthenticationThunk'
import PropTypes from 'prop-types'
import InfoIcon from '@mui/icons-material/Info'
import CookieIcon from '@mui/icons-material/Cookie'
import { HomeAction } from '../home/reducers/HomeReducer'
import { BLUE_AQUADB } from '../utils/GlobalColorsUtils'
import packageJson from '../../package.json'

const useStyles = makeStyles(() => ({
    modal: {
        backgroundColor: 'white',
    },
    item: {
        cursor: 'pointer',
        padding: 10,
        marginTop: '0 !important',
        '&:hover': {
            backgroundColor: 'lightgray',
        },
    },
    itemLabel: {
        paddingLeft: 8,
    },
    border: {
        borderTop: '1px solid lightgray',
    },
    mapItem: {
        borderRadius: '0 8px 8px 0',
        padding: '2px 15px',
        cursor: 'pointer',
        backgroundColor: 'white',
        color: BLUE_AQUADB,
        transitionDuration: '0.2s',
        border: `2px ${BLUE_AQUADB} solid`,
        borderLeft: 'none !important',
        '&:hover': {
            backgroundColor: BLUE_AQUADB,
            color: 'white',
        },
    },
}))

const BurgerMenu = ({ color, fromMap }) => {
    const classes = useStyles()
    const theme = useTheme()
    const dispatch = useDispatch()
    const lgMatches = useMediaQuery((t) => t.breakpoints.up('lg'))
    const mdMatches = useMediaQuery((t) => t.breakpoints.up('md'))
    const smMatches = useMediaQuery((t) => t.breakpoints.up('sm'))
    const xsMatches = useMediaQuery((t) => t.breakpoints.up('xs'))

    const { login } = useSelector(store => ({
        login: store.AuthenticationReducer.login,
    }))

    const [isOpen, setOpen] = useState(false)

    const getModalWidth = () => {
        if (lgMatches) {
            return 15
        } else if (mdMatches) {
            return 25
        } else if (smMatches) {
            return 35
        } else if (xsMatches) {
            return 65
        }
        return 50
    }

    return (
        <>
            {!isOpen && (
                !fromMap ? (
                    <IconButton size='large' color={color} onClick={() => setOpen(!isOpen)}>
                        <MenuIcon fontSize='large'/>
                    </IconButton>
                ) : (
                    <Grid item className={classes.mapItem} onClick={() => setOpen(!isOpen)}>
                        <IconButton color={color}>
                            <MenuIcon fontSize='large'/>
                        </IconButton>
                    </Grid>
                )
            )}
            {isOpen && (
                <Modal
                    open={isOpen}
                    onClose={() => setOpen(false)}
                >
                    <Grid
                        container
                        direction='column'
                        justifyContent='flex-start'
                        alignItems='flex-start'
                        className={classes.modal}
                        style={{ width: `${getModalWidth()}%` }}
                        sx={theme.menu.container}
                    >
                        <Grid item sx={theme.menu.logoAquadb} style={{ padding: 5 }} >
                            <img src={logoAquaDB} onClick={() => dispatch(push('/home'))}/>
                        </Grid>
                        { login && (
                            <Grid item container direction='row' alignItems='center' justifyContent='center' style={{ marginTop: 0 }}>
                                <h4>{login}</h4>
                            </Grid>
                        )}
                        <Grid container className={classes.border}/>
                        <Grid item container direction='row' alignItems='center' className={classes.item} onClick={() => dispatch(push('/home'))}>
                            <Grid item><HomeIcon/></Grid>
                            <Grid item className={classes.itemLabel}>{i18n.home}</Grid>
                        </Grid>
                        <Grid item container direction='row' alignItems='center' className={classes.item} onClick={() => dispatch(push('/map'))}>
                            <Grid item><MapIcon/></Grid>
                            <Grid item className={classes.itemLabel}>{i18n.map}</Grid>
                        </Grid>
                        { login && (
                            <Grid item container direction='row' alignItems='center' className={classes.item} onClick={() => dispatch(push('/selection'))}>
                                <Grid item><ListIcon/></Grid>
                                <Grid item className={classes.itemLabel}>{i18n.mySearches}</Grid>
                            </Grid>
                        )}
                        <Grid container className={classes.border}/>
                        { login && (
                            <>
                                <Grid item container direction='row' alignItems='center' className={classes.item} onClick={() => dispatch(push('/account'))}>
                                    <Grid item><PersonIcon/></Grid>
                                    <Grid item className={classes.itemLabel}>{i18n.myAccount}</Grid>
                                </Grid>
                                <Grid container className={classes.border}/>
                            </>
                        )}
                        <Grid item container direction='row' alignItems='center' className={classes.item} onClick={() => dispatch(HomeAction.setShowCGU(true))}>
                            <Grid item><InfoIcon/></Grid>
                            <Grid item className={classes.itemLabel}>{i18n.termsOfUse}</Grid>
                        </Grid>
                        <Grid item container direction='row' alignItems='center' className={classes.item} onClick={() => dispatch(HomeAction.setShowCGU(true))}>
                            <Grid item><InfoIcon/></Grid>
                            <Grid item className={classes.itemLabel}>{i18n.cgu}</Grid>
                        </Grid>
                        <Grid item container direction='row' alignItems='center' className={classes.item} onClick={() => dispatch(HomeAction.setShowCookies(true))}>
                            <Grid item><CookieIcon/></Grid>
                            <Grid item className={classes.itemLabel}>{i18n.cookies}</Grid>
                        </Grid>
                        <Grid container className={classes.border}/>
                        { login && (
                            <Grid item container direction='row' alignItems='center' className={classes.item} onClick={() => dispatch(AuthenticationThunk.logout())}>
                                <Grid item><LogoutIcon/></Grid>
                                <Grid item className={classes.itemLabel}>{i18n.logout}</Grid>
                            </Grid>
                        )}
                        { !login && (
                            <Grid item container direction='row' alignItems='center' className={classes.item} onClick={() => dispatch(push('/login'))}>
                                <Grid item><LoginIcon/></Grid>
                                <Grid item className={classes.itemLabel}>{i18n.log_in}</Grid>
                            </Grid>
                        )}
                        <Grid item style={{ marginTop: 0 }} sx={theme.menu.burger.mobile}>
                            <IconButton size='large' onClick={() => setOpen(!isOpen)}>
                                <MenuIcon fontSize='large'/>
                            </IconButton>
                        </Grid>
                        <Grid item position={'absolute'} fontSize={'12px'} style={{ bottom: '5px', left: '10px' }}>
                            {packageJson.version}
                        </Grid>
                    </Grid>
                </Modal>
            )
            }
        </>
    )
}

BurgerMenu.propTypes = {
    color: PropTypes.string,
    fromMap: PropTypes.bool,
}

BurgerMenu.defaultProps = {
    color: 'inherit',
}

export default BurgerMenu
