/* eslint-disable no-console */
import fetch from 'isomorphic-fetch'
import i18n from 'simple-react-i18n'
import { createAsyncThunk } from '@reduxjs/toolkit'
import ApplicationConf from '../../conf/ApplicationConf'
import { checkAuth, checkError, getAuthorization, getJson } from '../../utils/ActionUtils'
import { ToastrAction } from '../../toastr/reducers/ToastrReducer'
import { HomeAction } from '../../home/reducers/HomeReducer'

const AccountThunk = {
    fetchUserData: createAsyncThunk(
        'fetchUserData',
        async(login, { rejectWithValue }) => {
            try {
                return await fetch(ApplicationConf.user.get(login), {
                    method: 'GET',
                    headers: getAuthorization(),
                }).then(checkAuth)
                    .then(getJson)
            } catch (err) {
                return rejectWithValue(err)
            }
        }
    ),

    updateUser(user, authorizedFetch = true) {
        return (dispatch) => {
            return fetch(ApplicationConf.user.put(), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(user),
            })
                .then(checkAuth)
                .then(checkError)
                .then(() => {
                    if (authorizedFetch) {
                        dispatch(HomeAction.fetchUsers())
                    }
                    ToastrAction.success(i18n.updateUserSucceed)
                })
        }
    },

    addUser(user, email) {
        return () => {
            return fetch(ApplicationConf.user.put(), {
                method: 'POST',
                body: JSON.stringify({ login: user.login, name: user.login, email }),
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(json => checkError(json, {
                    200: () => {
                        ToastrAction.success(i18n.addUserSuccessMessage)
                        return true
                    },
                    409: () => {
                        ToastrAction.warning(i18n.identifierAlreadyTaken)
                        return false
                    },
                }))
                .then(response => {
                    return response
                })
                .catch((err) => {
                    console.error(`${i18n.addUserErrorMessage} : ${err}`)
                    ToastrAction.error(i18n.addUserErrorMessage)
                })
        }
    },

    changePassword(password) {
        return () => {
            return fetch(ApplicationConf.user.changePassword(), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(password),
            })
                .then(checkAuth)
                .then(checkError)
                .then((json) => {
                    if (json.status === 200) {
                        ToastrAction.success(i18n.elementUpdateSuccess, true)
                    } else {
                        ToastrAction.error(i18n.updateError + i18n.password, true)
                    }
                })
                .catch((err) => {
                    console.error(`${i18n.updateError + i18n.password} : ${err}`)
                })
        }
    },

    updatePassword(login, password) {
        return () => {
            return fetch(ApplicationConf.user.updatePassword(login), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(password),
            })
                .then(checkAuth)
                .then(checkError)
                .then((json) => {
                    if (json.status === 200) {
                        ToastrAction.success(i18n.elementUpdateSuccess, true)
                    } else {
                        ToastrAction.error(i18n.updateError + i18n.password, true)
                    }
                })
                .catch((err) => {
                    console.error(`${i18n.updateError + i18n.password} : ${err}`)
                })
        }
    },

    updateProfilePicture(profilePicture, login) {
        return (dispatch) => {
            return fetch(ApplicationConf.user.updateProfilePicture(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(profilePicture),
            })
                .then(checkAuth)
                .then(checkError)
                .then((json) => {
                    if (json.status === 200) {
                        dispatch(AccountThunk.fetchProfilePicture(login))
                        dispatch(ToastrAction.success(i18n.updateUserSucceed))
                    }
                })
                .catch((err) => {
                    console.error(`${i18n.updateError + i18n.password} : ${err}`)
                })
        }
    },

    fetchProfilePicture: createAsyncThunk(
        'fetchProfilePicture',
        async(login, { rejectWithValue }) => {
            try {
                return await fetch(ApplicationConf.user.profilePicture(login), {
                    method: 'GET',
                    headers: getAuthorization(),
                }).then(checkAuth)
                    .then(getJson)
            } catch (err) {
                return rejectWithValue(err)
            }
        }
    ),


    fetchCity: createAsyncThunk(
        'fetchCity',
        async(code, { rejectWithValue }) => {
            try {
                return await fetch(ApplicationConf.referencial.city(code), {
                    method: 'GET',
                    headers: getAuthorization(),
                }).then(checkAuth)
                    .then(getJson)
            } catch (err) {
                return rejectWithValue(err)
            }
        }
    ),
}

export default AccountThunk