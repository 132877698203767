import { createSlice } from '@reduxjs/toolkit'
import DtoCity from 'referential/dto/DtoCity'
import ReferentialThunk from 'referential/actions/ReferentialThunk'
import DtoDepartments from 'referential/dto/DtoDepartments'
import DtoWatershed from 'referential/dto/DtoWatershed'

export const initialState = {
    cities: [],
    departments: [],
    watersheds: [],
}

const store = createSlice({
    name: 'referential',
    initialState,
    reducers: {

    },
    extraReducers: {
        [ReferentialThunk.fetchCities.fulfilled]: (state, action) => {
            state.cities = action.payload.map(c => new DtoCity(c))
        },
        [ReferentialThunk.fetchDepartments.fulfilled]: (state, action) => {
            state.departments = action.payload.map(d => new DtoDepartments(d))
        },
        [ReferentialThunk.fetchWatersheds.fulfilled]: (state, action) => {
            state.watersheds = action.payload.map(w => new DtoWatershed(w))
        },
    },
})

export default store.reducer