export default class DtoStation {
    constructor(object) {
        this.type = object.type
        this.name = object.name
        this.code = object.code
        this.codebss = object.codebss
        this.x = object.x
        this.y = object.y
        this.soustype = object.soustype
    }
}