import { createSlice } from '@reduxjs/toolkit'
import AccountThunk from '../action/AccountThunk'
import DtoUser from '../dto/DtoUser'

export const initialState = {
    accountUser: {},
    accountHabilitations: [],
    accountUserKeyFigures: [],
    accountUserSettings: [],
    accountStatistics: [],
    accountStationStatistics: [],
    accountUsageStatistics: [],
    accountStations: [],
    accountContributor: {},
    picture: '',
    userCity: '',
}

const store = createSlice({
    name: 'account',
    initialState,
    reducers: {
        setAccountUser(state, action) {
            state.accountUser = new DtoUser(action.payload)
        },
        setUserPicture(state, action) {
            state.picture = action.payload
        },
        setUserCity(state, action) {
            state.userCity = action.payload
        },
    },
    extraReducers: {
        [AccountThunk.fetchUserData.fulfilled]: (state, action) => {
            state.accountUser = new DtoUser(action.payload)
        },
        [AccountThunk.fetchProfilePicture.fulfilled]: (state, action) => {
            state.picture = action.payload
        },
        [AccountThunk.fetchCity.fulfilled]: (state, action) => {
            state.userCity = action.payload
        },
    },
})

export const AccountAction = store.actions
export default store.reducer