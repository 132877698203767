import React, { useEffect, useState } from 'react'
import { Autocomplete, Grid, IconButton, TextField, useMediaQuery } from '@mui/material'
import i18n from 'simple-react-i18n'
import { useDispatch, useSelector } from 'react-redux'
import { MapAction } from '../map/reducers/MapReducer'
import { push } from 'connected-react-router'
import { HomeAction } from 'home/reducers/HomeReducer'
import MapThunk from 'map/actions/MapThunk'
import ClearIcon from '@mui/icons-material/Clear'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import SearchBarThunk from './actions/SearchBarThunk'
import { SearchBarAction } from './reducers/SearchBarReducer'
import { makeStyles } from '@mui/styles'
import { groupBy, uniqBy } from 'lodash'
import { useLocation } from 'react-router'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { SEARCH_PARAMETERS } from './constants/SearchParametersConstants'
import LocationCityIcon from '@mui/icons-material/LocationCity'
import RoomIcon from '@mui/icons-material/Room'
import InvertColorsIcon from '@mui/icons-material/InvertColors'
import WaterIcon from '@mui/icons-material/Water'
import WorkspacesIcon from '@mui/icons-material/Workspaces'
import SearchIcon from '@mui/icons-material/Search'
import Geolocation from '../map/components/Geolocation'
import SaveSelection from '../selection/components/SaveSelection'
import PropTypes from 'prop-types'
import VectorLayer from 'ol/layer/Vector'
import DtoSelection from '../selection/dto/DtoSelection'
import { BLUE_AQUADB } from '../utils/GlobalColorsUtils'

const useStyles = makeStyles(() => ({
    inputField: {
        zIndex: 8,
        backgroundColor: 'transparent',
        '& div': {
            backgroundColor: 'white',
        },
    },
    autocomplete: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        maxHeight: 'auto',
    },
}))

const AutocompleteAquadb = ({ saveData }) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const smMatches = useMediaQuery((th) => th.breakpoints.up('sm'))

    const { login, options, searchCriteria } = useSelector(store => ({
        login: store.AuthenticationReducer.login,
        options: store.SearchBarReducer.options,
        searchCriteria: store.MapReducer.searchCriteria,
    }),)

    const [optionToDisplay, setOptionToDisplay] = useState([])
    const [noOptionsText, setNoOptionsText] = useState(i18n.enterAtLeast3char)
    const [value, setValue] = useState([
        ...searchCriteria.cities,
        ...searchCriteria.departments,
        ...searchCriteria.watersheds,
        ...searchCriteria.stations,
        ...searchCriteria.epci,
    ])
    const [input, setInput] = useState('')
    const [isOpen, setIsOpen] = useState(false)

    const location = useLocation()

    useEffect(() => {
        setOptionToDisplay(input.length > 2 ? options : [])
    }, [options, input])

    useEffect(() => {
        setValue(uniqBy([
            ...searchCriteria.cities,
            ...searchCriteria.departments,
            ...searchCriteria.watersheds,
            ...searchCriteria.stations,
            ...searchCriteria.epci,
        ], 'code'))
    }, [searchCriteria])

    const startSearch = () => {
        dispatch(HomeAction.setAutocompleteSelected(false))
        setIsOpen(false)
        dispatch(SearchBarAction.resetOptions())

        const selectedOptions = groupBy(value, v => v.group)

        dispatch(MapAction.setSearchCriteria({
            cities: selectedOptions.city || [],
            departments: selectedOptions.department || [],
            watersheds: selectedOptions.watershed || [],
            stations: selectedOptions.station || [],
            epci: selectedOptions.epci || [],
        }))
        dispatch(MapAction.resetDataToDisplay())
        dispatch(MapAction.setLocated(false))

        if (selectedOptions.city) {
            selectedOptions.city.forEach(c => dispatch(MapThunk.fetchCityResult(c.code)))
        }
        if (selectedOptions.department) {
            selectedOptions.department.forEach(d => dispatch(MapThunk.fetchDepartmentResult(d.code)))
        }
        if (selectedOptions.watershed) {
            selectedOptions.watershed.forEach(d => dispatch(MapThunk.fetchWatershedResult(d.code)))
        }
        if (selectedOptions.station) {
            selectedOptions.station.forEach(d => dispatch(MapThunk.fetchStationResult(d.code)))
        }
        if (selectedOptions.epci) {
            selectedOptions.epci.forEach(d => dispatch(MapThunk.fetchEpciResult(d.code)))
        }

        if (location.pathname !== '/map') {
            dispatch(push('/map'))
        }
    }

    const onKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.defaultMuiPrevented = true
            startSearch()
        } else if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
            e.defaultMuiPrevented = true
        }
    }

    const handleChange = (_, selectedOptions) => {
        dispatch(MapAction.resetDataToDisplay())
        dispatch(MapAction.setLocated(false))
        setValue(uniqBy([ ...selectedOptions], 'code'))
    }

    const handleInputChange = (_, inputValue) => {
        setInput(inputValue)
        if (inputValue.length > 2) {
            setNoOptionsText(i18n.loading)
            const body = {
                input: inputValue,
                criterias: SEARCH_PARAMETERS.map(sp => sp.name),
                // geolocation: {
                //     longitude: geolocation.lon,
                //     latitude: geolocation.lat,
                // },
            }
            dispatch(SearchBarThunk.search(body))
        } else {
            setOptionToDisplay([])
            setNoOptionsText(i18n.enterAtLeast3char)
        }
    }

    const getGroupIconLabel = (icon, label) => {
        return <Grid container style={{ alignItems: 'center' }}>{icon} {label}</Grid>
    }

    const groups = {
        city: getGroupIconLabel(<LocationCityIcon style={{ paddingRight: 5 }}/>, i18n.cities),
        department: getGroupIconLabel(<RoomIcon style={{ paddingRight: 5 }}/>, i18n.departments),
        watershed: getGroupIconLabel(<WaterIcon style={{ paddingRight: 5 }}/>, i18n.watersheds),
        station: getGroupIconLabel(<InvertColorsIcon style={{ paddingRight: 5 }}/>, i18n.stations),
        epci: getGroupIconLabel(<WorkspacesIcon style={{ paddingRight: 5 }}/>, i18n.epci),
    }

    const theme = createTheme({
        components: {
            MuiAutocomplete: {
                styleOverrides: {
                    noOptions: {
                        fontSize: '0.7em',
                        fontStyle: 'italic',
                        padding: '7px',
                    },
                    inputRoot: {
                        border: `1.5px solid ${BLUE_AQUADB}`,
                        borderRadius: smMatches ? '50px' : '10px',
                    },
                },
            },
        },
    })

    return (
        <ThemeProvider theme={theme}>
            <Autocomplete
                multiple
                disableCloseOnSelect
                onChange={handleChange}
                onInputChange={handleInputChange}
                value={value}
                inputValue={input}
                open={isOpen}
                onOpen={() => {
                    setIsOpen(true)
                    dispatch(HomeAction.setAutocompleteSelected(true))
                }}
                onClose={() => {
                    setIsOpen(false)
                    dispatch(HomeAction.setAutocompleteSelected(false))
                }}
                options={optionToDisplay}
                noOptionsText={noOptionsText}
                groupBy={(option) => groups[option.group] ?? option.group}
                filterOptions={optn => optn}
                getOptionLabel={({ code, name, extra }) => {
                    return `${code ? `${code} - ` : ''}${name || ''}${extra ? ` - ${extra}` : ''}`
                }}
                className={classes.autocomplete}
                renderInput={(params) => (
                    <TextField
                        color='primary'
                        className={classes.inputField}
                        fullWidth
                        placeholder={i18n.geographicalCriteriaResearch}
                        onKeyDown={onKeyDown}
                        {...params}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    <>
                                        <IconButton size='small' onClick={() => startSearch()}>
                                            <SearchIcon style={{
                                                color: 'black',
                                            }}
                                            />
                                        </IconButton>
                                        {params.InputProps.endAdornment}
                                    </>
                                    <>
                                        <IconButton size='small'>
                                            <Geolocation/>
                                        </IconButton>
                                        {params.InputProps.endAdornment}
                                    </>
                                    {
                                        login && saveData && (
                                            <>
                                                <SaveSelection
                                                    layers={ saveData.layers }
                                                    watersheds={ saveData.watersheds }
                                                    selection={ saveData.selection }
                                                />
                                                {params.InputProps.endAdornment}
                                            </>
                                        )
                                    }
                                </>
                            ),
                        }}
                    />
                )}
                ChipProps={{
                    variant: 'outlined',
                    color: 'primary',
                }}
                clearIcon={
                    <ClearIcon
                        fontSize='small'
                        style={{
                            color: 'black',
                            visibility: 'visible',
                        }}
                    />
                }
                popupIcon={(
                    <ExpandMoreIcon
                        fontSize='small'
                        style={{
                            color: 'black',
                            visibility: 'visible',
                        }}
                    />
                )}
            />
        </ThemeProvider>
    )
}

AutocompleteAquadb.propTypes = {
    saveData: PropTypes.arrayOf(PropTypes.shape({
        layers: PropTypes.arrayOf(PropTypes.shape({
            layer: PropTypes.shape({
                current: PropTypes.instanceOf(VectorLayer),
            }),
            code: PropTypes.string,
            label: PropTypes.string,
            isSelected: PropTypes.bool,
        })),
        watersheds: PropTypes.arrayOf(PropTypes.string),
        selection: PropTypes.instanceOf(DtoSelection),
    })),
}

export default AutocompleteAquadb