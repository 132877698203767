/* eslint-disable no-console */
'use strict'
import fetch from 'isomorphic-fetch'
import i18n from 'simple-react-i18n'
import {
    RECEIVE_CONTACT,
    RECEIVE_CHAT,
    RECEIVE_MESSAGE,
    RECEIVE_MESSAGES_OF,
    RECEIVE_CONTACTS,
    RECEIVE_CONTACT_CEDULA,
    RESET_CONTACT_CEDULA,
    RECEIVE_SETTINGS,
} from '../constants/ContactConstants'
import ApplicationConf from 'conf/ApplicationConf'
import { checkAuth, checkError, getAuthorization, getJson } from 'utils/ActionUtils'
import { hasValue } from 'utils/NumberUtil'
import AppStore from 'store/AppStore'
import { ToastrAction } from '../../../../toastr/reducers/ToastrReducer'

const ContactAction = {
    receiveContact(contact) {
        return { type: RECEIVE_CONTACT, contact }
    },

    promiseContact(code) {
        return fetch(ApplicationConf.referencial.contact(code), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchContact(code) {
        return (dispatch) => {
            return ContactAction.promiseContact(code)
                .then((json = {}) => {
                    if (json.id) {
                        dispatch(ContactAction.receiveContact(json))
                    }
                })
                .catch((err) => {
                    console.error(`${i18n.fetchError + i18n.contact} : ${err}`)
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.contact))
                })
        }
    },

    receiveContactCedula(contactCedula) {
        return { type: RECEIVE_CONTACT_CEDULA, contactCedula }
    },

    fetchContactCedula(cedula, countryCodeUser) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.contactByCedula(cedula), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json = []) => {
                    const contactCedula = json.find(c => c.countryCode === countryCodeUser)
                    if (contactCedula) {
                        dispatch(ContactAction.receiveContactCedula(contactCedula))
                    } else {
                        dispatch(ContactAction.resetContactCedula())
                    }
                })
                .catch((err) => {
                    console.log(`${i18n.fetchError + i18n.contact} : ${err}`)
                })
        }
    },

    receiveContacts(contacts) {
        return { type: RECEIVE_CONTACTS, contacts }
    },

    promiseContacts() {
        return fetch(ApplicationConf.referencial.contacts(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchContacts() {
        return (dispatch) => {
            return ContactAction.promiseContacts()
                .then((json = []) => {
                    dispatch(ContactAction.receiveContacts(json))
                })
                .catch((err) => {
                    console.error(`${i18n.fetchError + i18n.contacts} : ${err}`)
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.contacts))
                })
        }
    },

    updateContact(contact) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.contacts(), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(contact),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    if (json.update === 1) {
                        dispatch(ContactAction.fetchContact(contact.id))
                        dispatch(ToastrAction.success(i18n.updateUserSucceed))
                        return contact.id
                    }
                    throw new Error()
                })
                .catch((err) => {
                    console.error(`${i18n.updateError + i18n.contact} : ${err}`)
                })
        }
    },

    resetContactCedula() {
        return (dispatch) => {
            dispatch({ type: RESET_CONTACT_CEDULA })
        }
    },

    createContact(contact) {
        return () => {
            return fetch(ApplicationConf.referencial.contacts(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(contact),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.insert === 1) {
                        return json.id
                    }
                    throw new Error()
                }).catch((err) => {
                    console.error(`${i18n.createError + i18n.contact} : ${err}`)
                })
        }
    },

    getChat(login, dest) {
        return (dispatch) => {
            return fetch(ApplicationConf.contact.getChat(login, dest), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then((json) => {
                    dispatch(ContactAction.receiveChat(json))
                })
                .catch((err) => {
                    console.error(`${i18n.fetchError} : ${err}`)
                })
        }
    },

    promiseMessagesOf(login) {
        return fetch(ApplicationConf.contact.getMessagesOf(login), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    getMessagesOf(login) {
        return (dispatch) => {
            return ContactAction.promiseMessagesOf(login)
                .then((json) => {
                    dispatch(ContactAction.receiveMessagesOf(json))
                })
                .catch((err) => {
                    console.error(`${i18n.fetchError} : ${err}`)
                })
        }
    },

    getMessage(id) {
        return (dispatch) => {
            return fetch(ApplicationConf.contact.getMessage(id), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then((json) => {
                    dispatch(ContactAction.receiveMessage(json))
                })
                .catch((err) => {
                    console.error(`${i18n.fetchError} : ${err}`)
                })
        }
    },

    sendMessage(userMessage) {
        return () => {
            return fetch(ApplicationConf.contact.sendMessage(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(userMessage),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    if (json.id) {
                        return json.id
                    }
                    throw new Error()
                })
                .catch((err) => {
                    console.error(`${i18n.createError} : ${err}`)
                })
        }
    },

    defConv(userMessageDest) {
        return () => {
            return fetch(ApplicationConf.contact.defConv(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(userMessageDest),
            })
                .then(checkAuth)
                .catch((err) => {
                    console.error(`${i18n.createError} : ${err}`)
                })
        }
    },

    receiveChat(chat) {
        return { type: RECEIVE_CHAT, chat }
    },

    receiveMessagesOf(listOfMessages) {
        return { type: RECEIVE_MESSAGES_OF, listOfMessages }
    },

    receiveMessage(message) {
        return { type: RECEIVE_MESSAGE, message }
    },

    receiveSettings(settings) {
        return { type: RECEIVE_SETTINGS, settings }
    },
    promiseSettings() {
        return fetch(ApplicationConf.user.settings(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
    },
    fetchSettings() {
        return (dispatch) => {
            return ContactAction.promiseSettings()
                .then(json => {
                    dispatch(ContactAction.receiveSettings(json))
                })
                .catch((err) => {
                    console.error(`${i18n.fetchError + i18n.settings} : ${err}`)
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.settings))
                })
        }
    },

    updateSetting(settingName, value) {
        return (dispatch) => {
            const setting = AppStore.getState().ContactReducer.settings.find(s => s.parameter === settingName) || {}
            return fetch(ApplicationConf.user.setting(), {
                method: hasValue(setting.value) && setting.login !== '' ? 'PUT' : 'POST',
                headers: getAuthorization(),
                body: JSON.stringify({
                    parameter: settingName,
                    value,
                }),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json = {}) => {
                    if (json.insert === 1 || json.update === 1) {
                        dispatch(ContactAction.fetchSettings())
                        window.location.reload()
                    } else {
                        throw new Error()
                    }
                })
                .catch((err) => {
                    console.error(i18n.updateError + i18n.setting + err)
                    dispatch(ToastrAction.error(i18n.updateError + i18n.setting))
                })
        }
    },

}

export default ContactAction
