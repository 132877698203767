import React, { useState } from 'react'
import i18n from 'simple-react-i18n'
import { push } from 'connected-react-router'
import { Grid } from '@mui/material'
import { useTheme } from '@mui/styles'
import { InputRow } from 'components/styled/Inputs'
import { PrimaryButton } from 'components/styled/Buttons'
import { useDispatch } from 'react-redux'
import AuthenticationThunk from './actions/AuthenticationThunk'
import { AQUADB_LOGO, AQUASYS_LOGO } from 'home/constants/HomeConstants'
import { ToastrAction } from '../toastr/reducers/ToastrReducer'
import { useLocation } from 'react-router'

const Reset = () => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const token = params.get('token')

    const [login, setLogin] = useState('')
    const [isLoginError, setLoginError] = useState(false)
    const [isMailSent, setMailSent] = useState(false)

    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')
    const isPasswordError = !new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/).test(password)
    const isPasswordConfirmError = password !== passwordConfirm

    const handleChangeValue = (value) => {
        setLoginError(false)
        setMailSent(false)
        setLogin(value)
    }

    const onSubmit = () => {
        if (login) {
            setLoginError(false)
            dispatch(AuthenticationThunk.resetPassword(login))
                .unwrap()
                .then((json) => {
                    if (!json) {
                        dispatch(ToastrAction.info(i18n.sendEmail))
                        setMailSent(true)
                    } else {
                        setLoginError(true)
                    }
                })
        } else {
            setLoginError(true)
        }
    }

    const onSubmitPassword = () => {
        if (token && !isPasswordError && !isPasswordConfirmError) {
            dispatch(AuthenticationThunk.updatePassword({ password, token }))
        }
    }

    return (
        <Grid
            container
            direction='row'
            justifyContent='space-evenly'
            alignItems='flex-start'
            sx={theme.menu.backgroundAquadb}
        >
            <Grid container sx={theme.menu.center} justifyContent={'center'}>
                <Grid item xs={12} sx={theme.menu.logoAquadb} >
                    <img src={AQUADB_LOGO} alt='' onClick={() => dispatch(push('/home'))}/>
                </Grid>
                <Grid container item xs={10} md={5} justifyContent={'center'} style={{ backgroundColor: 'white', margin: '100px 0', padding: '50px 0', borderRadius: 3 }}>
                    {
                        token ? (
                            <>
                                <Grid container justifyContent={'center'} style={{ fontSize: '1.5em', marginBottom: 15 }}>
                                    {i18n.changePassword}
                                </Grid>
                                <Grid container justifyContent={'center'} style={{ textAlign: 'center', margin: 15 }}>
                                    {i18n.newPasswordText}
                                </Grid>
                                <Grid item xs={11} md={7} style={{ marginBottom: 25 }}>
                                    <InputRow
                                        id='password'
                                        label={i18n.newPassword}
                                        type='password'
                                        value={password}
                                        error={isPasswordError && password !== ''}
                                        helperText={isPasswordError ? i18n.incorrectPassword : ''}
                                        onChange={(event) => setPassword(event.target.value)}
                                        variant='standard'
                                        style={{ marginBottom: 15 }}
                                    />
                                    <InputRow
                                        id='passwordConfirm'
                                        label={i18n.newPasswordAgain}
                                        type='password'
                                        value={passwordConfirm}
                                        error={isPasswordConfirmError}
                                        helperText={isPasswordConfirmError ? i18n.invalidNewPassword : ''}
                                        onChange={(event) => setPasswordConfirm(event.target.value)}
                                        variant='standard'
                                        style={{ marginBottom: 15 }}
                                    />
                                    <PrimaryButton onClick={onSubmitPassword} style={{ marginBottom: 10 }}>
                                        {i18n.save}
                                    </PrimaryButton>
                                </Grid>
                            </>
                        ) : (
                            <>
                                <Grid container justifyContent={'center'} style={{ fontSize: '1.5em', marginBottom: 15 }}>
                                    {i18n.forgetPassword}
                                </Grid>
                                <Grid container justifyContent={'center'} style={{ textAlign: 'center', margin: 15 }}>
                                    {isMailSent ? i18n.sendEmail : i18n.forgetPasswordText}
                                </Grid>
                                <Grid item xs={11} md={7} style={{ marginBottom: 25 }}>
                                    <InputRow
                                        id='login'
                                        label={i18n.login}
                                        type='text'
                                        value={login}
                                        error={isLoginError}
                                        helperText={isLoginError ? i18n.invalidLogin : ''}
                                        onChange={(event) => handleChangeValue(event.target.value)}
                                        variant='standard'
                                        style={{ marginBottom: 15 }}
                                    />
                                    <PrimaryButton onClick={onSubmit} style={{ marginBottom: 10 }}>
                                        {i18n.send}
                                    </PrimaryButton>
                                    <PrimaryButton
                                        id='cancel'
                                        variant='contained'
                                        reverse='true'
                                        onClick={() => dispatch(push('/login'))}
                                        style={{ marginBottom: 15 }}
                                    >
                                        {i18n.cancel}
                                    </PrimaryButton>
                                </Grid>
                            </>
                        )
                    }
                </Grid>
                <Grid item xs={12} container justifyContent='center' alignItems='center' sx={theme.menu.logoAquasys} >
                    <img src={AQUASYS_LOGO} alt='' onClick={() => window.open('https://www.aquasys.fr/', '_blank')} />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Reset
