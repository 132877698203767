import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup, FormHelperText,
    Grid,
    Modal,
    useMediaQuery,
} from '@mui/material'
import i18n from 'simple-react-i18n'
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { HomeAction } from '../home/reducers/HomeReducer'
import { isUndefined } from 'lodash'

const useStyles = makeStyles(() => ({
    modal: {
        width: '100%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        '&:focus': {
            outline: 'none',
        },
        overflow: 'scroll',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        '&::-webkit-scrollbar-track': {
            display: 'none',
        },
        '&::-webkit-scrollbar-thumb': {
            display: 'none',
        },
    },
    content: {
        backgroundColor: 'white',
        padding: '15px 10px',
        alignItems: 'center',
        fontSize: '1.1em',
    },
    button: {
        border: '#353333 solid 1px',
        padding: 10,
        cursor: 'pointer',
        borderRadius: 4,
        color: 'white',
        fontWeight: 'bold',
    },
    reject: {
        backgroundColor: 'white',
        color: '#232052',
        '&:hover': {
            color: 'white',
            transitionDuration: '0.25s',
            backgroundColor: '#232052',
        },
    },
    valid: {
        backgroundColor: '#232052',
        '&:hover': {
            transitionDuration: '0.25s',
            backgroundColor: '#353333',
        },
    },
    customize: {
        transition: 'opacity 0.5s ease-in',
    },
}))

const Cookies = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const smMatches = useMediaQuery((th) => th.breakpoints.up('sm'))
    const [required, setRequired] = useState(false)
    const [customize, setCustomize] = useState(false)

    const [functional, setFunctional] = useState(true)
    const [analytics, setAnalytics] = useState(true)

    const { needShowCookies } = useSelector(store => ({
        needShowCookies: store.HomeReducer.showCookies,
    }))

    useEffect(() => {
        const cookies = JSON.parse(localStorage.getItem('cookies'))

        setCustomize(false)

        if (cookies === null || isUndefined(cookies.consents) || moment(new Date().getTime()).diff(cookies.timestamp, 'months') >= 13) {
            setRequired(true)

            localStorage.setItem('cookies', JSON.stringify({
                timestamp: new Date().getTime(),
                consents: {
                    functional: true,
                    analytics: true,
                },
            }))
        } else {
            const consents = cookies.consents

            setFunctional(consents.functional)
            setAnalytics(consents.analytics)
        }
    }, [])

    useEffect(() => {
        const cookies = JSON.parse(localStorage.getItem('cookies'))
        const consents = cookies.consents
        const newConsents = {
            ...consents,
            functional: functional ?? consents.functional,
            analytics: analytics ?? consents.analytics,
        }

        localStorage.setItem('cookies', JSON.stringify({
            ...cookies,
            consents: newConsents,
        }))
    }, [functional, analytics])

    const hideDisplay = () => {
        setRequired(false)
        dispatch(HomeAction.setShowCookies(false))
    }

    return (
        <Modal
            open={required || needShowCookies}
        >
            <Box className={classes.modal} style={{ maxHeight: '100%', overflow: 'scroll' }}>
                <Grid container justifyContent={'center'}>
                    <Grid container item sm={6} spacing={2} className={classes.content}>
                        <Grid item style={{ fontSize: '1.2em', fontWeight: 'bold' }}>
                            {i18n.acceptCoookiesTitle}
                        </Grid>
                        <Grid container item>
                            {i18n.acceptCookies}
                        </Grid>
                        <Grid container style={{ paddingTop: 10 }} spacing={1} justifyContent={smMatches ? 'flex-end': 'center'}>
                            <Grid item>
                                <button className={`${classes.button} ${classes.reject}`} onClick={() => setCustomize(!customize)}>{i18n.customizer}</button>
                            </Grid>
                            <Grid item>
                                <button className={`${classes.button} ${classes.reject}`} onClick={() => {
                                    setFunctional(false)
                                    setAnalytics(false)
                                    hideDisplay()
                                }}
                                >{i18n.refuse}</button>
                            </Grid>
                            <Grid item>
                                <button className={`${classes.button} ${classes.valid}`} onClick={() => {
                                    setFunctional(true)
                                    setAnalytics(true)
                                    hideDisplay()
                                }}
                                >{i18n.agree}</button>
                            </Grid>
                        </Grid>
                        <Grid container item display={!customize ? 'none' : 'initial'} >
                            <Grid item style={{ fontSize: '1.1em', fontWeight: 'bold' }}>
                                {i18n.customizeConsent}
                            </Grid>
                            <Grid item style={{ paddingTop: 10 }}>
                                {i18n.customizeConsentDesc}
                            </Grid>
                            <Grid container item style={{ paddingTop: 10 }}>
                                <FormControl required>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox style={{ color: '#353333' }} checked={true} />} label={i18n.necessary} />
                                        <FormHelperText>{i18n.necessaryDesc}</FormHelperText>
                                        <FormControlLabel control={<Checkbox onChange={() => setFunctional(!functional)} checked={functional} />} label={i18n.functional} />
                                        <FormHelperText>{i18n.functionalDesc}</FormHelperText>
                                        <FormControlLabel control={<Checkbox onChange={() => setAnalytics(!analytics)} checked={analytics} />} label={i18n.analytics} />
                                        <FormHelperText>{i18n.analyticsDesc}</FormHelperText>
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                            <Grid container item style={{ paddingTop: 10 }} justifyContent={smMatches ? 'flex-end': 'center'}>
                                <Grid item>
                                    <button className={`${classes.button} ${classes.valid}`} onClick={() => {
                                        hideDisplay()
                                    }}
                                    >{i18n.saveCookies}</button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
}

Cookies.propTypes = {
    required: PropTypes.bool,
}

export default Cookies