import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import { Box, Grid } from '@mui/material'

const BackgroundTab = ({ onClick, backgrounds, selectedBackground }) => {
    const setBackground = (e, bgcode) => {
        onClick(e, bgcode)
    }

    return (
        <FormControl>
            <FormLabel style={{ marginBottom: 10, fontSize: 23, color: 'black' }}>{i18n.backgroundLayers}</FormLabel>
            <RadioGroup
                value={selectedBackground}
                onChange={setBackground}
            >
                {
                    backgrounds.map((b, i) => (
                        <Grid key={i} container alignItems={'center'} style={{ padding: '5px 0' }}>
                            <Grid item width={'60px'} height={'50px'} style={{ marginRight: '10px', boxShadow: '0 2px 10px -3px black' }}>
                                <img width={'100%'} height={'100%'} src={b.preview}/>
                            </Grid>
                            <Grid item>
                                <FormControlLabel key={i} value={b.code} control={<Radio/>} label={<Box fontSize={17}>
                                    {b.label}
                                </Box>}
                                />
                            </Grid>
                        </Grid>
                    ))
                }
            </RadioGroup>
        </FormControl>
    )
}

BackgroundTab.propTypes = {
    onClick: PropTypes.func,
    backgrounds: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
    })),
    selectedBackground: PropTypes.string,
}

export default BackgroundTab