import React, { useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import ContactAction from '../pages/online/contact/actions/ContactAction'
import { Autocomplete, Box, Grid, Icon, IconButton, TextField, useMediaQuery } from '@mui/material'
import BurgerMenu from '../burgerMenu/BurgerMenu'
import i18n from 'simple-react-i18n'
import noPicture from '../assets/pictures/noPicture.png'
import ReferentialThunk from '../referential/actions/ReferentialThunk'
import LoadingScreen from '../map/components/LoadingScreen'
import PropTypes from 'prop-types'
import { HOME_BACKGROUND_IMAGE, URL } from '../home/constants/HomeConstants'
import { useTheme } from '@emotion/react'
import AccountThunk from './action/AccountThunk'

const useStyles = makeStyles(() => ({
    background: {
        backgroundImage: HOME_BACKGROUND_IMAGE,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundAttachment: 'fixed',
        backgroundSize: 'cover',
        overflowX: 'hidden',
        height: '100%',
        width: '100%',
    },
    titleContainer: {
        color: 'white',
        borderRadius: '5px',
        padding: '15px',
        margin: '20px auto',
    },
    title: {
        fontWeight: 'bold',
        fontSize: '3em',
        paddingBottom: 5,
    },
    content: {
        backgroundColor: 'white',
        borderRadius: '4px',
        padding: '20px',
        boxShadow: '1px 1px 12px rgba(0,0,0,0.5)',
    },
    hr: {
        backgroundColor: 'white',
        height: '3px',
        border: 'none',
        borderRadius: '3px',
        margin: '10px',
    },
    userProfile: {
        borderRadius: '50%',
        boxShadow: '1px 1px 12px rgba(0,0,0,0.5)',
        objectFit: 'cover',
        width: '100%',
        height: '100%',
    },
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
        '&:focus': {
            outline: 'none',
        },
    },
    button: {
        border: '1px solid #353333',
        padding: '10px',
        cursor: 'pointer',
        borderRadius: '4px',
        color: 'white',
        fontWeight: 'bold',
    },
    save: {
        backgroundColor: 'white',
        color: '#232052',
        '&:hover': {
            color: 'white',
            transitionDuration: '0.25s',
            backgroundColor: '#232052',
        },
    },
    disabled: {
        color: '#B8B1B1',
        backgroundColor: '#353333',
        cursor: 'not-allowed',
    },
}))

const AccountTextField = ({ required = false, error, label, value, setValue, placeholder, disabled }) => {
    return (
        <TextField
            fullWidth
            disabled={disabled}
            required={required}
            helperText={required && error || ''}
            error={required && error !== ''}
            label={label}
            value={value}
            onChange={(e) => {
                setValue(e.target.value)
            }}
            size={'small'}
            placeholder={placeholder}
            variant='standard'
        />
    )
}

AccountTextField.propTypes = {
    required: PropTypes.bool,
    error: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    setValue: PropTypes.func,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
}

const Account = () => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const [optionToDisplay, setOptionToDisplay] = useState([])

    const theme = useTheme()
    const smMatches = useMediaQuery((th) => th.breakpoints.up('sm'))

    const { login, user, contact, cities, pictureProfile, userCity } = useSelector(store => ({
        login: store.AuthenticationReducer.login,
        user: store.AccountReducer.accountUser,
        contact: store.ContactReducer.contact,
        cities: store.ReferentialReducer.cities,
        pictureProfile: store.AccountReducer.picture,
        userCity: store.AccountReducer.userCity,
    }))

    const [username, setUsername] = useState(contact.name ?? '')
    const [email, setEmail] = useState(login ?? '')
    const [address, setAddress] = useState(contact.address ?? '')
    const [postalCode, setPostalCode] = useState(contact.codepostal ?? '')
    const [city, setCity] = useState('')
    const [cityCode, setCityCode] = useState(contact.cityCode)
    const [selectedCity, setSelectedCity] = useState(null)
    const [mobile, setMobile] = useState(contact.mobile ?? '')
    const [profileUrl, setProfileUrl] = useState(pictureProfile ?? noPicture)
    const [isLoading, setIsLoading] = useState(false)

    const noOptionsText = useMemo(() => {
        if (isLoading) {
            return i18n.loading
        }

        return i18n.pleaseEnterCityMinimum
    }, [isLoading])

    const emailHasAnError = !email || !(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email))

    const handleInputChange = (_, inputValue, reason) => {
        setCity(inputValue)

        if (reason === 'clear' || inputValue.length <= 2) {
            setCityCode(undefined)
            setSelectedCity(null)
            setOptionToDisplay([])

            return
        }

        if (!cities.length && !isLoading) {
            setIsLoading(true)
            dispatch(ReferentialThunk.fetchCities())
        } else {
            const foundCity = cities.find(s => inputValue.includes(s.code))

            if (foundCity) {
                setCityCode(foundCity.code)
            }
        }
    }

    const checkInputs = username !== contact.name ||
            email !== login ||
            address !== contact.address ||
            postalCode !== contact.postalBox ||
            cityCode !== contact.cityCode ||
            mobile !== contact.mobile && !emailHasAnError

    useEffect(() => {
        setOptionToDisplay(city && city.length > 2 ? cities : [])
    }, [cities, city])

    useEffect(() => {
        if (cityCode && cities.length) {
            setSelectedCity(cities.find(s => s.code === cityCode))
        }
    }, [cities, cityCode, dispatch])

    useEffect(() => {
        if (userCity) {
            setSelectedCity(userCity)
        }
    }, [userCity])

    useEffect(() => {
        if (!login) {
            dispatch(push('/login'))
        } else {
            dispatch(AccountThunk.fetchUserData(login))
        }
    }, [dispatch, login])

    useEffect(() => {
        if (user.contactCode) {
            dispatch(ContactAction.fetchContact(user.contactCode))
        }
    }, [dispatch, user])

    useEffect(() => {
        setProfileUrl(`${URL}contents/PHOTOS/${pictureProfile.name}`)
    }, [pictureProfile])

    useEffect(() => {
        dispatch(AccountThunk.fetchProfilePicture(login))
        setUsername(contact.name)
        setAddress(contact.address)
        setPostalCode(contact.postalBox)
        setMobile(contact.mobile)
        setCityCode(contact.cityCode)
        if (contact.cityCode) {
            dispatch(AccountThunk.fetchCity(contact.cityCode))
        }
    }, [contact.address, contact.cityCode, contact.mobile, contact.name, contact.postalBox, dispatch, login, user])

    useEffect(() => {
        setIsLoading(false)
    }, [cities.length])

    return (
        <div className={classes.background}>
            <LoadingScreen isOpen={!contact.email}/>
            <Grid container direction='row' alignItems='center' spacing={2} style={{ padding: 8 }}>
                <Grid item xs={12}>
                    {
                        smMatches && (
                            <BurgerMenu color='light'/>
                        )
                    }
                </Grid>
                <Grid container item spacing={4} paddingTop={smMatches ? 50 : 0} justifyContent={'center'}>
                    <Grid container item md={9}>
                        <Grid container style={{ paddingBottom: 20 }}>
                            <Grid container item xs={12} md={8} className={classes.titleContainer}>
                                <Grid container className={classes.title}>
                                    {i18n.myAccount}
                                </Grid>
                                <Grid item xs={10} sm={6} className={classes.hr} style={{ padding: '0 auto 20 auto' }} />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.content}>
                            <Grid container item spacing={3} alignItems={'center'}>
                                <Grid container item md={9} spacing={2} style={{ marginTop: '10px', marginBottom: '10px' }}>
                                    <Grid item xs={12} md={6}>
                                        <AccountTextField
                                            label={i18n.name}
                                            value={username}
                                            setValue={setUsername}
                                            placeholder={i18n.pleaseEnterName}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <AccountTextField
                                            required={true}
                                            disabled={true}
                                            error={emailHasAnError ? i18n.invalidEmail : ''}
                                            label={i18n.mail}
                                            value={email}
                                            setValue={setEmail}
                                            placeholder={i18n.pleaseEnterMail}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <AccountTextField
                                            label={i18n.address}
                                            value={address}
                                            setValue={setAddress}
                                            placeholder={i18n.pleaseEnterAddress}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <AccountTextField
                                            label={i18n.postalCode}
                                            value={postalCode}
                                            setValue={setPostalCode}
                                            placeholder={i18n.pleaseEnterPostalCode}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Autocomplete
                                            onInputChange={handleInputChange}
                                            renderInput={(params) => (
                                                <TextField
                                                    fullWidth
                                                    label={i18n.city}
                                                    {...params}
                                                    size={'small'}
                                                    placeholder={i18n.pleaseEnterCity}
                                                    variant='standard'
                                                />
                                            )}
                                            value={selectedCity}
                                            getOptionLabel={({ code, id, name }) => {
                                                return `${code ? `${code} - ` : ''}${id ? `${id} - ` : ''}${name}`
                                            }}
                                            options={optionToDisplay}
                                            noOptionsText={noOptionsText}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <AccountTextField
                                            label={i18n.mobile}
                                            value={mobile}
                                            setValue={setMobile}
                                            placeholder={i18n.pleaseEnterMobile}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container item md={3} justifyContent={'center'}>
                                    <Grid item style={{ position: 'relative', width: 256, height: 256 }}>
                                        <img src={`${profileUrl}?${Date.now()}`} onError={() => setProfileUrl(noPicture)} alt='User logo' className={classes.userProfile}/>
                                        <IconButton
                                            aria-label='change picture'
                                            component='label'
                                            sx={{
                                                position: 'absolute',
                                                top: '0px',
                                                right: '25%',
                                                backgroundColor: '#322881',
                                                color: 'white',
                                                borderRadius: '50%',
                                                fontSize: '1.5rem',
                                                width: '2.5rem',
                                                height: '2.5rem',
                                                padding: '0.75rem',
                                                '&:hover': {
                                                    backgroundColor: '#493bbd',
                                                },
                                            }}
                                            onChange={(event) => {
                                                if (event.target.files && event.target.files[0]) {
                                                    let reader = new FileReader()
                                                    const file = event.target.files[0]
                                                    const name = `${login}${file.name.substring(file.name.lastIndexOf('.'), file.name.length)}`
                                                    reader.onload = (e) => {
                                                        dispatch(AccountThunk.updateProfilePicture({
                                                            name,
                                                            content: e.target.result,
                                                        }, login))
                                                    }
                                                    reader.readAsDataURL(event.target.files[0])
                                                    const newContact = {
                                                        ...contact,
                                                        name: username,
                                                        address,
                                                        postalBox: postalCode,
                                                        cityCode,
                                                        mobile,
                                                    }
                                                    dispatch(ContactAction.updateContact(newContact))
                                                }
                                            }}
                                        >
                                            <input hidden accept='image/*' type='file' multiple />
                                            <Icon>{'edit'}</Icon>
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {
                                <Grid container justifyContent={'flex-end'} style={{ paddingTop: '10px' }}>
                                    <button
                                        disabled={!checkInputs}
                                        className={`${classes.button} ${!checkInputs ? classes.disabled : classes.save}`}
                                        onClick={() => {
                                            const newContact = {
                                                ...contact,
                                                name: username,
                                                address,
                                                postalBox: postalCode,
                                                cityCode,
                                                mobile,
                                            }
                                            dispatch(ContactAction.updateContact(newContact))
                                        }}
                                    >
                                        {i18n.register}
                                    </button>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            { !smMatches && (
                <Box sx={theme.menu.burger.mobile}>
                    <BurgerMenu color='light'/>
                </Box>
            )}
        </div>
    )
}

export default Account