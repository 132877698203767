import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    showCookies: false,
    showCGU: false,
    CGU: null,
    CGUValidDate: false,
    isAutocompleteSelected: false,
}

const store = createSlice({
    name: 'home',
    initialState,
    reducers: {
        setShowCookies(state, action) {
            state.showCookies = action.payload
        },
        setShowCGU(state, action) {
            state.showCGU = action.payload
        },
        setCGU(state, action) {
            state.CGU = action.payload
        },
        setCGUValidDate(state, action) {
            state.CGUValidDate = action.payload
        },
        setAutocompleteSelected(state, action) {
            state.isAutocompleteSelected = action.payload
        },
    },
    extraReducers: {
    },
})

export const HomeAction = store.actions
export default store.reducer