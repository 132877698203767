import { createAsyncThunk } from '@reduxjs/toolkit'
import ApplicationConf from 'conf/ApplicationConf'
import i18n from 'simple-react-i18n'
import LogAction from 'log/actions/LogAction'
import { checkAuth, checkError, genericPublicPromise, getAuthorization, getJson } from 'utils/ActionUtils'
import { SIEAU_LOGIN, SIEAU_TOKEN } from 'pages/offline/constants/HomeConstants'
import { push } from 'connected-react-router'
import { AuthenticationAction } from 'authentication/reducers/AuthenticationReducer'
import { ToastrAction } from '../../toastr/reducers/ToastrReducer'
import { URL } from '../../home/constants/HomeConstants'
import { WaitAction } from '../../wait/WaitReducer'

const AuthenticationThunk = {
    login: createAsyncThunk(
        'login',
        async(lp, { rejectWithValue, dispatch }) => {
            try {
                const basicAuth = btoa(`${lp.login}:${lp.password}`)
                dispatch(WaitAction.waitStart())
                const response = await fetch(ApplicationConf.login(), {
                    method: 'POST',
                    headers: {
                        Authorization: `Basic ${basicAuth}`,
                    },
                    body: JSON.stringify({
                        module: 'AQUADB',
                    }),
                })
                    .then(checkAuth)
                    .then(getJson)
                    .then(json => {
                        dispatch(AuthenticationAction.setLogin(lp.login))
                        localStorage.setItem(SIEAU_LOGIN, lp.login)
                        localStorage.setItem(SIEAU_TOKEN, json)
                        dispatch(WaitAction.waitStop())
                        const wantedUrl = localStorage.getItem('URL_WANTED')
                        if (wantedUrl) {
                            localStorage.removeItem('URL_WANTED')
                            dispatch(push(wantedUrl))
                        } else {
                            dispatch(push('/home'))
                        }
                    }).finally(dispatch(WaitAction.waitStop()))
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.unauthorizedLogin} : ${err}`))
                dispatch(ToastrAction.error(i18n.unauthorizedLogin))
                return rejectWithValue(err)
            }
        }
    ),
    logout: createAsyncThunk(
        'logout',
        async(_, { rejectWithValue, dispatch }) => {
            try {
                dispatch(WaitAction.waitStart())
                const response = await fetch(ApplicationConf.logout(), {
                    method: 'POST',
                    headers: getAuthorization(),
                }).then(() => {
                    localStorage.removeItem(SIEAU_TOKEN)
                    localStorage.removeItem(SIEAU_LOGIN)
                    dispatch(ToastrAction.success(i18n.logoutSucceed))
                    dispatch(push('/home'))
                }).finally(dispatch(WaitAction.waitStop()))
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.unauthorizedLogin} : ${err}`))
                dispatch(ToastrAction.error(i18n.unauthorizedLogin))
                return rejectWithValue(err)
            }
        }
    ),
    fetchApplicationSettings: createAsyncThunk(
        'fetchApplicationSettings',
        async(_, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPublicPromise(ApplicationConf.user.applicationSettings())
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError}${i18n.settings} : ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.settings))
                return rejectWithValue(err)
            }
        }
    ),
    resetPassword: createAsyncThunk(
        'resetPassword',
        async(login, { rejectWithValue, dispatch }) => {
            try {
                const response = fetch(ApplicationConf.resetPassword(), {
                    method: 'POST',
                    body: JSON.stringify({ login, url: URL.slice(0, -1) }),
                })
                    .then(checkError)
                    .then((json) => {
                        if (json.status !== 200) {
                            ToastrAction.error(i18n.invalidLogin, true)
                            return json
                        }
                        return null
                    })
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.retrievePassword} : ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.retrievePassword, true))
                return rejectWithValue(err)
            }
        }
    ),
    updatePassword: createAsyncThunk(
        'updatePassword',
        async(args, { rejectWithValue, dispatch }) => {
            try {
                const response = fetch(ApplicationConf.resetPassword(), {
                    method: 'PUT',
                    body: JSON.stringify({ password: args.password, token: args.token }),
                })
                    .then(checkError)
                    .then((json) => {
                        if (json.status !== 200) {
                            dispatch(ToastrAction.error(i18n.invalidToken))
                            dispatch(push('/reset'))
                            return json
                        }
                        dispatch(ToastrAction.success(i18n.passwordUpdated))
                        dispatch(push('/login'))
                        return null
                    })
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.retrievePassword} : ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.retrievePassword))
                return rejectWithValue(err)
            }
        }
    ),
    createUser: createAsyncThunk(
        'createUser',
        async(user, { rejectWithValue, dispatch }) => {
            try {
                dispatch(WaitAction.waitStart())
                const response = fetch(ApplicationConf.user.createUser(), {
                    method: 'POST',
                    body: JSON.stringify(user),
                })
                    .then(getJson)
                    .then(json => checkError(json, {
                        409: () => {
                            dispatch(ToastrAction.error(i18n.identifierAlreadyTaken))
                            return false
                        },
                    }))
                    .then(json => {
                        if (json.status && json.status === 200) {
                            dispatch(push('/login'))
                            dispatch(ToastrAction.success(i18n.accountCreated))
                        }
                    })
                    .finally(dispatch(WaitAction.waitStop()))
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError}${i18n.signup} : ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.signup))
                return rejectWithValue(err)
            }
        }
    ),
}

export default AuthenticationThunk
