import React, { useEffect, useRef, useState } from 'react'
import Map from 'ol/Map'
import View from 'ol/View'
import TileLayer from 'ol/layer/Tile'
import { fromLonLat } from 'ol/proj'
import Feature from 'ol/Feature'
import Point from 'ol/geom/Point'
import { Cluster, Vector } from 'ol/source'
import Style from 'ol/style/Style'
import Stroke from 'ol/style/Stroke'
import AnimatedCluster from 'ol-ext/layer/AnimatedCluster'
import Icon from 'ol/style/Icon'
import Fill from 'ol/style/Fill'
import Text from 'ol/style/Text'
import CircleStyle from 'ol/style/Circle'
import { HYDRO_COLOR, PIEZO_COLOR, PLUVIO_COLOR, QUALITY_COLOR } from '../utils/GlobalColorsUtils'
import pluvioMarker from '../assets/pictures/markers/map_pluvio_2.png'
import hydroMarker from '../assets/pictures/markers/PNG/map_hydro_blue.png'
import ades from '../assets/pictures/logos/ades.jpg'
import naiades from '../assets/pictures/logos/naiades.png'
import ars from '../assets/pictures/logos/ars.png'
import PropTypes from 'prop-types'
import DtoStation from './dto/DtoStation'
import qualityMarker from '../assets/pictures/markers/PNG/map_quality_red.png'
import piezoMarker from '../assets/pictures/markers/map_piezo.png'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { split, sumBy, trim, uniq } from 'lodash'
import { Polygon } from 'ol/geom'
import MultiPolygon from 'ol/geom/MultiPolygon'
import { getCenter } from 'ol/extent'
import DtoWatershedPolygon from './dto/DtoWatershedPolygon'
import DtoGeolocation from './dto/DtoGeolocation'
import locationMarker from '../assets/pictures/markers/PNG/map_contact_green.png'
import { useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { HYDRO, PIEZO, PLUVIO, QUALITO } from './constants/ThemesConstants'
import OSMsource from 'ol/source/OSM'
import OSM_IMG from '../assets/pictures/map/open_street_map.png'
import {
    IGN, IGN_URL, OSM,
    RELIEF,
    RELIEF_URL,
    ROADMAP,
    ROADMAP_URL,
    SATELLITE,
    SATELLITE_URL, WHITE,
} from './constants/MapBackgroundConstants'
import XYZ from 'ol/source/XYZ'
import SATELLITE_IMG from '../assets/pictures/map/satellite.png'
import RELIEF_IMG from '../assets/pictures/map/relief.png'
import ROADMAP_IMG from '../assets/pictures/map/carte_routiere.png'
import IGN_IMG from '../assets/pictures/map/ign.png'
import Popup from 'ol-ext/overlay/Popup'
import { makeStyles } from '@mui/styles'
import Select from 'ol/interaction/Select'
import { formatDate } from '../utils/DateUtil'
import useLocalStorage from '../utils/customHooks/useLocalStorage'

const DEFAULT_BACKGROUND = OSM

const PLUVIO_HEIGHT_UNITS = 1
const HYDRO_HEIGHT_UNITS = 4
const HYDRO_FLOW_UNITS = 5
const PIEZO_DEPTH_UNITS = -1

const useStyles = makeStyles(() => ({
    popup: {
        position: 'absolute',
        backgroundColor: '#fff',
        filter: 'drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2))',
        padding: '15px',
        borderRadius: '10px',
        border: '2px solid',
        left: '-50px',
    },
    closeBox: {
        '& button': {
            display: 'none',
        },
    },
    boldLabel: {
        fontWeight: 'bold',
    },
    redBorder: {
        borderColor: QUALITY_COLOR,
    },
    darkBlueBorder: {
        borderColor: PIEZO_COLOR,
    },
    grayBorder: {
        borderColor: PLUVIO_COLOR,
    },
    blueBorder: {
        borderColor: HYDRO_COLOR,
    },
    reference: {
        color: 'blue',
        cursor: 'pointer',
        textDecoration: 'underline',
        '&:hover': {
            color: 'darkblue',
        },
    },
}))

const MapView = ({
    setParams,
    entities = [],
    cityPolygons = [],
    watershedPolygons = [],
    geolocation,
    distance = 25,
    clustering = false,
}) => {
    const classes = useStyles()
    const { login, lastMeasures } = useSelector(store => ({
        login: store.AuthenticationReducer.login,
        lastMeasures: store.MapReducer.lastMeasures,
    }))

    const map = useRef()
    const popup = useRef()

    const [types, setTypes] = useState(uniq(entities.map(s => s.type)))
    const [disableCluster, setDisableCluster] = useState(clustering ?? false)
    const [clusterDistance, setClusterDistance] = useState(distance ?? 25)

    const layersSource = useRef({})
    const clustersSource = useRef({})

    const cityPolygonLayer = useRef()
    const watershedPolygonLayer = useRef()

    const animatedClusters = useRef([])
    const vectorLayers = useRef([])

    const locationLayer = useRef()

    const layers = useRef([
        {
            code: QUALITO,
            label: i18n.qualitometers,
            border: classes.redBorder,
            icon: qualityMarker,
            color: QUALITY_COLOR,
        },
        {
            code: PIEZO,
            label: i18n.piezometers,
            border: classes.darkBlueBorder,
            icon: piezoMarker,
            color: PIEZO_COLOR,
        },
        {
            code: HYDRO,
            label: i18n.hydrometers,
            border: classes.blueBorder,
            icon: hydroMarker,
            color: HYDRO_COLOR,
        },
        {
            code: PLUVIO,
            label: i18n.pluviometers,
            border: classes.grayBorder,
            icon: pluvioMarker,
            color: PLUVIO_COLOR,
        },
    ])

    const backgrounds = [
        {
            code: OSM,
            label: 'Open Street Map',
            layer: new OSMsource(),
            preview: OSM_IMG,
        },
        {
            code: SATELLITE,
            label: 'Satellite',
            layer: new XYZ({
                url: SATELLITE_URL,
            }),
            preview: SATELLITE_IMG,
        },
        {
            code: RELIEF,
            label: 'Relief',
            layer: new XYZ({
                url: RELIEF_URL,
            }),
            preview: RELIEF_IMG,
        },
        {
            code: ROADMAP,
            label: i18n.roadMap,
            layer: new XYZ({
                url: ROADMAP_URL,
            }),
            preview: ROADMAP_IMG,
        },
        {
            code: IGN,
            label: 'IGN',
            layer: new XYZ({
                url: IGN_URL,
            }),
            preview: IGN_IMG,
        },
        {
            code: WHITE,
            label: i18n.white,
            layer: new XYZ(),
        },
    ]

    const clearLayers = () => {
        if (popup.current) {
            popup.current.hide()
        }

        Object.entries(clustersSource.current).forEach(c => {
            if (c[1]) {
                c[1].getSource().clear()
            }
        })

        Object.entries(layersSource.current).forEach(l => {
            if (l[1]) {
                l[1].clear()
            }
        })
    }

    const [background, setBackground] = useLocalStorage('carto_background', DEFAULT_BACKGROUND)

    useEffect(() => {
        const backgroundSource = backgrounds.filter(b => b.code === background)[0]?.layer

        if (backgroundSource && map.current) {
            map.current.getLayers().setAt(0, new TileLayer({ source: backgroundSource }))
        }
    }, [background])

    useEffect(() => {
        if (entities && entities.length) {
            setTypes(current => uniq([...current, ...entities.map(s => s.type)]))
        }
    }, [entities])

    useEffect(() => {
        clearLayers()

        types.forEach(t => {
            clustersSource.current[t] = new Cluster({
                distance: clusterDistance ?? 25,
                source: new Vector(),
            })

            layersSource.current[t] = new VectorSource()
        })
    }, [types])

    useEffect(() => {
        if (popup.current) {
            popup.current.hide()
        }

        types.forEach(t => {
            clustersSource.current[t].distance = clusterDistance ?? 25
        })
    }, [clusterDistance])

    useEffect(() => {
        const createPolygonLayer = (name, fillColor, strokeColor) => {
            return new VectorLayer({
                name: `${name}PolygonLayer`.toUpperCase(),
                source: new VectorSource(),
                style: new Style({
                    fill: new Fill({
                        color: fillColor,
                    }),
                    stroke: new Stroke({
                        color: strokeColor,
                        width: 2,
                    }),
                }),
            })
        }

        cityPolygonLayer.current = createPolygonLayer('city', 'rgba(255,255,255,0.3)', '#ff0000')
        watershedPolygonLayer.current = createPolygonLayer('watershed', 'rgba(0, 0, 255,0.1)', '#0000ff')

        const backgroundCache = localStorage.getItem('carto_background')

        if (backgroundCache) {
            setBackground(backgroundCache)
        }

        const backgroundSource = backgroundCache && backgrounds.find(b => b.code === backgroundCache) ? backgrounds.filter(b => b.code === backgroundCache)[0].layer : backgrounds.filter(b => b.code === DEFAULT_BACKGROUND)[0].layer

        map.current = new Map({
            layers: [
                new TileLayer({ source: backgroundSource }),
                cityPolygonLayer.current,
                watershedPolygonLayer.current,
            ],
            view: new View({
                center: fromLonLat([2.510082, 46.721434]),
                zoom: 6.5,
            }),
            target: 'map',
        })

        locationLayer.current = new VectorLayer({
            name: 'locationLayer'.toUpperCase(),
            source: new VectorSource(),
            style: new Style({
                image: new Icon({
                    scale: 0.7,
                    src: locationMarker,
                }),
            }),
        })

        map.current.addLayer(locationLayer.current)

        popup.current = new Popup({
            popupClass: 'default',
            positioning: 'bottom-left',
        })

        popup.current.removePopupClass('ol-popup')
        popup.current.addPopupClass(classes.popup)
        popup.current.addPopupClass(classes.closeBox)
        map.current.addOverlay(popup.current)
    }, [])

    useEffect(() => {
        if (geolocation && geolocation.longitude && geolocation.latitude) {
            locationLayer.current.getSource().clear()
            locationLayer.current.getSource().addFeature(
                new Feature({
                    geometry: new Point(fromLonLat([geolocation.longitude, geolocation.latitude])),
                })
            )
        }
    }, [geolocation])

    useEffect(() => {
        const featureStyle = (type) => {
            const layer = layers.current.find(l => l.code === type)

            return new Style({
                image: new Icon({
                    src: layer.icon,
                    scale: 0.7,
                }),
            })
        }

        const getStyle = (feature) => {
            const features = feature.get('features')
            const size = features.length

            if (size === 1) {
                return featureStyle(features[0].get('type'))
            }

            return new Style({
                image: new CircleStyle({
                    scale: 0.8,
                    radius: 15,
                    stroke: new Stroke({
                        color: '#fff',
                    }),
                    fill: new Fill({
                        color: layers.current.find(l => l.code === features[0].get('type')).color,
                    }),
                }),
                text: new Text({
                    text: size.toString(),
                    scale: 1.5,
                    fill: new Fill({
                        color: '#fff',
                    }),
                }),
            })
        }

        types.forEach(t => {
            const animatedCluster = new AnimatedCluster({
                name: t.toUpperCase(),
                source: clustersSource.current[t],
                animationDuration: 700,
                style: getStyle,
                theme: t,
            })

            const vectorLayer = new VectorLayer({
                name: t.toUpperCase(),
                source: layersSource.current[t],
                style: featureStyle(t),
                theme: t,
            })

            animatedClusters.current[t] = animatedCluster
            vectorLayers.current[t] = vectorLayer

            map.current.addLayer(animatedCluster)
            map.current.addLayer(vectorLayer)
        })

        const selectLayer = new Select({
            layers: [...Object.entries(animatedClusters.current).map(c => c[1]), ...Object.entries(vectorLayers.current).map(c => c[1])],
        })

        map.current.addInteraction(selectLayer)

        selectLayer.getFeatures().on(['add'], (e) => {
            const isCluster = e.element.get('features')
            const feature = isCluster ? e.element.get('features')[0] : e.element
            const layer = layers.current.find(l => l.code === feature.get('type'))

            layers.current.forEach(l => {
                popup.current.removePopupClass(l.border)
            })

            popup.current.addPopupClass(layer.border)

            const getContent = () => {
                if (isCluster && e.element.get('features').length > 1) {
                    return `<div class='${classes.boldLabel}'>${i18n.entitiesList} ${layers.current.find(l => l.code === feature.get('type')).label}</div>
                        <div style="margin-top: 5px; max-height: 200px; overflow-y: scroll">
                        ${e.element.get('features').flatMap(f => `<div style="padding: 0 5px">${f.get('name')}</div>`).toString().replaceAll(',', '')}
                        </div>`
                }

                return feature.get('content')
            }

            popup.current.show(feature.getGeometry().getFirstCoordinate(), getContent())
        })

        selectLayer.getFeatures().on(['remove'], () => {
            popup.current.hide()
        })
    }, [clustersSource, layersSource, types])

    useEffect(() => {
        if (popup.current) {
            popup.current.hide()
        }

        types.forEach(t => {
            layers.current.forEach(l => {
                if (l.code.toUpperCase() === t.toUpperCase()) {
                    l.layer = !disableCluster ? animatedClusters.current[t] : vectorLayers.current[t]
                }
            })
        })
    }, [animatedClusters, vectorLayers, disableCluster, types])

    const getCoordsFromRing = ring => {
        return split(ring, ' ').map(pair => fromLonLat(split(pair, ',').map(coord => parseFloat(coord))))
    }

    const getPolygonFeature = kml => {
        const polygonsRings = split(kml, 'Polygon').filter(poly => poly.includes(',')).map(poly => split(poly, 'LinearRing').filter(ring => ring.includes(',')))
        const polygons = polygonsRings.map(poly => new Polygon(
            poly.map(ring => {
                const cleanedRing = trim(ring, '<>azertyuiopqsdfghjklmwxcvbnAZERTYUIOPQSDFGHJKLMWXCVBN/')
                if (cleanedRing.length > 0) {
                    return getCoordsFromRing(cleanedRing)
                }
                return ''
            })
        ))

        if (polygons.length === 1) {
            return polygons[0]
        }

        return new MultiPolygon(polygons)
    }

    useEffect(() => {
        cityPolygonLayer.current.getSource().clear()

        if (cityPolygons && cityPolygons.length) {
            cityPolygons.forEach(c => {
                const feature = new Feature({
                    geometry: getPolygonFeature(c),
                })
                cityPolygonLayer.current.getSource().addFeature(feature)
            })
        }
    }, [cityPolygons])

    useEffect(() => {
        watershedPolygonLayer.current.getSource().clear()

        if (watershedPolygons && watershedPolygons.length) {
            watershedPolygons.forEach(w => {
                const feature = new Feature({
                    geometry: getPolygonFeature(w.kml),
                    name: `${w.name || ''}`,
                })
                feature.setId(`${w.code || ''}`)
                watershedPolygonLayer.current.getSource().addFeature(feature)
            })

            const centerXAverage = sumBy(watershedPolygonLayer.current.getSource().getFeatures(), (watershedPolygon) => getCenter(watershedPolygon.getGeometry().getExtent())[0]) / watershedPolygonLayer.current.getSource().getFeatures().length
            const centerYAverage = sumBy(watershedPolygonLayer.current.getSource().getFeatures(), (watershedPolygon) => getCenter(watershedPolygon.getGeometry().getExtent())[1]) / watershedPolygonLayer.current.getSource().getFeatures().length
            if (!isNaN(centerXAverage) && !isNaN(centerYAverage)) {
                map.current.getView().setCenter([centerXAverage, centerYAverage])
                map.current.getView().setZoom(10)
            }
        }
    }, [watershedPolygons])

    useEffect(() => {
        clearLayers()

        if (entities && entities.length && types && types.length) {
            const getLastMeasureUnit = (type, lastMeasure) => {
                const units = {
                    pluvio: {
                        [PLUVIO_HEIGHT_UNITS]: 'mm',
                    },
                    hydro: {
                        [HYDRO_HEIGHT_UNITS]: 'm',
                        [HYDRO_FLOW_UNITS]: 'm3',
                    },
                    piezo: {
                        [PIEZO_DEPTH_UNITS]: 'm',
                    },
                }

                const typeUnit = units[type]

                if (typeUnit && lastMeasure.dataType) {
                    return typeUnit[lastMeasure.dataType] ?? ''
                }

                return ''
            }
            const getLastMeasureContent = (type, lastMeasure) => (
                `<div>
                    <div>
                        ${i18n.date}: ${formatDate(lastMeasure.date)}
                    </div>
                    <div>
                        ${i18n.value}: ${lastMeasure.value}${getLastMeasureUnit(type, lastMeasure)}
                    </div>
                </div>`
            )

            const getReferenceContent = (type, code) => {
                if (type === QUALITO || type === PIEZO) {
                    const getReference = () => {
                        if (code.length === 8) {
                            return {
                                icon: naiades,
                                url: `http://www.naiades.eaufrance.fr/acces-donnees#/physicochimie/resultats?stations=${code}`,
                            }
                        } else if (/^\d+$/.test(code)) {
                            return {
                                icon: ars,
                                url: 'https://orobnat.sante.gouv.fr/orobnat/afficherPage.do?methode=menu&idRegion=011&dpt=027&usd=AEP&comDpt=',
                            }
                        }

                        return {
                            icon: ades,
                            url: `http://www.ades.eaufrance.fr/FichePtEau.aspx?code=${code}`,
                        }
                    }

                    const reference = getReference(code)

                    if (reference) {
                        return (
                            `<div style='margin-top: 20px; display: flex; align-items: center;'>
                                <div style='padding-right: 5px;'>
                                    <img width='30px' height='auto' src='${reference.icon}' alt="${i18n.entityIcon}"/>
                                </div>
                                <div>
                                    <div class='${classes.reference}' onclick="window.open('${reference.url}', '_blank')">
                                        ${i18n.referenceLink}
                                    </div>
                                </div>
                            </div>`
                        )
                    }
                }

                return ('</div>')
            }

            types.forEach(t => {
                const features = entities.filter(s => s.type === t).map(s => {
                    const feature = new Feature(new Point(fromLonLat([s.x, s.y])))
                    feature.set('id', s.codebss)
                    feature.set('code', s.codebss)
                    feature.set('type', s.type)
                    feature.set('name', `${s.codebss || ''} ${s.name ? ` - ${s.name}` : ''}`)

                    const codeName = `${s.type.toLowerCase()}Id`
                    const lastMeasure = lastMeasures[s.type].find(ls => ls[codeName] && (ls[codeName] === s.code))
                    const content = `<div class='${classes.boldLabel}'>${layers.current.find(l => l.code === s.type).label}</div>
                        <div style="margin-top: 5px;">${feature.get('name')}</div>
                        <div class='${classes.boldLabel}' style='margin-top: 10px;'>${i18n.lastData}</div>
                        <div>${!login ? i18n.needConnected : !lastMeasure || lastMeasures.value ? i18n.any : `<div>${getLastMeasureContent(s.type, lastMeasure)}</div>`}</div>
                        <div>${getReferenceContent(s.type, s.codebss)}</div>`
                    feature.set('content', content)
                    return feature
                })

                if (!disableCluster) {
                    clustersSource.current[t]?.getSource().addFeatures(features)
                } else {
                    layersSource.current[t]?.addFeatures(features)
                }
            })
        }
    }, [clustersSource, disableCluster, clusterDistance, layersSource, entities, types])

    useEffect(() => {
        setParams({
            map: map.current,
            layers: layers.current,
            watershedPolygonLayer: watershedPolygonLayer.current,
            backgrounds,
            background,
            setBackground,
            disableCluster,
            setDisableCluster,
            clusterDistance,
            setClusterDistance,
        })
    }, [map, layers, watershedPolygonLayer, background, disableCluster, clusterDistance])

    return (
        <div id='map' style={{ backgroundColor: 'white' }}/>
    )
}

MapView.propTypes = {
    setParams: PropTypes.func,
    entities: PropTypes.arrayOf(PropTypes.instanceOf(DtoStation)),
    cityPolygons: PropTypes.arrayOf(PropTypes.string),
    watershedPolygons: PropTypes.arrayOf(PropTypes.instanceOf(DtoWatershedPolygon)),
    geolocation: PropTypes.instanceOf(DtoGeolocation),
    distance: PropTypes.number,
    clustering: PropTypes.bool,
}

export default MapView