import { createSlice } from '@reduxjs/toolkit'
import SelectionThunk from 'selection/actions/SelectionThunk'
import DtoSelection from 'selection/dto/DtoSelection'

export const initialState = {
    selections: [],
}

const store = createSlice({
    name: 'selection',
    initialState,
    reducers: {
    },
    extraReducers: {
        [SelectionThunk.get.fulfilled]: (state, action) => {
            state.selections = action.payload.map(s => new DtoSelection(s))
        },
        [SelectionThunk.delete.fulfilled]: (state, action) => {
            state.selections = state.selections.filter(s => s.code !== action.payload)
        },
        [SelectionThunk.favorite.fulfilled]: (state, action) => {
            const selection = state.selections.find(s => s.code === action.payload)
            selection.isFavorite = !selection.isFavorite
            state.selections = [
                ...state.selections.filter(s => s.code !== action.payload),
                selection,
            ]
        },
    },
})

export const SelectionAction = store.actions
export default store.reducer