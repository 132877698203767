import { createSlice } from '@reduxjs/toolkit'
import SearchBarThunk from 'searchBar/actions/SearchBarThunk'

export const initialState = {
    options: [],
}

const store = createSlice({
    name: 'autocomplete',
    initialState,
    reducers: {
        resetOptions(state) {
            state.options = []
        },
    },
    extraReducers: {
        [SearchBarThunk.search.fulfilled]: (state, action) => {
            state.options = action.payload
        },
    },
})

export const SearchBarAction = store.actions
export default store.reducer