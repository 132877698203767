import React, { useEffect, useRef } from 'react'
import { IconButton } from '@mui/material'
import i18n from 'simple-react-i18n'
import { useDispatch, useSelector } from 'react-redux'
import { MapAction } from 'map/reducers/MapReducer'
import MapThunk from 'map/actions/MapThunk'
import { push } from 'connected-react-router'
import MyLocationIcon from '@mui/icons-material/MyLocation'
import PropTypes from 'prop-types'
import { ToastrAction } from '../../toastr/reducers/ToastrReducer'

const Geolocation = () => {
    const dispatch = useDispatch()
    const location = useRef({})
    const { geolocation } = useSelector(store => ({
        geolocation: store.MapReducer.geolocation,
    }),)

    const getLocationOnMap = () => {
        if (geolocation && location.current) {
            dispatch(MapAction.setGeolocation(location.current))
            dispatch(MapAction.setLocated(true))
            dispatch(MapThunk.fetchCityByCoordinate(location.current))
            dispatch(push('/map'))
        } else {
            dispatch(ToastrAction.error(i18n.enableGeolocation))
        }
    }

    useEffect(() => {
        const success = ({ coords }) => {
            location.current = {
                lon: coords.longitude,
                lat: coords.latitude,
                isLocated: false,
            }
            dispatch(MapAction.setGeolocation(location.current))
        }

        const error = (err) => {
            console.info(`Geolocation error (${err.code}): ${err.message}`)
        }

        const options = { maximumAge: 10000, timeout: 5000, enableHighAccuracy: true }
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(success, error, options)
        } else {
            // eslint-disable-next-line no-console
            console.warn('Geolocation is not supported by this browser.')
        }
    }, [dispatch])

    return (
        <>
            <IconButton size='small' onClick={getLocationOnMap}>
                <MyLocationIcon fontSize='inherit' style={{
                    color: 'black',
                }}
                />
            </IconButton>
        </>
    )
}

Geolocation.propTypes = {
    isOnMap: PropTypes.bool,
}

Geolocation.defaultProps = {
    isOnMap: false,
}

export default Geolocation