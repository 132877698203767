export default class DtoSelection {
    constructor(obj) {
        this.name = obj.name
        this.code = obj.id
        this.filters = obj.filter.map(f => ({ type: f.typ, code: f.code, name: f.name }))
        this.themes = obj.theme
        this.stations = obj.station.map(f => ({ type: f.typ, code: f.code }))
        this.watersheds = obj.watershed
        this.isFavorite = obj.favorite
        this.creationTimestamp = obj.dateCreate
        this.exportTimestamp = obj.dateExport
        this.updateTimestamp = obj.dateModif
        this.download = obj.downloadExport
    }
}
