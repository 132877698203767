import React, { useEffect, useState } from 'react'
import i18n from 'simple-react-i18n'
import { Box, Grid, useMediaQuery } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useDispatch, useSelector } from 'react-redux'
import { HOME_BACKGROUND_IMAGE } from 'home/constants/HomeConstants'
import BurgerMenu from 'burgerMenu/BurgerMenu'
import { push } from 'connected-react-router'
import SelectionThunk from './actions/SelectionThunk'
import { sortBy } from 'lodash'
import { useTheme } from '@emotion/react'
import ExportSelection from './components/ExportSelection'
import SelectionCard from './components/SelectionCard'
import DeleteSelection from './components/DeleteSelection'
import LoadingScreen from '../map/components/LoadingScreen'

const useStyles = makeStyles(() => ({
    background: {
        backgroundImage: HOME_BACKGROUND_IMAGE,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundAttachment: 'fixed',
        backgroundSize: 'cover',
        overflow: 'hidden',
        height: '100%',
        width: '100%',
    },
    titleContainer: {
        color: 'white',
        borderRadius: '5px',
        padding: '15px',
        margin: '20px auto',
    },
    title: {
        fontWeight: 'bold',
        fontSize: '3em',
        paddingBottom: 5,
    },
    mySearches: {
        overflow: 'scroll',
        '&::-webkit-scrollbar': {
            width: '1px',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'transparent',
            border: 'none',
        },
    },
    container: {
        borderRadius: '5px',
        padding: '15px',
    },
    hr: {
        backgroundColor: 'white',
        height: '3px',
        border: 'none',
        borderRadius: '3px',
        margin: '10px',
    },
}))

const SelectionDashboard = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const theme = useTheme()
    const smMatches = useMediaQuery((th) => th.breakpoints.up('sm'))

    const {
        login,
        selections,
    } = useSelector(store => ({
        login: store.AuthenticationReducer.login,
        selections: store.SelectionReducer.selections,
    }))

    const [sortedSelections, setSortedSelections] = useState(sortBy(selections, [s => !s.isFavorite, s => s.name]))
    const [selectExport, setSelectExport] = useState(null)
    const [selectDelete, setSelectDelete] = useState(null)
    const [loaded, setLoaded] = useState(false)

    const favorite = (selection) => {
        dispatch(SelectionThunk.favorite(selection.code))
    }

    const deleteSelection = (selection) => {
        dispatch(SelectionThunk.delete(selection.code))
    }

    useEffect(() => {
        if (!login) {
            dispatch(push('/login'))
        }
    }, [dispatch, login])

    useEffect(() => {
        dispatch(SelectionThunk.get()).then(() => setLoaded(true))
    }, [dispatch])

    useEffect(() => {
        setSortedSelections(sortBy(selections, [s => !s.isFavorite, s => s.name]))
    }, [selections])

    return (
        <div className={classes.background}>
            <LoadingScreen isOpen={!loaded} message={i18n.loadingResearch}/>
            <ExportSelection selection={selectExport} setSelection={setSelectExport}/>
            <DeleteSelection selection={selectDelete} setSelection={setSelectDelete} deleteSelection={deleteSelection}/>
            <Grid
                container
                direction='row'
                justifyContent='center'
                alignItems='flex-start'
                spacing={1}
            >
                <Grid item xs={12}>
                    {
                        smMatches && (
                            <BurgerMenu color='light'/>
                        )
                    }
                </Grid>
            </Grid>
            <Grid container justifyContent={'center'} style={{ paddingBottom: 10 }}>
                <Grid container item xs={12} md={8} className={classes.titleContainer}>
                    <Grid container className={classes.title}>
                        {i18n.mySearches}
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.hr} style={{ padding: '0 auto 20 auto' }} />
                </Grid>
            </Grid>
            <Grid container justifyContent={'center'} style={sortedSelections.length > 0 ? { maxHeight: '80%' } : { height: 'auto' }} className={classes.mySearches}>
                <Grid container item md={8} className={classes.container}>
                    <Grid container justifyContent={!smMatches ? 'center' : ''} spacing={3}>
                        {
                            sortedSelections.length > 0 ?
                                sortedSelections.map(selection => (
                                    <SelectionCard key={selection.code} selection={selection} onClick={() => dispatch(push(`/selection/detail?selection=${selection.code}`))} setSelectExport={setSelectExport} favorite={favorite} setSelectDelete={setSelectDelete}/>
                                )) : (
                                    <Grid container item justifyContent={'center'}>
                                        <Grid item style={{ fontSize: '1.3em', color: 'white' }}>{i18n.noSavedSearches}</Grid>
                                    </Grid>
                                )
                        }
                    </Grid>
                </Grid>
            </Grid>
            { !smMatches && (
                <Box sx={theme.menu.burger.mobile}>
                    <BurgerMenu color='light'/>
                </Box>
            )}
        </div>
    )
}

export default SelectionDashboard