/* eslint-disable consistent-return */
import { useCallback, useEffect, useState } from 'react'

/**
 * Hook to store data in the local storage
 * @param {string} key - The default value of the list
 * @param {*} defaultValue - The default value of the list
 * @return {[*, function, function]} The value, the setter and a function to remove the property from the storage
 */
const useLocalStorage = (key, defaultValue) => {
    const [value, setValue] = useState(() => {
        const storeValue = window.localStorage.getItem(key)
        if (storeValue !== null) {
            return storeValue
        }
        if (typeof initialValue === 'function') {
            return defaultValue()
        }
        return defaultValue
    })

    useEffect(() => {
        if (value === undefined) {
            return window.localStorage.removeItem(key)
        }
        window.localStorage.setItem(key, value)
    }, [key, value])

    const remove = useCallback(() => {
        setValue(undefined)
    }, [])

    return [value, setValue, remove]
}

export default useLocalStorage