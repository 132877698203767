import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Box, Modal } from '@mui/material'
import loadingGif from '../assets/pictures/loading.gif'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
        '&:focus': {
            outline: 'none',
        },
    },
}))

const Wait = ({}) => {
    const {
        isWaiting,
    } = useSelector(store => ({
        isWaiting: store.WaitReducer.isWaiting,
    }), shallowEqual)

    const classes = useStyles()

    return (
        <Modal
            open={isWaiting}
        >
            <Box className={classes.modal}>
                <img src={loadingGif}/>
            </Box>
        </Modal>
    )
}

export default Wait