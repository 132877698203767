import React, { useEffect, useState } from 'react'
import MapView from './MapView'
import { useDispatch, useSelector } from 'react-redux'
import DtoWatershedPolygon from './dto/DtoWatershedPolygon'
import DtoGeolocation from './dto/DtoGeolocation'
import LoadingScreen from './components/LoadingScreen'
import { MapAction } from './reducers/MapReducer'
import MapOverlay from './components/MapOverlay'

const MapDashboard = () => {
    const dispatch = useDispatch()

    const [entities, setEntities] = useState([])
    const [cityPolygons, setCityPolygons] = useState()
    const [watershedPolygons, setWatershedPolygons] = useState([])
    const [params, setParams] = useState()

    const {
        isLoading,
        citiesResult,
        departmentsResult,
        watershedsResult,
        stationsResult,
        searchCriteria,
        geolocation,
        progress,
        message,
    } = useSelector(store => ({
        isLoading: store.MapReducer.isLoading,
        citiesResult: store.MapReducer.citiesResult,
        departmentsResult: store.MapReducer.departmentsResult,
        watershedsResult: store.MapReducer.watershedsResult,
        stationsResult: store.MapReducer.stationsResult,
        searchCriteria: store.MapReducer.searchCriteria,
        geolocation: store.MapReducer.geolocation,
        progress: store.MapReducer.loadingProgress,
        message: store.MapReducer.loadingMessage,
    }))

    useEffect(() => {
        if (!isLoading) {
            citiesResult.forEach(c => {
                setCityPolygons([c.kml])
            })

            const citiesStations = citiesResult.flatMap(c => c.watershedStation.flatMap(w => w.stations))
            const departmentsStations = departmentsResult.flatMap(d => d.watershedStation.flatMap(w => w.stations))
            const watershedsStations = watershedsResult.flatMap(w => w.stations)
            const stationsStations = stationsResult.flatMap(s => s.stations)

            const citiesPolygons = citiesResult.flatMap(c =>
                c.watershedStation.map(w => new DtoWatershedPolygon({ code: w.code, name: w.name, kml: w.kml }))
            )

            const departmentsPolygons = departmentsResult.flatMap(d =>
                d.watershedStation.map(w => new DtoWatershedPolygon({ code: w.code, name: w.name, kml: w.kml }))
            )

            const watershedsPolygons = watershedsResult.flatMap(w =>
                w.watershedStation.map(ws => new DtoWatershedPolygon({ code: ws.code, name: ws.name, kml: ws.kml }))
            )

            const stationsPolygons = stationsResult.flatMap(s =>
                s.watershedStation.map(w => new DtoWatershedPolygon({ code: w.code, name: w.name, kml: w.kml }))
            )

            setEntities([...citiesStations, ...departmentsStations, ...watershedsStations, ...stationsStations])
            setWatershedPolygons([...citiesPolygons, ...departmentsPolygons, ...watershedsPolygons, ...stationsPolygons])
        } else {
            const citiesFullfield = searchCriteria.cities.length === citiesResult.length
            const departmentsFullfield = searchCriteria.departments.length === departmentsResult.length
            const watershedsFullfield = searchCriteria.watersheds.length === watershedsResult.length
            const stationsFullfield = searchCriteria.stations.length === stationsResult.length
            dispatch(MapAction.setIsLoading(!(citiesFullfield && departmentsFullfield && watershedsFullfield && stationsFullfield && progress === 100)))
        }
    }, [isLoading, searchCriteria, citiesResult, departmentsResult, watershedsResult, stationsResult, dispatch, progress])

    useEffect(() => {
        setEntities([])
        setCityPolygons([])
        setWatershedPolygons([])
    }, [citiesResult, departmentsResult, watershedsResult, stationsResult])

    return (
        <div>
            <LoadingScreen isOpen={isLoading} message={message} progress={progress}/>
            {
                params?.map && (
                    <MapOverlay
                        map={params.map}
                        layers={params.layers}
                        watershedPolygonLayer={params.watershedPolygonLayer}
                        selection={params.selection}
                        backgrounds={params.backgrounds}
                        background={params.background}
                        setBackground={params.setBackground}
                        disableCluster={params.disableCluster}
                        setDisableCluster={params.setDisableCluster}
                        clusterDistance={params.clusterDistance}
                        setClusterDistance={params.setClusterDistance}
                    />
                )
            }
            <MapView
                setParams={setParams}
                entities={entities}
                cityPolygons={cityPolygons}
                watershedPolygons={watershedPolygons}
                geolocation={new DtoGeolocation({ longitude: geolocation.lon, latitude: geolocation.lat, located: geolocation.isLocated })}
            />
        </div>
    )
}

export default MapDashboard