import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import i18n from 'simple-react-i18n'
import { push } from 'connected-react-router'
import { Grid } from '@mui/material'
import { getLoginPassword } from '../utils/ActionUtils'
import { InputRow } from 'components/styled/Inputs'
import { Link } from 'react-router-dom'
import { useTheme } from '@mui/styles'
import AuthenticationThunk from './actions/AuthenticationThunk'
import { AQUADB_LOGO, AQUASYS_LOGO } from 'home/constants/HomeConstants'
import { PrimaryButton } from '../components/styled/Buttons'

const Login = () => {
    const theme = useTheme()
    const dispatch = useDispatch()

    const credentials = getLoginPassword() || ['', '']
    const [login, setLogin] = useState(credentials[0])
    const [password, setPassword] = useState(credentials[1])
    const [isLoginError, setLoginError] = useState(false)
    const [isPasswordError, setPasswordError] = useState(false)

    const onSubmit = () => {
        if (!login) {
            setLoginError(true)
        }
        if (!password) {
            setPasswordError(true)
        }
        if (password && login) {
            dispatch(AuthenticationThunk.login({
                login,
                password,
            }))
                .then(action => {
                    if (action.type === 'login/rejected') {
                        setLoginError(true)
                        setPasswordError(true)
                    }
                })
        }
    }

    const onKeyDown = (e) => {
        if (e.key === 'Enter') {
            onSubmit()
        }
    }

    return (
        <Grid
            container
            direction='row'
            justifyContent='space-evenly'
            alignItems='flex-start'
            sx={theme.menu.backgroundAquadb}
        >
            <Grid container sx={theme.menu.center} justifyContent={'center'}>
                <Grid item xs={12} sx={theme.menu.logoAquadb}>
                    <img src={AQUADB_LOGO} alt='' onClick={() => dispatch(push('/home'))}/>
                </Grid>
                <Grid container item xs={10} md={5} justifyContent={'center'} textAlign={'center'} style={{ backgroundColor: 'white', margin: '100px 0', padding: '50px 0', borderRadius: 3 }}>
                    <Grid container justifyContent={'center'} style={{ fontSize: '1.5em', marginBottom: 15 }}>
                        {i18n.connection}
                    </Grid>
                    <Grid item xs={11} md={7} style={{ marginBottom: 25 }}>
                        <InputRow
                            id='login'
                            label={i18n.login}
                            type='text'
                            value={login}
                            onChange={(e) => {
                                setLogin(e.target.value)
                                setLoginError(false)
                            }}
                            variant='standard'
                            error={isLoginError}
                            onKeyDown={onKeyDown}
                            style={{ marginBottom: '15px' }}
                        />
                        <InputRow
                            id='password'
                            label={i18n.password}
                            type='password'
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value)
                                setPasswordError(false)
                            }}
                            variant='standard'
                            error={isPasswordError}
                            onKeyDown={onKeyDown}
                            style={{ marginBottom: '15px' }}
                        />
                        <PrimaryButton onClick={onSubmit} style={{ marginTop: 15 }}>
                            {i18n.log_in}
                        </PrimaryButton>
                        <PrimaryButton onClick={() => dispatch(push('/signup'))} style={{ marginTop: 15 }}>
                            {i18n.signup}
                        </PrimaryButton>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Link to='/reset' style={{ textAlign: 'center' }}>
                            {i18n.forgotPassword}
                        </Link>
                    </Grid>
                </Grid>
                <Grid item xs={12} container justifyContent='center' alignItems='center' sx={theme.menu.logoAquasys} >
                    <img src={AQUASYS_LOGO} alt='' onClick={() => window.open('https://www.aquasys.fr/', '_blank')} />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Login
