module.exports = {
    RECEIVE_CONTACT: 'RECEIVE_CONTACT',
    RECEIVE_CONTACTS: 'RECEIVE_CONTACTS',
    RECEIVE_CHAT: 'RECEIVE_CHAT',
    RECEIVE_MESSAGE: 'RECEIVE_MESSAGE',
    RECEIVE_MESSAGES_OF: 'RECEIVE_MESSAGES_OF',
    RECEIVE_CONTACT_CEDULA: 'RECEIVE_CONTACT_CEDULA',
    RESET_CONTACT_CEDULA: 'RESET_CONTACT_CEDULA',
    RECEIVE_SETTINGS: 'RECEIVE_SETTINGS',
}
