import { createSlice } from '@reduxjs/toolkit'
import MapThunk from 'map/actions/MapThunk'
import DtoCityLayer from 'map/dto/DtoCityLayer'
import DtoDepartmentLayer from 'map/dto/DtoDepartmentLayer'
import DtoWatershedLayer from 'map/dto/DtoWatershedLayer'

export const initialState = {
    searchCriteria: {
        cities: [],
        departments: [],
        watersheds: [],
        stations: [],
        epci: [],
    },
    citiesResult: [],
    departmentsResult: [],
    watershedsResult: [],
    stationsResult: [],
    geolocation: false,
    isLocated: false,
    isLoading: false,
    loadingProgress: 0,
    loadingMessage: '',
    lastMeasures: {
        qualito: [],
        pluvio: [],
        hydro: [],
        piezo: [],
    },
}

const store = createSlice({
    name: 'map',
    initialState,
    reducers: {
        setSearchCriteria(state, action) {
            state.searchCriteria = action.payload
        },
        resetDataToDisplay(state) {
            state.loadingProgress = 0
            state.loadingMessage = ''
            state.citiesResult = []
            state.departmentsResult = []
            state.watershedsResult = []
            state.stationsResult = []
        },
        setGeolocation(state, action) {
            state.geolocation = action.payload
        },
        setLocated(state, action) {
            state.isLocated = action.payload
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload
        },
        setLoadingProgress(state, action) {
            state.loadingProgress = action.payload
        },
        setLoadingMessage(state, action) {
            state.loadingMessage = action.payload
        },
        setPluvioLastMeasures(state, action) {
            state.lastMeasures.pluvio = action.payload
        },
        setHydroLastMeasures(state, action) {
            state.lastMeasures.hydro = action.payload
        },
        setPiezoLastMeasures(state, action) {
            state.lastMeasures.piezo = action.payload
        },
        setQualitoLastMeasures(state, action) {
            state.lastMeasures.qualito = action.payload
        },
    },
    extraReducers: {
        [MapThunk.fetchCityResult.fulfilled]: (state, action) => {
            state.citiesResult = [...state.citiesResult, new DtoCityLayer(action.payload)]
        },
        [MapThunk.fetchDepartmentResult.fulfilled]: (state, action) => {
            state.departmentsResult = [...state.departmentsResult, new DtoDepartmentLayer(action.payload)]
        },
        [MapThunk.fetchWatershedResult.fulfilled]: (state, action) => {
            state.watershedsResult = [...state.watershedsResult, new DtoWatershedLayer(action.payload)]
        },
        [MapThunk.fetchStationResult.fulfilled]: (state, action) => {
            state.stationsResult = [...state.stationsResult, new DtoWatershedLayer(action.payload)]
        },
        [MapThunk.fetchCityByCoordinate.fulfilled]: (state, action) => {
            const [city] = action.payload
            state.searchCriteria = {
                cities: [{
                    group: 'city',
                    code: city.code,
                    name: city.nom,
                    extra: city.codesPostaux[0],
                }],
                departments: [],
                watersheds: [],
                stations: [],
                epci: [],
            }
            state.isLoading = true
        },
        [MapThunk.fetchCityResult.pending]: (state) => {
            state.isLoading = true
        },
        [MapThunk.fetchDepartmentResult.pending]: (state) => {
            state.isLoading = true
        },
        [MapThunk.fetchWatershedResult.pending]: (state) => {
            state.isLoading = true
        },
        [MapThunk.fetchStationResult.pending]: (state) => {
            state.isLoading = true
        },
        [MapThunk.fetchCityByCoordinate.pending]: (state) => {
            state.isLoading = true
        },
    },

})

export const MapAction = store.actions
export default store.reducer